import { useQuery } from 'react-query'
import api from '../../api/api'

const useGetInstancias = (payload) => {
  return useQuery(
    ['chat_useGetInstancias', { payload }],
    async () => {
      const response = await api.get('/api/instances/getAllInstancesStatus', { params: payload })
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000,
      refetchInterval: 5000
    }
  )
}

export { useGetInstancias }
