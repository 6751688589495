import React, { useState } from 'react'
import { BsCheck2Circle, BsEye, BsEyeSlash, BsPower } from 'react-icons/bs'
import { GrClose } from 'react-icons/gr'

import { Box, Button, Flex, FormControl, HStack, Icon, Table, TableContainer, Tag, TagLabel, TagRightIcon, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'

import { useAtivarInativarTrilha } from '../hooks/useAtivarInativarTrilha'
import { useGetObterTrilhas } from '../hooks/useGetTrilhas'
import { useMostrarEsconderTrilha } from '../hooks/useMostrarEsconderTrilha'

export default function TableTrilhas () {
  const [visibility, setVisibility] = useState(true)
  const [status, setStatus] = useState(true)

  const toggleVisibility = () => { setVisibility(!visibility) }
  const toggleStatus = () => { setStatus(!status) }

  const params = { ativo: status, visualizar: visibility }

  const { UseRequestAlterarStatusTrilha } = useAtivarInativarTrilha()
  const { UseRequestAlterarVisibilidadeTrilha } = useMostrarEsconderTrilha()
  const { data } = useGetObterTrilhas(params)
  const trilhas = data?.trilhas || []

  return (
    <Box>
      <HStack justifyContent={'space-between'} spacing={4} mb={4}>
        <Text fontWeight={'semibold'} fontSize={18} m={'10px'}>Gerenciamento de Trilhas</Text>
        <Flex gap={4}>
          <Tag _hover={{ boxShadow: 'lg' }} cursor={'pointer'} onClick={toggleStatus} size={'lg'} variant='outline' colorScheme={params?.ativo ? 'green' : 'red'}>
            <TagLabel>Status</TagLabel>
            {params?.ativo ? <TagRightIcon color={'black'} as={BsCheck2Circle} /> : <TagRightIcon color={'black'} as={GrClose} />}
          </Tag>

          <Tag _hover={{ boxShadow: 'lg' }} cursor={'pointer'} onClick={toggleVisibility} size={'lg'} variant='outline' colorScheme={params?.visualizar ? 'green' : 'red'}>
            <TagLabel>Visibilidade</TagLabel>
            {params?.visualizar ? <TagRightIcon color={'black'} as={BsEye} /> : <TagRightIcon color={'black'} as={BsEyeSlash} />}
          </Tag>
        </Flex>

      </HStack>
      <TableContainer pb={100} h={'98vh'} rounded={'2xl'} boxShadow={'lg'} overflowY={'scroll'} >
        <Table size='sm'>
          <Thead>
            <Tr>
              <Th>{trilhas.length > 0 ? 'Trilha' : ''}</Th>
              <Th>{trilhas.length > 0 ? 'Tag' : ''}</Th>
              <Th>{trilhas.length > 0 ? 'Status' : ''}</Th>
              <Th>{trilhas.length > 0 ? 'Visibilidade' : ''}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {trilhas.length > 0
              ? (
                  trilhas.map((trilha) => (
                <Tr key={trilha.id}>
                  <Td>{trilha.nome}</Td>
                  <Td textTransform={'capitalize'}>{trilha.tag}</Td>
                  <Td>
                    <FormControl >
                      <Button border={trilha.ativo === '1' ? '1px solid red' : '1px solid green'} ml={-4} fontSize={14} onClick={() => UseRequestAlterarStatusTrilha({ idTrilha: trilha.id })}>
                        {trilha.ativo === '1'
                          ? <Flex alignItems={'center'} justifyContent={'center'} gap={2}>
                            Desativar
                            <Icon color={'red'} as={BsPower} />
                          </Flex>
                          : <Flex alignItems={'center'} justifyContent={'center'} gap={2}>
                            Ativar
                            <Icon color={'green'} as={BsPower} />
                          </Flex>}
                      </Button>
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl display='flex' alignItems='center'>
                      <Button border={trilha.visualizar ? '1px solid red' : '1px solid green'} ml={-1} fontSize={14} onClick={() => UseRequestAlterarVisibilidadeTrilha({ idTrilha: trilha.id })}>
                        {trilha.visualizar
                          ? <Flex alignItems={'center'} justifyContent={'center'} gap={2}>
                            Desativar
                            <Icon color={'red'} as={BsPower} />
                          </Flex>
                          : <Flex alignItems={'center'} justifyContent={'center'} gap={2}>
                            Ativar
                            <Icon color={'green'} as={BsPower} />
                          </Flex> }

                      </Button>
                    </FormControl>
                  </Td>
                </Tr>
                  ))
                )
              : (
              <Tr>
                <Td>Nenhum resultado existente com os filtros exigidos...</Td>
              </Tr>
                )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}
