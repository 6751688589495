import { useQuery } from 'react-query'
import apiAdmin from '../../../../services/apiAdmin'

const useObtendoAgendaCalendario = () => {
  return useQuery(
    'atendimento_useObtendoAgendaCalendario',
    async () => {
      const response = await apiAdmin.get('/v1/api/admin/rh/calendario-compromissos')
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000,
      refetchInterval: 5000
    }
  )
}

export { useObtendoAgendaCalendario }
