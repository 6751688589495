import { useQuery } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useGetObterTrilhas = (payload) => {
  return useQuery(
    ['chat_useGetObterTrilhas', { payload }],
    async () => {
      const response = await apiAdmin.get('/v1/api/admin/gerenciamento/chatbot/obterTrilhas', { params: payload })
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 2500,
      refetchInterval: 2500
    }
  )
}

export { useGetObterTrilhas }
