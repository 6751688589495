import React, { memo, useState } from 'react'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Select,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { FcFilledFilter } from 'react-icons/fc'
import { useObtendoInfoTabulacoes } from '../../hooks/useObtendoInfoTabulacoes'
import { useObtendoTabulacoes } from '../../hooks/useObtendoQtdTabulacoes'

function FiltroTabulacao () {
  const dataInicial = new Date().toISOString().split('T')[0]
  const dataFinal = new Date().toISOString().split('T')[0]
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const [dataHoje, setDataHoje] = useState(dataFinal)
  const [dataComeco, setDataComeco] = useState(dataInicial)
  const [correspondencia, setCorrespondencia] = useState('&correspondente[]=1&correspondente[]=0')
  const obterInfos = useObtendoInfoTabulacoes(dataComeco, dataHoje, correspondencia)
  const obtendoQtd = useObtendoTabulacoes(dataComeco, dataHoje)

  const dispararFiltro = async () => {
    onClose()
    await obtendoQtd.refetch()
    await obterInfos.refetch()
  }

  return (
    <Box>
      <Button w={'100%'} boxShadow={'lg'} h={10} ref={btnRef} mr={1} ml={-2} onClick={onOpen}>
        Abrir Filtros <FcFilledFilter fontSize={22} />
      </Button>
      <Drawer isOpen={isOpen} placement='right' onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Filtros para Tabulação</DrawerHeader>

          <DrawerBody>
            <Box display={'flex'} flexDir={'column'} gap={2}>
              <Text mt={4} mb={-6}> Selecione a data inicial</Text>
              <input
                style={{ background: 'transparent', height: '20px', marginTop: '20px', outline: 'none', textTransform: 'uppercase' }}
                type='date'
                value={dataComeco}
                onChange={(e) => setDataComeco(e.target.value)}
              ></input>

              <Text mt={4} mb={-6}> Selecione a data final</Text>
              <input
                style={{ background: 'transparent', height: '20px', marginTop: '20px', outline: 'none', textTransform: 'uppercase' }}
                type='date'
                value={dataHoje}
                onChange={(e) => setDataHoje(e.target.value)}
              ></input>

              <Text mt={4} mb={-2}>Selecione o status da tabulação</Text>
              <Select
                placeholder="-- Selecione a tabulação"
                onChange={(e) => {
                  const selectedValue = e.target.value
                  if (selectedValue === '1') {
                    setCorrespondencia('&correspondente[]=1')
                  } else if (selectedValue === '0') {
                    setCorrespondencia('&correspondente[]=0')
                  } else if (selectedValue === 'TODAS') {
                    setCorrespondencia('&correspondente[]=1&correspondente[]=0')
                  }
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
                <option value="TODAS">TODAS</option>
              </Select>
            </Box>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button onClick={dispararFiltro} colorScheme='green'>
              Filtrar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default memo(FiltroTabulacao)
