import React from 'react'

import {
  Box,
  Image,
  useMediaQuery
} from '@chakra-ui/react'

import imagemSeguranca from '../../../assets/seguranca.png'
function CardSeguranca () {
  const [isMobile] = useMediaQuery('(max-width: 900px)')
  return (
    <>
      {!isMobile && (
        <Box
          flex={{ base: 'none', md: '1' }}
          display='flex'
          justifyContent={'center'}
          overflow='hidden'
          height='550.5px'
          mb={'-50px'}
        >
          <Image
            src={imagemSeguranca}
            alt='Imagem de Segurança'
            height='490px'
            rounded={'lg'}
            shadow={'md'}
          />
        </Box>
      )}
    </>
  )
}

export default CardSeguranca
