/* eslint-disable react/prop-types */
import React, { memo, useState } from 'react'
import { BsRobot } from 'react-icons/bs'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Icon,
  Step,
  StepDescription,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  Tooltip,
  useColorMode,
  useSteps
} from '@chakra-ui/react'

import { useObetendoRoadMap } from '../hooks/useObetendoRoadMap'
import formatDataHoraChat from '../../../../../helpers/dataHora/formatDataHoraChat'

function RoadMapChatbot ({ idProtocolo }) {
  const { colorMode } = useColorMode()
  const [ZIndex, setzIndex] = useState(false)
  const { data } = useObetendoRoadMap(idProtocolo)
  const steps = data || []

  const { activeStep } = useSteps({
    index: 3,
    count: steps.length
  })

  const substituirQuebrasDeLinha = (arrayDeStrings) => {
    if (!Array.isArray(arrayDeStrings)) return arrayDeStrings

    const arrayComLinhasDivididas = arrayDeStrings.map((s) =>
      s.replaceAll(/\n/g, '<br />').replaceAll('?', '') + '<hr>'
    )

    const resultadoSemVirgulas = arrayComLinhasDivididas.join('')
    return resultadoSemVirgulas
  }

  function sobrePoem () {
    setzIndex(!ZIndex)
  }

  return (
    <Tooltip zIndex={9} hasArrow label='Visualizar etapas no chatbot' placement='top-start'>
      <Accordion zIndex={ZIndex ? 9999 : 9} bg={colorMode === 'light' ? 'white' : 'gray.400'} mt={4} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton onClick={sobrePoem} _expanded={{ bg: 'blue.300', color: 'white' }}>
              <Box color={colorMode === 'light' ? 'black' : 'white'} as='span' flex='1' textAlign='left'>
                <Text fontWeight={'bold'}>Etapas do Chatbot Pré Solicitação de Atendimento</Text>
                <Text color={'gray.600'} fontSize={14} fontWeight={'bold'}>Solicitação de atendimento as:{formatDataHoraChat(steps.dataCriacaoAtendimento)}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel zIndex={999} display={'flex'} alignItems={'center'} justifyContent={'space-between'} pb={4} color={colorMode === 'light' ? 'black' : 'white'}>
            <Stepper overflow={'scroll'} orientation='vertical' size='sm' h={'62vh'} index={activeStep}>
              {steps?.roadmap?.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus complete={'🔎'} active={<Icon as={BsRobot} />} />
                  </StepIndicator>

                  <Box w={'100%'} textAlign={'left'} flexShrink='0' color={colorMode === 'light' ? 'black' : 'white'}>
                    <StepTitle>{step.opcaoMenu}</StepTitle>
                    <StepDescription color={colorMode === 'light' ? 'black' : 'white'}
                      dangerouslySetInnerHTML={{ __html: substituirQuebrasDeLinha(step.botMensagem) }}
                    />
                    <hr/>
                  </Box>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Tooltip>
  )
}

export default memo(RoadMapChatbot)
