/* eslint-disable react/prop-types */
import React from 'react'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, Flex, Heading, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import ModalDisparoMsg from './modalDisparoMsg'
import logo from '../images/image.png'

export default function Lista ({ data }) {
  const responsavel = data.usuarioSessao.nome
  const celularResponsavel = data.usuarioSessao.celular

  const diaUtil = new Date().getDay()
  const dataAtual = new Date()
  const diaAtual = dataAtual.getDate()
  const mesAtual = dataAtual.getMonth() + 1

  const aniversariantesPassados = []
  const aniversariantesFuturos = []

  data.aniversariantesMes.forEach(aniversariante => {
    const [diaAniversario, mesAniversario] = aniversariante.aniversario.split('/').map(Number)

    if (mesAniversario < mesAtual || (mesAniversario === mesAtual && diaAniversario < diaAtual)) {
      aniversariantesPassados.push(aniversariante)
    } else {
      aniversariantesFuturos.push(aniversariante)
    }
  })

  return (
    <Box h={'100vh'} w={'100%'} px={5} pb={5}>

      <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'} w={'100%'}>
        <Image width={'100px'} src={logo}/>
        <Heading mt={-4} mb={4} textAlign={'center'}>Sistema de Mensageria Mais Valor</Heading>
      </Flex>

      <Box>
        <Heading size={'lg'} borderBottom={'#229544 2px solid'} w={'max-content'} mb={2} ml={2}>Aniversariantes do Mês</Heading>
        <Accordion pos={'absolute'} w={'97%'} display={'flex'} allowMultiple gap={4}>
          <AccordionItem zIndex={99} rounded={'xl'} w={'100%'}>
            <h2>
              <AccordionButton bg={'white'} >
                <Box as='span' flex='1' textAlign='left'>
                  <Text>Aniversariantes Passados</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel bg={'white'} maxH={'250px'} overflowY={'auto'} pb={4} css={{
              '&::-webkit-scrollbar': {
                width: '10px',
                height: '6px'
              },
              '&::-webkit-scrollbar-track': {
                background: '#229544'
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#1A6A31'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              }
            }}>
              <ul>
                {aniversariantesPassados
                  .sort((a, b) => {
                    const diaA = Number(a.aniversario.split('/')[0])
                    const diaB = Number(b.aniversario.split('/')[0])
                    return diaA - diaB
                  })
                  .map(aniversariante => (
                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '5px' }} key={aniversariante.celular}>
                      <Text>
                        {aniversariante.aniversariante}
                      </Text>
                      <Badge colorScheme='red'>
                        {aniversariante.aniversario}
                      </Badge>
                    </li>
                  ))}
              </ul>

            </AccordionPanel>
          </AccordionItem>

          <AccordionItem zIndex={99} rounded={'xl'} w={'100%'}>
            <h2>
              <AccordionButton bg={'white'}>
                <Box as='span' flex='1' textAlign='left'>
                  <Text>Aniversariantes Futuros</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel bg={'white'} maxH={'250px'} overflowY={'auto'} pb={4} css={{
              '&::-webkit-scrollbar': {
                width: '10px',
                height: '6px'
              },
              '&::-webkit-scrollbar-track': {
                background: '#229544'
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#1A6A31'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              }
            }}>
              <ul>
                {aniversariantesFuturos
                  .filter(aniversariante => {
                    const [diaAniversario] = aniversariante.aniversario.split('/').map(Number)
                    const diaAtual = new Date().getDate()
                    return diaAniversario !== diaAtual
                  })
                  .sort((a, b) => {
                    const diaA = Number(a.aniversario.split('/')[0])
                    const diaB = Number(b.aniversario.split('/')[0])
                    return diaA - diaB
                  })
                  .map(aniversariante => (
                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '5px' }} key={aniversariante.celular}>
                      <Text>
                        {aniversariante.aniversariante}
                      </Text>
                      <Badge colorScheme='green'>
                        {aniversariante.aniversario}
                      </Badge>
                    </li>
                  ))}
              </ul>

            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      <Box>
        <Heading size={'lg'} borderBottom={'#229544 2px solid'} w={'max-content'} mt={20} ml={2}>Aniversariantes do Dia</Heading>
        <Text display={diaUtil === 1 ? 'initial' : 'none'} fontWeight={'semibold'} ml={2}>As segundas feiras não podemos esquecer dos aniversariantes do fim de semana! 😅</Text>
        <TableContainer mt={2} bg={'white'} rounded={'xl'} p={5} maxH={'450px'} overflowY={'scroll'} css={{
          '&::-webkit-scrollbar': {
            width: '10px',
            height: '6px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#229544'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#1A6A31'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          }
        }}>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Aniversariante</Th>
                <Th>Departamento</Th>
                <Th >Aniversario</Th>
                <Th isNumeric>Enviar parabéns</Th>
              </Tr>
            </Thead>
            <Tbody >
              {data.aniversariantesDiaFormatado
                .sort((a, b) => a.departamento.localeCompare(b.departamento))
                .map((dados, index) => (
                  <Tr key={index}>
                    <Td>{dados.aniversariante}</Td>
                    <Td><Badge colorScheme='green'>{dados.departamento}</Badge></Td>
                    <Td>{dados.aniversario}</Td>
                    <Td isNumeric><ModalDisparoMsg dadosAniversariante={dados} nomeUsuario={responsavel} celularUsuario={celularResponsavel} /></Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
