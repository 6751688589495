import React, { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'

const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const reducer = (state, action) => {
    const { processamento, filtros, ...newPayload } = action.payload

    switch (action.type) {
      case 'ATUALIZAR_DADOS':
        return {
          ...state,
          dados: newPayload
        }
      case 'CADASTRAR_FILTRO':
        return {
          ...state,
          dados: newPayload
        }
      default:
        return state
    }
  }

  const initialState = { }

  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  )
}
GlobalProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export const useGlobalContext = () => {
  return useContext(GlobalContext)
}
