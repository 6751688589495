import React from 'react'
import { BsPersonFillGear, BsPersonLinesFill } from 'react-icons/bs'

import { Heading, Icon, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorMode } from '@chakra-ui/react'

import { useObtendoQuantidadeTrilhasUsuarioMes } from '../hooks/useObtendoQuantidadeTrilhasUsuarioMes'

export default function TableTrilhaUsuarioMes () {
  const { colorMode } = useColorMode()
  const { data } = useObtendoQuantidadeTrilhasUsuarioMes()
  const dadosQuantidadeTrilhasUsuario = data
  return (
    <>
      <Heading mt={8} fontSize={18} ml={4}>Quantidade de Trilhas Selecionadas por Usuário Mensalmente</Heading>
      <Text fontSize={12} fontWeight={'semibold'} color={colorMode === 'light' ? 'gray.800' : 'gray.200'} mb={2} ml={4} >Acompanhe a quantidade de seleção das trilhas por usuário</Text>
      <TableContainer rounded={'2xl'} bg={colorMode === 'light' ? 'white' : 'gray.600'} padding={'10px 2px'} minH={'250px'} overflowY={'scroll'} maxHeight={'600px'}>
        <Table variant='striped' colorScheme='green' size='sm'>
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th>Gerente</Th>
              <Th>trilhas Selecionadas</Th>
              <Th isNumeric>Quantidade de Seleção</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dadosQuantidadeTrilhasUsuario
              ? (
                  dadosQuantidadeTrilhasUsuario.map((dados) => (
                <Tr fontWeight={'bold'} key={dados.qtde}>
                  <Td >
                    <Icon mb={-1} fontSize={16} mr={2} as={BsPersonLinesFill}/>
                    Parceiro: {dados.nome}
                    <br></br>
                    <Text fontSize={12} color={colorMode === 'light' ? 'black' : 'gray.200'}>
                      <Icon mb={-1} ml={4} fontSize={18} mr={2} as={BsPersonFillGear} />
                      Supervisor: {dados.supervisor === '-' ? 'Sem Supervisor' : dados.supervisor}
                    </Text>
                  </Td>
                  <Td>{dados.gerente}</Td>
                  <Td>{dados.tags}</Td>
                  <Td paddingInlineEnd={'10'} isNumeric>{dados.qtde}</Td>
                </Tr>

                  ))
                )
              : (
              <Tr fontWeight={'bold'} color={'black'} >
                <Td>Nenhum Agente Encontrado</Td>
                <Td>Nenhuma Trilha Selecionada</Td>
                <Td paddingInlineEnd={'10'} isNumeric>0</Td>
              </Tr>
                )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}
