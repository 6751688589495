/* eslint-disable react/no-children-prop */
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { SearchIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputRightElement, Box, Text, VStack, Stack, Avatar, AvatarBadge, Flex, Card, Badge, Divider, useColorMode } from '@chakra-ui/react'

import { obtendoDadosAtendimento } from '../../../../../features/atendimento/atendimentoSlice'
import { useObtendoAtendimentos } from '../hooks/useObtendoAtendimentos'
import AlterarStatusDrawer from './AlterarStatusDrawer'
import SkeletonSidebarContatos from './SkeletonSidebarContatos'
import { useManipulacaoDeMensagem } from '../../../../../layouts/provider/ManipulacaoDeMensagens'

function SidebarListaContatos () {
  const { colorMode } = useColorMode()
  const { statusAtendimento, dadosAtendimento } = useSelector((state) => state.atendimento)
  const { features } = useSelector((state) => state.perfil)
  const { isLoading, data } = useObtendoAtendimentos()
  const { setHandleMenuItem } = useManipulacaoDeMensagem()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const chaveAuth = features || []

  const statusAtendimentoAtual = { 'Aguardando Atendimento': 'yellow', 'Em Andamento': 'green', Fechado: 'gray', 'Cancelado (Por Falta de Interação)': 'red' }

  const atendimentos = data || []

  const clickAtendimento = (element) => {
    queryClient.removeQueries('atendimento_useObtendoMensagensAtendimentos')
    queryClient.invalidateQueries('atendimento_useObtendoAtendimentos')
    dispatch(obtendoDadosAtendimento({ dadosAtendimento: element }))
    setHandleMenuItem(false)
  }

  const highlightText = (text, highlight) => {
    const regex = new RegExp(`(${highlight})`, 'gi')
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase()
        ? (
        <span key={index} style={{ fontWeight: 'bold', background: 'yellow' }}>
          {part}
        </span>
          )
        : (
        <span key={index}>{part}</span>
          )
    )
  }
  const [searchTerm, setSearchTerm] = useState('')

  const handleChange = (event) => { setSearchTerm(event.target.value) }

  const filteredElements = atendimentos.filter((element) => element.usuario.toLowerCase().includes(searchTerm.toLowerCase()))

  return (

    <Box mt='-7px' h={'88vh'} p={2} bg={colorMode === 'light' ? 'white' : 'gray.600'} color={colorMode === 'light' ? 'black' : 'white'} width='100%'>
      <Text>Status do Departamento: {statusAtendimento.statusDepartamento}</Text>
      <>
        <InputGroup mt={4} ml={16} width={'320px'} display={'flex'} alignItems={'center'}>
          <InputRightElement children={<SearchIcon />} />
          <Input
            value={searchTerm}
            onChange={handleChange}
            type='text'
            placeholder='Pesquisar'
          />
          <AlterarStatusDrawer/>
        </InputGroup>
      </>
      <VStack align='stretch'>
        <Box h='72vh' w='100%' overflow='auto' mt={1}>
          {isLoading
            ? (
            <SkeletonSidebarContatos/>
              )
            : (
                filteredElements.length > 0
                  ? (
                      filteredElements.map((element) => (
                <Card color={colorMode === 'light' ? 'gray.500' : 'white'} onClick={() => clickAtendimento(element)} key={element.idProtocolo} size={'sm'} bg={colorMode === 'light' ? dadosAtendimento?.idProtocolo === element.idProtocolo ? 'gray.300' : 'white' : dadosAtendimento?.idProtocolo === element.idProtocolo ? 'gray.400' : 'gray.500' } boxShadow={'none'} _hover={{
                  bg: 'gray.400',
                  cursor: 'pointer'
                }}>
                  <VStack alignItems={'flex-start'} textAlign={'left'} spacing='4' px={2} py={4}>
                    <Flex flex='1' gap='2' alignItems='flex-start' flexWrap='wrap'>
                      <Stack direction='row' spacing={2}>
                        <Avatar src={element.foto}>
                          <AvatarBadge boxSize='1.10em' bg={element.online ? 'green.500' : 'red'} />
                        </Avatar>
                      </Stack>
                      <Box display={'flex'} alignItems={'start'} justifyContent={'space-between'} key={element.usuario} w={'300px'}>
                        <Box>
                          <Text color={colorMode === 'light' ? 'gray.500' : 'black'} fontWeight={'bold'} fontSize={'12px'} >
                            {highlightText(element.usuario, searchTerm)}
                          </Text>
                          <Flex flexDir={chaveAuth.includes('filter_atendimento_todosAtendimentos') ? 'column' : 'row'} alignItems={chaveAuth.includes('filter_atendimento_todosAtendimentos') ? 'flex-start' : 'center'} justifyContent={'space-between'}>
                            <Text color={colorMode === 'light' ? 'gray.500' : 'black'} fontSize={'10px'} >{chaveAuth.includes('filter_atendimento_todosAtendimentos') ? element.agente : element.tipoParceiro }
                              {chaveAuth.includes('filter_atendimento_todosAtendimentos') && <Divider/>}
                            </Text>

                            <Text color={colorMode === 'light' ? 'gray.500' : 'black'} textTransform={'capitalize'} fontSize={'10px'} >{element.trilha}</Text>
                          </Flex>
                          <Text fontSize={'10px'} color={colorMode === 'light' ? 'gray.500' : 'black'} >{element.ultimaMensagem?.mensagem.length > 0 ? element.ultimaMensagem?.mensagem.slice(0, 40) + '...' : ''}</Text>
                          <Badge colorScheme={statusAtendimentoAtual[element.status]} fontSize={12}>{element.status}</Badge>
                          {element?.dataVisto &&
                            <Badge ml={2} colorScheme={'gray'} fontSize={12}>Visto por último: {element.dataVisto.slice(11, 16)}</Badge>
                          }
                        </Box>
                        {element.mensagensNaoLidas?.quantidade > 0 &&
                        <Box display={'flex'} flexDir={'column'} height={'80px'} w={'12%'} alignItems={'end'} justifyContent={'space-between'}>
                          <Text fontSize={'12px'}>{element.ultimaMensagem?.horaMensagem}</Text>
                          <Box bg={'red'} borderRadius={'50%'} w={'20px'} h={'20px'} textAlign={'center'}>
                            <Text textTransform={'uppercase'} fontSize={'12px'} justifyContent={'end'} color={'#fff'} >{element.mensagensNaoLidas?.quantidade}</Text>
                          </Box>
                        </Box>
                        }
                      </Box>
                    </Flex>
                  </VStack>
                </Card>
                      ))
                    )
                  : (
              <Text textAlign={'center'}>Nenhum resultado encontrado.</Text>
                    )
              )}
        </Box>
      </VStack>
    </Box>
  )
}

export default React.memo(SidebarListaContatos)
