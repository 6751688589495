import React from 'react'

import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Box, SkeletonText } from '@chakra-ui/react'

import { useGetReportMenu } from '../hooks/useGetReportMenu'
function TabelaPorDepartamentos () {
  const { isLoading, data } = useGetReportMenu()
  const listaMenu = data?.report || []

  return (
    <>
      {!isLoading
        ? (
        <TableContainer h={'150px'} overflowY={'scroll'} maxHeight={'400px'}>
          <Table overflowY={'scroll'} variant='striped' colorScheme='green' size={'sm'}>
            <Thead >
              <Tr >
                <Th textAlign='left'>Menus</Th>
                <Th textAlign='right'>Quantidade</Th>
              </Tr>
            </Thead>
            <Tbody fontWeight={'bold'}>
              {listaMenu.map(({ QTDE, menu }, i) => (
                <Tr key={i}>
                  <Td textAlign='left'>{menu}</Td>
                  <Td textAlign='right'>{QTDE}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
          )
        : (
        <>
          <Box padding='6' boxShadow='lg' bg='white'>
            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
          </Box>
        </>
          )}
    </>
  )
}

export default TabelaPorDepartamentos
