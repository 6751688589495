import { useMutation } from 'react-query'
import { useState } from 'react'
import api from '../../api/api'

const useStartAllInstancia = () => {
  const [instanceStartAll, setIntanceStartAll] = useState(null)

  const startAllInstancia = async () => {
    const response = await api.post('/api/instances/startAllInstances')
    return response.data
  }

  const mutation = useMutation(startAllInstancia, {
    onSuccess: (data) => {
      setIntanceStartAll(data)
    },
    onError: (error) => {
      console.error('onError:', error)
    }
  })

  const UseRequestStartAllInstancia = () => {
    mutation.mutate()
  }

  return {
    UseRequestStartAllInstancia,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    instanceStartAll
  }
}

export { useStartAllInstancia }
