/* eslint-disable camelcase */
import { BsClock } from 'react-icons/bs'
import React from 'react'

import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { Box, Heading, Icon, useColorMode, useColorModeValue } from '@chakra-ui/react'

import { useObtendoQuantidadeSolicitacoesAtendimentoHora } from '../hooks/useObtendoQuantidadeSolicitacoesAtendimentoHora'

export default function GraficoSolicitacoesAtendimentoHora () {
  const { colorMode } = useColorMode()
  // Configuração do tema animado
  am4core.useTheme(am4themes_animated)
  const { data } = useObtendoQuantidadeSolicitacoesAtendimentoHora()
  const resultadosGrafico = data || []

  const chart = am4core.create('chartdiv', am4charts.XYChart)

  // Defina os dados para o gráfico
  chart.data = resultadosGrafico

  // Crie e configure o eixo X (hora)
  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
  categoryAxis.dataFields.category = 'hora'
  categoryAxis.title.text = 'Hora'
  categoryAxis.renderer.grid.template.stroke = am4core.color(
    useColorModeValue('#000', '#fff')
  )
  categoryAxis.renderer.labels.template.fill = am4core.color(
    useColorModeValue('#000', '#fff')
  )
  categoryAxis.title.fill = am4core.color(useColorModeValue('#000', '#fff'))

  // Crie e configure o eixo Y (quantidade)
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
  valueAxis.title.text = 'Quantidade'
  valueAxis.renderer.grid.template.stroke = am4core.color(
    useColorModeValue('#000', '#fff')
  )
  valueAxis.renderer.labels.template.fill = am4core.color(
    useColorModeValue('#000', '#fff')
  )
  valueAxis.title.fill = am4core.color(useColorModeValue('#000', '#fff'))

  // Crie uma série de linhas
  const series = chart.series.push(new am4charts.LineSeries())
  series.dataFields.valueY = 'quantidade'
  series.dataFields.categoryX = 'hora'
  series.name = 'Quantidade de Solicitações de Atendimento por Hora'
  series.stroke = am4core.color('#229544')
  series.strokeWidth = 4

  // Adicionar marcadores de valor
  series.dataItems.template.locations.categoryX = 0.5
  const valueLabel = series.bullets.push(new am4charts.LabelBullet())
  valueLabel.label.text = '{valueY}'
  valueLabel.label.fill = am4core.color('#fff')
  valueLabel.label.fontSize = 12
  valueLabel.label.verticalCenter = 'center'
  valueLabel.label.dy = -10
  valueLabel.label.textAlign = 'center'

  // Configurar o balão personalizado
  const balloon = valueLabel.label.createChild(am4core.Rectangle)
  balloon.fill = am4core.color('#229544')
  balloon.stroke = am4core.color('#fff')
  balloon.strokeWidth = 6
  balloon.cornerRadius = 5
  balloon.width = 30
  balloon.height = 30
  balloon.align = 'center'
  balloon.verticalCenter = 'top'
  balloon.dy = -5
  balloon.dx = -10

  // Adicione legendas
  chart.legend = new am4charts.Legend()
  chart.legend.labels.template.fill = am4core.color(
    useColorModeValue('#000', '#fff')
  )

  // Adicione um cursor de zoom/pan
  chart.cursor = new am4charts.XYCursor()
  chart.cursor.xAxis = categoryAxis

  // Adicione um botão de zoom de reset
  const resetZoomButton = chart.plotContainer.createChild(am4core.ZoomOutButton)
  resetZoomButton.align = 'right'
  resetZoomButton.valign = 'top'
  resetZoomButton.marginRight = 15
  resetZoomButton.marginTop = 15

  // Crie o contêiner do gráfico no HTML
  chart.exporting.menu = new am4core.ExportMenu()
  chart.exporting.filePrefix = 'grafico'

  return (
    <Box
      bg={colorMode === 'light' ? 'white' : 'gray.600'}
      w={'100%'}
      maxW='100%'
      mx={'auto'}
      pt={5}
      px={{ base: 2, sm: 12, md: 17 }}
    >
      <Box textAlign={{ base: 'center', md: 'left' }} mb={'-50px'} py={5}>
        <Heading
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          mt={-4}
          fontSize={{ md: '18px', base: '14px' }}
          fontWeight={'bold'}
          mb={4}
          ml={10}
        >
          Quantidade Solicitações de Atendimento por Hora
          <Icon
            color={'white'}
            bg={'#229544'}
            w={'50px'}
            h={'50px'}
            p={2}
            rounded={'12px 0 12px 12px'}
            mb={-2}
            fontSize={40}
            as={BsClock}
          />
        </Heading>
        <Box id='chartdiv' style={{ width: '100%', height: '500px' }}></Box>
      </Box>
    </Box>
  )
}
