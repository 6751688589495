import { useMutation } from 'react-query'
import apiAdmin from '../../../../../services/apiAdmin'

const useTabularAtendimento = () => {
  const mutation = useMutation(async (payload) => {
    await apiAdmin.put('/v1/api/admin/atendimento/aplicar-tabulacao-atendimento', payload)
  })

  const UseRequestTabularAtendimento = (idProtocolo, tabulacaoId, tabulacaoCorresponde) => {
    return mutation.mutateAsync({ idProtocolo, tabulacaoId, tabulacaoCorresponde })
  }

  return {
    UseRequestTabularAtendimento,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export { useTabularAtendimento }
