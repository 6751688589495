import { useMutation, useQueryClient } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useAtivarInativarTrilha = () => {
  const queryClient = useQueryClient()
  const alterarStatusTrilha = async (payload) => {
    const response = await apiAdmin.put('/v1/api/admin/gerenciamento/chatbot/ativarInativarTrilha', payload)

    queryClient.invalidateQueries('trilha_useAtivarInativar')

    if (response.status === 200) {
      mutation.isSuccess = true
      mutation.isError = false
    }

    if (response.status !== 200) {
      mutation.isSuccess = false
      mutation.isError = true
    }
  }

  const mutation = useMutation(alterarStatusTrilha)

  const UseRequestAlterarStatusTrilha = (payload) => {
    mutation.mutate(payload)
  }
  return {
    UseRequestAlterarStatusTrilha,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export {
  useAtivarInativarTrilha
}
