import { useMutation } from 'react-query'
import { useState } from 'react'
import api from '../../api/api'

const useCreateInstancia = () => {
  const [instanceCreate, setIntanceCreate] = useState(null)

  const createInstancia = async (payload) => {
    const response = await api.post('/api/instances/createInstance', payload)
    return response.data
  }

  const mutation = useMutation(createInstancia, {
    onSuccess: (data) => {
      setIntanceCreate(data)
    },
    onError: (error) => {
      console.error('onError:', error)
    }
  })

  const UseRequestCreateInstancia = (payload) => {
    mutation.mutate(payload)
  }

  return {
    UseRequestCreateInstancia,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    instanceCreate
  }
}

export { useCreateInstancia }
