/* eslint-disable react/prop-types */
import {
  Button, Divider, Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalHeader, ModalOverlay, useDisclosure
} from '@chakra-ui/react'
import React from 'react'
import { StepperTrilha } from './StepperTrilha'
import { FieldArray, Formik } from 'formik'

export const ModalTrilha = ({ campanha }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button onClick={onOpen}
        colorScheme="blue"
        size="sm"
        w="100%"
      >
        Editar trilha
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mb={2} fontSize={28}>
            Campanha de {campanha.nome}
            <Divider mt={2} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik>
              {({ values }) => (
                  <FieldArray
                    name="trilha"
                    render={arrayHelpers => (
                      <StepperTrilha campanha={campanha.id} arrayHelpers={arrayHelpers} values={values} />
                    )}
                  />
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
