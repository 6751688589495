import React, { memo } from 'react'
import { Card, CardBody, Divider, Flex, HStack, Heading, Image, Progress, Stack, Text } from '@chakra-ui/react'
import imgSim from '../../../../../assets/corresponde.png'
import imgNao from '../../../../../assets/NaoCorresponde.png'
import { useObtendoTabulacoes } from '../../hooks/useObtendoQtdTabulacoes'
function CardsTabulacao () {
  const { data } = useObtendoTabulacoes()
  const resultadoTabulacao = data

  return (
    resultadoTabulacao
      ? (
        <HStack w={'100%'} alignItems={'center'} justifyContent={'center'} >

          <Card
            w={'42%'}
            h={'140px'}
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            variant='outline'
            boxShadow={'lg'}
            rounded={'2xl'}
          >
            <Image
              w={'200px'}
              h={'140px'}
              src={imgSim}
              alt='imagem corresponde'
            />

            <Stack>
              <CardBody>
                <Flex justifyContent={'space-between'}>
                  <Heading size='sm'>Tabulações Correspondentes</Heading>
                  <Text borderRadius={'12px 12px 12px 0'} padding={2} fontSize={16} bg={'green'} color={'white'} pos={'absolute'} right={2} bottom={10} fontWeight={'bold'}>{resultadoTabulacao[0].corresponde_sim}</Text>
                </Flex>
                <Divider />

                <Text mt={-2} fontSize={12} fontWeight={'bold'} py='2'>
                  Essas tabulações foram feitas pelo atendente conferindo que o assunto tratado conferia com a trilha selecionada.
                </Text>
              </CardBody>

              <Stack paddingInline={6} mt={-12} textAlign={'right'} spacing={5}>
                <Progress rounded={'2xl'} colorScheme='green' size='sm' value={80} />
                <Text fontWeight={'bold'} mt={-4} >{Number(resultadoTabulacao[0].porcentagem_sim).toFixed(2)}%</Text>
              </Stack>

            </Stack>
          </Card>

          <Card
            w={'44%'}
            h={'140px'}
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            variant='outline'
            boxShadow={'lg'}
            rounded={'2xl'}
          >
            <Image
              w={'150px'}
              h={'140px'}
              src={imgNao}
              alt='imagem não corresponde'
            />

            <Stack>
              <CardBody>
                <Flex justifyContent={'space-between'}>
                  <Heading size='sm'>Tabulações Não Correspondentes</Heading>
                  <Text borderRadius={'12px 12px 12px 0'} padding={2} fontSize={16} bg={'red'} color={'white'} pos={'absolute'} right={2} bottom={10} fontWeight={'bold'}>{resultadoTabulacao[0].corresponde_nao}</Text>
                </Flex>
                <Divider />

                <Text mt={-2} fontSize={12} fontWeight={'bold'} py='2'>
                  Essas tabulações foram feitas pelo atendente que conferiu que o assunto tratado não correspondia com a trilha selecionada.
                </Text>
              </CardBody>

              <Stack paddingInline={6} mt={-12} textAlign={'right'} spacing={5}>
                <Progress rounded={'2xl'} colorScheme='red' size='sm' value={20} />
                <Text fontWeight={'bold'} mt={-4} >{Number(resultadoTabulacao[0].porcentagem_nao).toFixed(2)}%</Text>
              </Stack>
            </Stack>
          </Card>

          <Flex flexDir={'column'} gap={2}>

            <Card
              h={'136px'}
              color={'white'}
              bg={'blue.400'}
              direction={{ base: 'column', sm: 'row' }}
              overflow='hidden'
              variant='outline'
              boxShadow={'lg'}
              borderRadius={'8px 0 8px 8px'}
            >

              <Stack>
                <CardBody>
                  <Flex>
                    <Heading fontSize={12}>Total de Tabulações:</Heading>
                  </Flex>
                  <Divider />

                </CardBody>

                <Stack mt={-12} textAlign={'center'} >
                  <Text fontSize={20} color={'white'} fontWeight={'bold'}>{Number(resultadoTabulacao[0].corresponde_sim) + Number(resultadoTabulacao[0].corresponde_nao)} </Text>
                </Stack>
              </Stack>
            </Card>
          </Flex>

        </HStack>
        )
      : null
  )
}

export default memo(CardsTabulacao)
