import React from 'react'

import { Box, Container, Text } from '@chakra-ui/react'

import Logo from './Logo'

function ConfirmacaoProposta () {
  return (
    <Container margin={'10px auto'} w={'450px'} shadow={'none'} centerContent>
      <Box display={'flex'} flexDir={'column'} textAlign={'center'} gap={10} shadow={'none'} padding='4' color='black'>
        <Logo />
        <Text fontWeight={'semibold'} fontSize='20px'>Agradecemos pela sua resposta. <br /> Foi entregue, registrada e devidamente salva. 🔐 <br></br> Obrigado e até logo!</Text>
      </Box>
    </Container>
  )
}

export default ConfirmacaoProposta
