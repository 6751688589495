import { Box, Button, Flex, Heading } from '@chakra-ui/react'
import React from 'react'
import BuscarTodasAsInstancias from './components/BuscarTodasAsInstancias'
import { useCloseAllInstancia } from './components/hooks/useCloseAllInstancia'
import { useStartAllInstancia } from './components/hooks/useStartAllInstancia'
import CriarNovaInstancia from './components/CriarNovaInstancia'

export default function Instancias () {
  const { UseRequestCloseAllInstancia } = useCloseAllInstancia()
  const { UseRequestStartAllInstancia } = useStartAllInstancia()

  return (
    <Box w={'100%'}>
      <Flex w={'100%'} gap={2} p={4} alignItems={'center'} justifyContent={'space-between'}>
        <Heading>Gerenciamento de Instâncias</Heading>
        <Flex gap={2} >
          <Button onClick={UseRequestCloseAllInstancia} colorScheme='red'>Encerrar Processos</Button>
          <Button onClick={UseRequestStartAllInstancia} colorScheme='green'>Iniciar Processos</Button>
        </Flex>
      </Flex>
      <BuscarTodasAsInstancias />
      <CriarNovaInstancia/>
    </Box>
  )
}
