/* eslint-disable react/prop-types */
import React from 'react'
import { Box, Button, Flex, Heading } from '@chakra-ui/react'
import { IoCheckmarkDone } from 'react-icons/io5'

export default function HeaderComponent ({ isAutomatic, handlePlayClick }) {
  return (
    <Flex alignItems={'center'} justifyContent={'space-between'} px={4} py={2} gap={2}>
      <Flex alignItems={'center'} justifyContent={'center'} gap={2}>
        <Heading size={'md'} textTransform={'uppercase'} fontWeight={'semibold'}>Confirmação de Propostas</Heading>
        <IoCheckmarkDone size={30} color='blue' />
      </Flex>
      <Box>
        {!isAutomatic && <Button colorScheme='green' bg={'#229544'} onClick={handlePlayClick} ml={4}>Iniciar</Button>}
      </Box>
    </Flex>
  )
}
