import { useMutation } from 'react-query'
import { useState } from 'react'
import api from '../../api/api'

const useScannerQrCode = () => {
  const [qrCodeData, setQrCodeData] = useState(null)

  const scannerQrCode = async (payload) => {
    const response = await api.post('/api/instances/getQrCode', payload)
    return response.data
  }

  const mutation = useMutation(scannerQrCode, {
    onSuccess: (data) => {
      setQrCodeData(data)
    },
    onError: (error) => {
      console.error('onError:', error)
    }
  })

  const UseRequestScannerQrCode = (payload) => {
    mutation.mutate(payload)
  }

  return {
    UseRequestScannerQrCode,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    qrCodeData
  }
}

export { useScannerQrCode }
