/* eslint-disable react/prop-types */
import { Button } from '@chakra-ui/react'
import React from 'react'
import { useAdicionarNovaEtapa } from '../hooks/useAdicionarNovaEtapa'
export const ButtonAdicionarEtapa = ({ ArrayTrilha, idCampanha }) => {
  const { UseRequestAdicionarEtapa } = useAdicionarNovaEtapa()
  const handleAddEtapa = async () => {
    const novaEtapa = {
      etapa: ArrayTrilha ? ArrayTrilha.length + 1 : 1,
      mensagem: ' ',
      captura: false,
      mensagemResposta: ' ',
      fila: 'whatsApp_vagas_emprego',
      idCampanha,
      opcoesTrilha: []
    }

    UseRequestAdicionarEtapa({ novaEtapa })
    window.location.reload()
  }
  return (
    <Button onClick={handleAddEtapa}>
      Adicionar etapa
    </Button>
  )
}
