import { useQuery } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useObtendoQuantidadeSolicitacoesAtendimentoHora = () => {
  return useQuery(
    'atendimento_useObtendoQuantidadeSolicitacoesAtendimentoHora',
    async () => {
      const response = await apiAdmin.get('/v1/api/admin/dashboardPainelDeAtendimento/quantidadeDeSolicitacoesDeAtendimentosPorHora')
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000 * 1000 * 5,
      refetchInterval: 300000
    }
  )
}

export { useObtendoQuantidadeSolicitacoesAtendimentoHora }
