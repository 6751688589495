/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { BsCheckAll, BsDownload, BsFilePdf } from 'react-icons/bs'
import { RiFileExcel2Fill } from 'react-icons/ri'
import { useSelector } from 'react-redux'

import { AspectRatio, Avatar, Box, Button, Icon, Image, Slide, Text, useColorMode, useDisclosure } from '@chakra-ui/react'

import { useManipulacaoDeMensagem } from '../../../../../layouts/provider/ManipulacaoDeMensagens'
import ManipulacaoDeMensagens from './ManipulacaoDeMensagens'

const ChatBalloon = ({ ocultarMensagem, mensagensRespondida_direcao, mensagemRespondida_originalname, mensagemRespondida_mimetype, text, sender, perfil, hora, name, searchTerm, mimetype, originalname, mensagemLida, idProtocolo, idMensagem, mensagemRespondida }) => {
  const [isFullScreen, setIsFullScreen] = useState(false)
  const { isOpen, onToggle } = useDisclosure()
  const { dadosAtendimento } = useSelector((state) => state.atendimento)
  const { colorMode } = useColorMode()

  const highlightText = (msg, highlight) => {
    const regex = new RegExp(`(${highlight})`, 'gi')
    return msg.split(regex).map((part, index) =>
      part.toString().toLowerCase() === highlight.toString().toLowerCase()
        ? (
        <span key={index} style={{ fontWeight: 'bold', background: '#eddc8a' }}>
          {part}
        </span>
          )
        : (
        <span key={index}>
          {part.includes('\n')
            ? (
            <span> {part} <br /> </span>
              )
            : (
                part
              )}
        </span>
          )
    )
  }

  function isImage (mimetype) {
    if (mimetype === null) {
      return false
    }
    return mimetype.includes('image')
  }

  function isVideo (mimetype) {
    if (mimetype === null) {
      return false
    }
    return mimetype.includes('video')
  }

  function isPDF (mimetype) {
    if (mimetype === null) {
      return false
    }
    return mimetype.includes('pdf')
  }

  function isExcel (mimetype) {
    if (mimetype === null) {
      return false
    }
    return mimetype.includes('sheet')
  }

  const { handleMenuItem, setHandleMenuItem } = useManipulacaoDeMensagem()

  useEffect(() => {
    if (ocultarMensagem === true && handleMenuItem === true) {
      setHandleMenuItem(false)
    }
  }, [ocultarMensagem, setHandleMenuItem, handleMenuItem])

  return (
    <>
      {sender === 'in'
        ? (
        <Box mt={4}>
          <Box display={'flex'}>
            <Avatar mt='-8px' size='sm' src={perfil} />
            <Text textTransform={'uppercase'} fontWeight={'bold'} ml={2} mb={6} fontSize='12px' color={colorMode === 'light' ? 'gray.700' : 'black'}>
              {name}
            </Text>
            <Text display={'flex'} ml={2} fontSize='12px' color={colorMode === 'light' ? 'gray.700' : 'black'}>
              {hora}
              <ManipulacaoDeMensagens idProtocolo={idProtocolo} idMensagem={idMensagem} mensagem={text} direcao={sender} />
            </Text>
          </Box>
          <Box fontWeight={'bold'} color={'black'} ml={10} mt={'-20px'} bg='gray.300' borderRadius={'0 12px 12px 12px'} h={'max-content'} maxW={'max-content'} p={2}>
            {isImage(mimetype)
              ? (
              <>
                {isFullScreen
                  ? (
                  <Box rounded={'2xl'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDir={'column'} w={'940px'} h={'450px'} padding={'10'} zIndex={999} bg={'gray.200'} pos={'fixed'} bottom={'10'} right={'2'}>
                    <Slide in={isOpen}>
                      <Box rounded={'2xl'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDir={'column'} w={'940px'} h={'450px'} padding={'10'} zIndex={999} bg={'gray.200'} pos={'fixed'} bottom={'10'} right={'2'}>
                        <Image
                          onClick={() => {
                            setIsFullScreen(!isFullScreen)
                            onToggle()
                          }}

                          maxW={'100%'} maxH={'90%'} src={text} alt='Preview da imagem selecionada' />
                        <a target='_blank' href={text} rel='noreferrer'>
                          <Text textTransform={'uppercase'} color={'white'} bg={'#229544'} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={4} padding={2} rounded={'2xl'} mt={2} ml={2}>
                            Baixar Imagem
                            <Icon fontSize={22} as={BsDownload} />
                          </Text>
                        </a>
                        <Button
                          onClick={() => {
                            setIsFullScreen(!isFullScreen)
                            onToggle()
                          }}
                          cursor={'pointer'} pos={'absolute'} top={10} right={10} bg={'gray.300'} color={'black'} padding={2} w={'40px'} h={'40px'} textAlign={'center'} rounded={'md'}>X</Button>
                      </Box>
                    </Slide>
                  </Box>
                    )
                  : (
                  <>
                    <Image
                      onClick={() => {
                        setIsFullScreen(!isFullScreen)
                        onToggle()
                      }}
                      maxW={'350px'} src={text} alt='Preview da imagem selecionada' />
                  </>
                    )}
              </>
                )
              : isVideo(mimetype)
                ? (
              <>
                <AspectRatio h={'250px'} w='260px' ratio={1}>
                  <video controls title='video' controlsList='nodownload'>
                    <track kind='captions' srcLang='pt' label='Portuguese' />
                    <source src={text} />
                  </video>
                </AspectRatio>
                <a target='_blank' href={text} rel='noreferrer'>
                  <Text mt={2} ml={2}><Icon fontSize={22} as={BsDownload} /></Text>
                </a>
              </>
                  )
                : isPDF(mimetype)
                  ? (
              <>
                <a target='_blank' href={text} rel='noreferrer'>
                  <Box display={'flex'} align={'center'} justifyContent={'center'} gap={2} >
                    <Icon rounded={8} bg={'white'} color={'red'} padding={1} w={'30px'} h={'30px'} mt={1} fontSize={22} as={BsFilePdf} />
                    <Text mt={1} mr={2}>{highlightText(originalname, searchTerm)} </Text>
                    <Icon mt={1} fontSize={22} as={BsDownload} />
                  </Box>
                </a>
              </>
                    )
                  : isExcel(mimetype)
                    ? (
              <>
                <a target='_blank' href={text} rel='noreferrer'>
                  <Box display={'flex'} align={'center'} justifyContent={'center'} gap={2} >
                    <Icon rounded={8} bg={'white'} color={'green'} padding={1} w={'30px'} h={'30px'} mt={1} fontSize={22} as={RiFileExcel2Fill} />
                    <Text mt={1} mr={2}>{highlightText(originalname, searchTerm)} </Text>
                    <Icon mt={1} fontSize={22} as={BsDownload} />
                  </Box>
                </a>
              </>
                      )
                    : (
                        highlightText(text, searchTerm)
                      )}
          </Box>
        </Box>
          )
        : (
        <Box display={ocultarMensagem ? 'none' : 'flex'} flexDir={'column'} justifyContent={'center'} alignItems={'end'} mt={6} mb={6}>
          <Box display={'flex'}>
            <Text color={colorMode === 'light' ? 'gray.700' : 'black'} display={'flex'} mr={2} fontSize='12px'>
              <ManipulacaoDeMensagens idProtocolo={idProtocolo} idMensagem={idMensagem} mensagem={text} direcao={sender} />
              <Box mt={-0.5}>
                <BsCheckAll fontSize={'22px'} color={mensagemLida ? '#0267C3' : 'gray.200'} />
              </Box>
              {hora}
            </Text>
            <Text color={colorMode === 'light' ? 'gray.700' : 'black'} textTransform={'uppercase'} fontWeight={'bold'} mr={2} mb={6} fontSize='12px'>
              {name}
            </Text>
            <Avatar mt='-8px' size='sm' src={perfil} />
          </Box>
          <Box color={'#fff'} mr={8} mt={'-15px'} bg='#229544' borderRadius={'12px 0 12px 12px'} h={'max-content'} maxW={'max-content'} p={2}>

            {mensagemRespondida && mensagemRespondida_mimetype && isImage(mensagemRespondida_mimetype)
              ? (
              <Box bg={'green.700'} p={'2px 5px'} borderRadius={'4px 0 4px 4px'} fontSize={'12px'} borderLeft={'solid 3px #CBD5E0'}>
                <Text>
                  {mensagensRespondida_direcao === 'out' ? 'VOCÊ' : dadosAtendimento.usuario}:
                </Text>
                <>
                  <Image
                    onClick={() => {
                      setIsFullScreen(!isFullScreen)
                      onToggle()
                    }}
                    maxW={'350px'} src={mensagemRespondida} alt='Preview da imagem selecionada' />
                </>
              </Box>
                )
              : mensagemRespondida && !mensagemRespondida_mimetype && (
              <Box bg = { 'green.700' } p = { '2px 5px' } borderRadius = { '4px 0 4px 4px' } fontSize = { '12px' } borderLeft = { 'solid 3px #CBD5E0' }>
                <Text>
                  {mensagensRespondida_direcao === 'out' ? 'VOCÊ' : dadosAtendimento.usuario}:
                </Text>
                <Text>
                  {mensagemRespondida}
                </Text>
              </Box>
              ) }

            {mensagemRespondida && mensagemRespondida_mimetype && isVideo(mensagemRespondida_mimetype) &&
              <Box bg={'green.700'} p={'2px 5px'} borderRadius={'4px 0 4px 4px'} fontSize={'12px'} borderLeft={'solid 3px #CBD5E0'}>
                <Text>
                  {mensagensRespondida_direcao === 'out' ? 'VOCÊ' : dadosAtendimento.usuario}:
                </Text>
                <AspectRatio h={'250px'} w='260px' ratio={1}>
                  <video controls title='video' controlsList='nodownload'>
                    <track kind='captions' srcLang='pt' label='Portuguese' />
                    <source src={mensagemRespondida} />
                  </video>
                </AspectRatio>
              </Box>
            }

            {mensagemRespondida && mensagemRespondida_mimetype && isPDF(mensagemRespondida_mimetype) &&
              <Box bg={'green.700'} p={'2px 5px'} borderRadius={'4px 0 4px 4px'} fontSize={'12px'} borderLeft={'solid 3px #CBD5E0'}>
                <Text>
                  {mensagensRespondida_direcao === 'out' ? 'VOCÊ' : dadosAtendimento.usuario}:
                </Text>
                <a target='_blank' href={mensagemRespondida} rel='noreferrer'>
                  <Box display={'flex'} align={'center'} justifyContent={'center'} gap={2} >
                    <Icon rounded={8} bg={'white'} color={'red'} padding={1} w={'30px'} h={'30px'} mt={1} fontSize={22} as={BsFilePdf} />
                    <Text mt={1} mr={2}>{highlightText(mensagemRespondida_originalname, searchTerm)} </Text>
                  </Box>
                </a>
              </Box>
            }

            {mensagemRespondida && mensagemRespondida_mimetype && isExcel(mensagemRespondida_mimetype) &&
              <Box bg={'green.700'} p={'2px 5px'} borderRadius={'4px 0 4px 4px'} fontSize={'12px'} borderLeft={'solid 3px #CBD5E0'}>
                <Text>
                  {mensagensRespondida_direcao === 'out' ? 'VOCÊ' : dadosAtendimento.usuario}:
                </Text>
                <a target='_blank' href={text} rel='noreferrer'>
                  <Box display={'flex'} align={'center'} justifyContent={'center'} gap={2} >
                    <Icon rounded={8} bg={'white'} color={'green'} padding={1} w={'30px'} h={'30px'} mt={1} fontSize={22} as={RiFileExcel2Fill} />
                    <Text mt={1} mr={2}>{highlightText(mensagemRespondida_originalname, searchTerm)} </Text>
                  </Box>
                </a>
              </Box>
            }

            {isImage(mimetype)
              ? (
              <>

                {isFullScreen
                  ? (
                  <Box rounded={'2xl'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDir={'column'} w={'940px'} h={'450px'} padding={'10'} zIndex={999} bg={'gray.200'} pos={'fixed'} bottom={'10'} right={'2'}>
                    <Slide in={isOpen}>
                      <Box rounded={'2xl'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDir={'column'} w={'940px'} h={'450px'} padding={'10'} zIndex={999} bg={'gray.200'} pos={'fixed'} bottom={'10'} right={'2'}>
                        <Image
                          onClick={() => {
                            setIsFullScreen(!isFullScreen)
                            onToggle()
                          }}

                          maxW={'100%'} maxH={'90%'} src={text} alt='Preview da imagem selecionada' />
                        <a target='_blank' href={text} rel='noreferrer'>
                          <Text textTransform={'uppercase'} bg={'#229544'} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={4} padding={2} rounded={'2xl'} mt={2} ml={2}>
                            Baixar Imagem
                            <Icon fontSize={22} as={BsDownload} />
                          </Text>
                        </a>
                        <Button
                          onClick={() => {
                            setIsFullScreen(!isFullScreen)
                            onToggle()
                          }}
                          cursor={'pointer'} pos={'absolute'} top={10} right={10} bg={'gray.300'} color={'black'} padding={2} w={'40px'} h={'40px'} textAlign={'center'} rounded={'md'}>X</Button>
                      </Box>
                    </Slide>
                  </Box>
                    )
                  : (
                  <>
                    <Image
                      onClick={() => {
                        setIsFullScreen(!isFullScreen)
                        onToggle()
                      }}
                      maxW={'350px'} src={text} alt='Preview da imagem selecionada' />
                  </>
                    )}

              </>
                )
              : isVideo(mimetype)
                ? (
              <>
                <AspectRatio h={'250px'} w='260px' ratio={1}>
                  <video controls title='video' controlsList='nodownload'>
                    <track kind='captions' srcLang='pt' label='Portuguese' />
                    <source src={text} />
                  </video>
                </AspectRatio>
                <a target='_blank' href={text} rel='noreferrer'>
                  <Text mt={2} ml={2}><Icon fontSize={22} as={BsDownload} /></Text>
                </a>
              </>
                  )
                : isPDF(mimetype)
                  ? (
              <>
                <a target='_blank' href={text} rel='noreferrer'>
                  <Box display={'flex'} align={'center'} justifyContent={'center'} gap={2} >
                    <Icon rounded={8} bg={'white'} color={'red'} padding={1} w={'30px'} h={'30px'} mt={1} fontSize={22} as={BsFilePdf} />
                    <Text mt={1} mr={2}>{highlightText(originalname, searchTerm)} </Text>
                    <Icon mt={1} fontSize={22} as={BsDownload} />
                  </Box>
                </a>
              </>
                    )
                  : isExcel(mimetype)
                    ? (
              <>
                <a target='_blank' href={text} rel='noreferrer'>
                  <Box display={'flex'} align={'center'} justifyContent={'center'} gap={2} >
                    <Icon rounded={8} bg={'white'} color={'green'} padding={1} w={'30px'} h={'30px'} mt={1} fontSize={22} as={RiFileExcel2Fill} />
                    <Text mt={1} mr={2}>{highlightText(originalname, searchTerm)} </Text>
                    <Icon mt={1} fontSize={22} as={BsDownload} />
                  </Box>
                </a>
              </>
                      )
                    : (
                        highlightText(text, searchTerm)
                      )}
          </Box>
        </Box>
          )}
    </>
  )
}

export default ChatBalloon
