import { useQuery } from 'react-query'

import api from '../../../../services/api'

const useObterAgenda = (payload) => {
  return useQuery(
    ['useObterAgenda', { payload }],
    async () => {
      const response = await api.get(`/v1/api/public/agenda-compromissos?campanhaId=${payload.campanhaId}&baseId=${payload.baseId}`)
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 60000,
      refetchInterval: 10000,
      enabled: !!payload.campanhaId
    }
  )
}

export { useObterAgenda }
