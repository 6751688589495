import { useMutation } from 'react-query'

import apiAdmin from '../../../../../../services/apiAdmin'

const useUpdateProcessamento = () => {
  const atualizarProcesso = async (payload) => {
    const response = await apiAdmin.put('/v1/api/admin/bibliotecaProcessos/atualizar', payload)

    if (response.status === 200) {
      mutation.isSuccess = true
      mutation.isError = false
    }

    if (response.status !== 200) {
      mutation.isSuccess = false
      mutation.isError = true
    }
  }

  const mutation = useMutation(atualizarProcesso)

  const UseRequestAtualizarProcesso = (payload) => {
    mutation.mutate(payload)
  }
  return {
    UseRequestAtualizarProcesso,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export { useUpdateProcessamento }
