/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Search2Icon } from '@chakra-ui/icons'
import { Box, Text, Flex, Heading, Spinner, Icon, Input, SkeletonText, SkeletonCircle } from '@chakra-ui/react'

import MensagemEntrada from '../components/MensagemEntrada'
import MensagemSaida from '../components/MensagemSaida'
import { useGetMensagensAtendimento } from '../hooks/useGetMensagensAtendimento'
import SkeletonMessagensChat from './SkeletonMensagensChat'

export default function Mensagens () {
  const [loadScroll, setloadScroll] = React.useState(false)
  const baseScroll = React.useRef(null)
  const { informacao } = useSelector((state) => state.blocoInformacaoAtendimento)

  const { data, isLoading: isLoadingDadosMensagens, isError } = useGetMensagensAtendimento({
    idProtocolo: informacao.idProtocolo
  })

  const [searchTerm, setSearchTerm] = useState('')

  const mensagens = data || []

  const handleChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const filteredElements = mensagens.filter((element) =>
    element.mensagem.toLowerCase().includes(searchTerm.toLowerCase())
  )

  React.useEffect(() => {
    if (loadScroll) { baseScroll.current.scrollTop = baseScroll.current.scrollHeight }
  }, [loadScroll])

  function handleMouseLeave () {
    setloadScroll(false)
  }

  React.useEffect(() => {
    setloadScroll(true)
  }, [data])

  return (
    <>

      <Flex w={'100%'} rounded={'2xl'} onMouseLeave={handleMouseLeave} ref={baseScroll} mt='1' height={400} overflowY={'scroll'} overflowX={'hidden'} p='2'>
        {!isError
          ? (
          <>
            {!isLoadingDadosMensagens
              ? (
              <>
                {informacao.status === 'Aguardando Atendimento' && (
                  <>
                    <Flex m={' 0 auto 0 auto'} justifyContent={'center'} alignItems={'center'} ml={'25%'}>
                      <Box display={'flex'} flexDir={'column'} alignContent={'center'} justifyContent={'center'} alignItems={'center'} textAlign='center' py={10} px={6}>
                        <Spinner
                          thickness='4px'
                          speed='0.65s'
                          emptyColor='gray.200'
                          color='green'
                          size='xl'
                        />
                        <Heading as='h2' size='xl' mt={6} mb={2}>
                          Nº de Protocolo do Atendimento: {informacao.idProtocolo}
                        </Heading>
                        <Text color={'gray.500'}>
                          Muitos atendimentos ao cliente são resolvidos por meio de orientação, da instrução amigável, simples e de fácil execução. Em breve você sera atendido.
                        </Text>
                      </Box>
                    </Flex>
                  </>
                )}
                {informacao.status !== 'Aguardando Atendimento' && (
                  <>

                    <Box w={'350px'}
                      rounded={'2xl'} p={2}
                      bg={'gray.200'} top={'16'} mt={1}
                      pos={'fixed'} right={4} display={'flex'}
                      ref={baseScroll}
                      alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                      <Icon as={Search2Icon} fontSize={20} mr={4} />
                      <Input
                        onChange={handleChange}
                        value={searchTerm}
                        placeholder='Buscar Mensagens...'
                        variant='unstyled' />
                    </Box>
                  </>
                )}
                <Box ref={baseScroll} w={'90%'} spacing={2} mb={2} mt={2} display={'flex'} flexDirection={'column'} gap={2} >

                  {isLoadingDadosMensagens
                    ? (
                    <>
                      <SkeletonMessagensChat />
                    </>
                      )
                    : (
                    <>
                      {filteredElements.map(({ direcao, autor, mensagem, data, mimetype, originalname, foto, mensagemLida, mensagemRespondida_originalname, mensagensRespondida_direcao, mensagemRespondida_mimetype, mensagemRespondida }) => (
                        <>
                          {direcao === 'out' && (
                            <Box>
                              <MensagemEntrada searchTerm={searchTerm} autor={autor} data={data} mensagem={mensagem} mimetype={mimetype} originalname={originalname} foto={foto} mensagemLida={mensagemLida} mensagemRespondida_originalname={mensagemRespondida_originalname} mensagensRespondida_direcao={mensagensRespondida_direcao} mensagemRespondida_mimetype={mensagemRespondida_mimetype} mensagemRespondida={mensagemRespondida}/>
                            </Box>
                          )}
                          {direcao === 'in' && (
                            <Box >
                              <MensagemSaida searchTerm={searchTerm} autor={autor} data={data} mensagem={mensagem} mimetype={mimetype} originalname={originalname} foto={foto} mensagemLida={mensagemLida} mensagemRespondida_originalname={mensagemRespondida_originalname} mensagensRespondida_direcao={mensagensRespondida_direcao} mensagemRespondida_mimetype={mensagemRespondida_mimetype} mensagemRespondida={mensagemRespondida}/>
                            </Box >
                          )}
                        </>
                      ))}
                    </>
                      )}

                </Box>
              </>
                )
              : (
              <>
                <Box padding='1' w={'50%'}>
                  <SkeletonCircle size='10' mb={2} />
                  <SkeletonText noOfLines={5} spacing='4' skeletonHeight='2' mb={4} />
                  <Text fontSize={12} fontWeight={'hairline'} color={'teal'}>Carregando</Text>
                </Box>
              </>
                )}
          </>
            )
          : (
              null
            )}
      </Flex>
    </>
  )
}
