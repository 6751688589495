import React, { useState } from 'react'
import { Box, Button, Divider, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Switch, useDisclosure } from '@chakra-ui/react'
import { FaRegEdit } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { useGetGerenciadorIdMenu } from '../hooks/useGetGerenciadorIdMenu'
import { useGetPerguntas } from '../hooks/useGetPerguntas'
import { useGetRespostas } from '../hooks/useGetRespostas'
import { useGetProcessamentos } from '../hooks/useGetProcessamentos'

export default function DetailsGerenciador ({ menu, idMenu, idDepartamento }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const detailsForMenu = useGetGerenciadorIdMenu(idMenu)
  const perguntas = useGetPerguntas(idDepartamento)
  const respostas = useGetRespostas(idDepartamento)
  const processamento = useGetProcessamentos()

  const [size, setSize] = React.useState('md')
  const [editMenu, setEditMenu] = useState(false)
  const [editProcess, setEditProcess] = useState(false)
  const [submitForm, setSubmitForm] = useState({
    idMenu,
    idDepartamento,
    config_resposta: {
      idResp: detailsForMenu?.data?.config_resposta.idResp,
      privado: detailsForMenu?.data?.config_resposta.privado,
      idProcessamento: detailsForMenu?.data?.config_resposta.idProcessamento
    },
    config_pergunta: {
      idPerg: detailsForMenu?.data?.config_pergunta.idPerg,
      ferramenta: detailsForMenu?.data?.config_pergunta.ferramenta,
      tag: detailsForMenu?.data?.config_pergunta.tag,
      idTags: detailsForMenu?.data?.config_pergunta.idTags
    }
  })

  const handleSizeClick = (newSize) => {
    setSize(newSize)
    onOpen()
  }

  const submitData = () => {
    setSubmitForm({
      idMenu,
      idDepartamento,
      config_resposta: {
        idResp: '',
        privado: '',
        idProcessamento: ''
      },
      config_pergunta: {
        idPerg: '',
        ferramenta: '',
        tag: '',
        idTags: ''
      }
    })
    setTimeout(() => {
      console.log(submitForm)
    }, 3000)
  }

  const handlePerguntaChange = (event) => {
    const selectedPerguntaValue = event.target.value

    setSubmitForm((prevForm) => ({
      ...prevForm,
      config_pergunta: {
        ...prevForm.config_pergunta,
        idPerg: selectedPerguntaValue
      }
    }))
  }

  const handleRespostaChange = (event) => {
    const selectedRespostaValue = event.target.value

    setSubmitForm((prevForm) => ({
      ...prevForm,
      config_resposta: {
        ...prevForm.config_resposta,
        idResp: selectedRespostaValue
      }
    }))
  }

  const handlePrivadoChange = (event) => {
    const isPrivadoChecked = event.target.checked

    setSubmitForm((prevForm) => ({
      ...prevForm,
      config_resposta: {
        ...prevForm.config_resposta,
        privado: isPrivadoChecked
      }
    }))
  }

  const handleProcessamentoChange = (event) => {
    const selectedProcessamentoValue = event.target.value

    setSubmitForm((prevForm) => ({
      ...prevForm,
      config_resposta: {
        ...prevForm.config_resposta,
        idProcessamento: selectedProcessamentoValue
      }
    }))
  }

  const handleFerramentaChange = (event) => {
    const selectedFerramentaValue = event.target.value

    setSubmitForm((prevForm) => ({
      ...prevForm,
      config_pergunta: {
        ...prevForm.config_pergunta,
        ferramenta: selectedFerramentaValue
      }
    }))
  }

  const handleMenuChange = (event) => {
    const selectedMenuValue = event.target.value

    setSubmitForm((prevForm) => ({
      ...prevForm,
      config_resposta: {
        ...prevForm.config_resposta,
        idResp: selectedMenuValue
      }
    }))
  }

  const sizes = ['full']

  return (
    <>
      {sizes.map((size) => (
        <Button
          onClick={() => handleSizeClick(size)}
          key={size}
          _hover={{ bg: '#229544', color: 'white' }}
          pr={-1}
          pl={-0.5}
        >
          <FaRegEdit size={22} />
        </Button>
      ))}

      <Modal onClose={onClose} size={size} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Menu selecionado: {menu}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Box mt={4}>
              <Heading maxW={'650px'} size={'md'} textTransform={'uppercase'}>
                Configurações de Menu:
                <Divider />
              </Heading>
              <Flex alignItems={'center'} gap={4}>
                <Box
                  id='a'
                  display={'flex'}
                  flexDir={'column'}
                  alignItems={'left'}
                  my={2}
                  boxShadow={'md'}
                  rounded={'lg'}
                  h={'max-content'}
                  maxW={'650px'}
                  p={4}
                >
                  <strong>Menu: </strong>
                  <Select
                    color={'black'}
                    disabled={!editMenu}
                    variant={'flushed'}
                    onChange={handleMenuChange}
                    value={submitForm.config_resposta.idResp || ''}
                  >
                    <option value=''>{detailsForMenu?.data?.informacoes_chat.menu}</option>
                  </Select>
                </Box>
                <Flex textAlign={'left'} maxW={'650px'} flexDir={'column'}>
                  <Box
                    id='b'
                    display={'flex'}
                    flexDir={'column'}
                    alignItems={'left'}
                    my={2}
                    boxShadow={'md'}
                    rounded={'lg'}
                    h={'max-content'}
                    maxW={'650px'}
                    p={4}
                  >
                    <strong>Pergunta: </strong>
                    <Select
                      color={'black'}
                      disabled={!editMenu}
                      variant={'flushed'}
                      onChange={handlePerguntaChange}
                      value={submitForm.config_pergunta.idPerg || ''}
                    >
                      <option value='' selected hidden>
                        {detailsForMenu?.data?.informacoes_chat.pergunta}
                      </option>
                      {perguntas.data &&
                        perguntas.data.scriptsPg.map((items, index) => (
                          <option key={index} value={items.id}>
                            {items.pergunta}
                          </option>
                        ))}
                    </Select>
                  </Box>

                  <Box
                    id='c'
                    display={'flex'}
                    flexDir={'column'}
                    alignItems={'left'}
                    my={2}
                    boxShadow={'md'}
                    rounded={'lg'}
                    h={'max-content'}
                    maxW={'650px'}
                    p={4}
                  >
                    <strong>Resposta: </strong>
                    <Select
                      disabled={!editMenu}
                      variant={'flushed'}
                      onChange={handleRespostaChange}
                      value={submitForm.config_pergunta.idResp || ''}
                    >
                      <option value selected hidden>
                        {detailsForMenu?.data?.informacoes_chat.resposta}
                      </option>
                      {respostas.data &&
                        respostas.data.scriptsResp.map((items, index) => (
                          <option key={index} value={items.id}>
                            {items.resposta}
                          </option>
                        ))}
                    </Select>
                  </Box>
                </Flex>
                <Box id='d' my={2} boxShadow={'md'} rounded={'lg'} maxW={'650px'} p={4}>
                  <strong>Usuário Privado: </strong>
                  <Switch
                    disabled={!editMenu}
                    colorScheme={'green'}
                    defaultChecked={detailsForMenu?.data?.informacoes_chat.usuarioPrivado}
                    onChange={handlePrivadoChange}
                  />
                </Box>
                <Button id='e' colorScheme={!editMenu ? 'red' : 'green'} onClick={() => setEditMenu(!editMenu)}>
                  {editMenu ? 'Salvar Edição' : 'Editar Menu'}
                </Button>
              </Flex>
            </Box>

            <Divider mt={20} />

            <Box mt={20}>
              <Heading maxW={'650px'} size={'md'} textTransform={'uppercase'}>
                Configurações de Processamento:
                <Divider />
              </Heading>
              <Flex alignItems={'center'} gap={4}>
                <Box my={2} boxShadow={'md'} rounded={'lg'} h={'max-content'} maxW={'650px'} p={4}>
                  <strong>Processamento: </strong>
                  <Select
                    disabled={!editProcess}
                    variant={'flushed'}
                    onChange={handleProcessamentoChange}
                    value={submitForm.config_resposta.idProcessamento || ''}
                  >
                    <option value=''>{detailsForMenu?.data?.informacoes_chat.processamentos.tipoDeAcao}</option>
                    {processamento.data &&
                      processamento.data.processamentos.map((items, index) => (
                        <option key={index} value={items.id}>
                          {items.tipoDeAcao}
                        </option>
                      ))}
                  </Select>
                </Box>

                <Flex textAlign={'left'} maxW={'650px'} flexDir={'column'}>
                  <Box
                    display={'flex'}
                    flexDir={'column'}
                    alignItems={'left'}
                    my={2}
                    boxShadow={'md'}
                    rounded={'lg'}
                    h={'max-content'}
                    maxW={'650px'}
                    p={4}
                  >
                    <strong>Nome da Ferramenta: </strong>
                    <Select
                      disabled={!editProcess}
                      variant={'flushed'}
                      onChange={handleFerramentaChange}
                      value={submitForm.config_pergunta.ferramenta || ''}
                    >
                      <option hidden selected value={detailsForMenu?.data?.config_pergunta.ferramenta}>
                        {detailsForMenu?.data?.informacoes_chat.processamentos.nome_ferramenta}
                      </option>
                      <option value='2'>Botões</option>
                      <option value='1'>Caixa de Texto</option>
                    </Select>
                  </Box>
                </Flex>

                <Button colorScheme={!editProcess ? 'red' : 'green'} onClick={() => setEditProcess(!editProcess)}>
                  {editProcess ? 'Salvar Processo' : 'Editar Processo'}
                </Button>
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button
              onClick={() => {
                onClose()
                setEditMenu(false)
              }}
            >
              Fechar
            </Button>

            <Button colorScheme='green' onClick={() => submitData()}>
              Salvar Alterações
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

DetailsGerenciador.propTypes = {
  idMenu: PropTypes.string,
  menu: PropTypes.string,
  idDepartamento: PropTypes.string
}
