import React from 'react'

import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Box, SkeletonText } from '@chakra-ui/react'

import { useGetReportUsuario } from '../hooks/useGetReportUsuario'
export default function TabelaPorUsuario () {
  const { isLoading, data } = useGetReportUsuario()
  const listaMenu = data || []

  return (
    <>
      {!isLoading
        ? (
        <TableContainer h={'250px'} overflowY={'scroll'} maxHeight={'400px'}>
          <Table variant='striped' colorScheme='green' size={'sm'}>
            <Thead >
              <Tr >
                <Th textAlign='left'>Nomes</Th>
                <Th textAlign='right'>Opção</Th>
                <Th textAlign='right'>Quantidade</Th>
              </Tr>
            </Thead>
            <Tbody fontWeight={'bold'}>
              {listaMenu.map((item, index) => (
                <Tr key={index}>
                  <Td textAlign='left'>{item.nome}</Td>
                  <Td textAlign='right'>{item.opcao}</Td>
                  <Td textAlign='right'>{item.qtde}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
          )
        : (
        <>
          <Box padding='6' boxShadow='lg' bg='white'>
            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
          </Box>
        </>
          )}
    </>
  )
}
