/* eslint-disable react/prop-types */
import React from 'react'

import Agenda from './components/agenda'

export default function AgendaCmpromissos () {
  const getIdAcessoFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)

    return {
      campanhaId: urlParams.get('campanhaId'),
      baseId: urlParams.get('baseId')

    }
  }

  const { campanhaId, baseId } = getIdAcessoFromUrl()

  return (
      <Agenda campanhaId={campanhaId} baseId={baseId} />
  )
}
