import { useQuery } from 'react-query'
import apiAdmin from '../../../../services/apiAdmin'

const useObtendoTabulacoes = (dataInicial, dataHoje) => {
  return useQuery(
    'use_ObtendoTabulacoes',
    async () => {
      const response = await apiAdmin.get(`/v1/api/admin/dashboardPainelDeAtendimento/stats-quantidade-tabulacoes-trilha?dataInicial=${dataInicial ?? new Date().toISOString().split('T')[0]}&dataFinal=${dataHoje ?? new Date().toISOString().split('T')[0]}`)

      return response.data
    },
    {
      refetchOnWindowFocus: false,
      staleTime: false,
      refetchInterval: false
    }
  )
}

export { useObtendoTabulacoes }
