import { useQuery } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useGetGerenciadorIdMenu = (payload) => {
  return useQuery(
    ['chat_useGetGerenciadorIdMenu', { payload }],
    async () => {
      const response = await apiAdmin.get(`/v1/api/admin/configuracoes-chat/obtendo-menus-chat-por-id?idMenu=${payload}`)
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: false,
      refetchInterval: false,
      enabled: !!payload
    }
  )
}

export { useGetGerenciadorIdMenu }
