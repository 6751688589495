import { useMutation } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useRequestUploudArquivo = () => {
  const uploadArquivo = async (formData) => {
    await apiAdmin.post('/v1/api/admin/campanhas/upload', formData)
  }

  const mutation = useMutation(uploadArquivo)

  const enviarArquivo = async (formData) => {
    await mutation.mutateAsync(formData)
  }

  return {
    enviarArquivo,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export default useRequestUploudArquivo
