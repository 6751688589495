import { useMutation } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useAdicionarNovaEtapa = () => {
  const criaEtapa = async (novaEtapa) => {
    const response = await apiAdmin.post('/v1/api/admin/chatbot-whatsapp/cadastrar-trilhas', novaEtapa, {
      headers: { 'x-access-token': localStorage.getItem('token') }
    })
    return response.data
  }

  const mutation = useMutation(criaEtapa)

  const UseRequestAdicionarEtapa = (novaEtapa) => {
    mutation.mutate(novaEtapa)
  }
  return {
    UseRequestAdicionarEtapa,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export {
  useAdicionarNovaEtapa
}
