import { useMutation } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useCreateNovoMenu = () => {
  const novoMenu = async (payload) => {
    const response = await apiAdmin.post('/v1/api/admin/menu/cadastrar', payload)

    if (response.status === 200) {
      mutation.isSuccess = true
      mutation.isError = false
    }

    if (response.status !== 200) {
      mutation.isSuccess = false
      mutation.isError = true
    }
  }

  const mutation = useMutation(novoMenu)

  const UseRequestCreateNovoMenu = (payload) => {
    mutation.mutate(payload)
  }
  return {
    UseRequestCreateNovoMenu,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export { useCreateNovoMenu }
