import { useQuery } from 'react-query'

import apiAdmin from '../../../../services/apiAdmin'

const useObtendoMensagensAtendimentos = (idProtocolo) => {
  return useQuery(
    'atendimento_useObtendoMensagensAtendimentos',
    async () => {
      const response = await apiAdmin.get(`/v1/api/admin/atendimento/obtendo-historico-atendimento/${idProtocolo}`)

      if (response.status === 200) {
        return response?.data
      }
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 2500,
      refetchInterval: false,
      enabled: !!idProtocolo
    }
  )
}

export { useObtendoMensagensAtendimentos }
