/* eslint-disable react/prop-types */
import React from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Text,
  SimpleGrid,
  Box
} from '@chakra-ui/react'
import formatDataHoraChat from '../../../../helpers/dataHora/formatDataHoraChat'

const DrawerInfos = ({ isOpen, onClose, eventsData, dateStr }) => {
  return (
    <Drawer size={'md'} isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Detalhes dos Eventos {formatDataHoraChat(dateStr).slice(0, 11)}</DrawerHeader>
        <DrawerBody>
          {eventsData && eventsData.length > 0
            ? (
            <SimpleGrid columns={2} spacing={4}>
              {eventsData.map((event, index) => (
                <Box _hover={{ bg: '#229544', color: 'white', cursor: 'pointer' }} boxShadow={'lg'} p={4} rounded={'xl'} key={index} style={{ marginBottom: '1rem' }}>
                  <Text fontWeight={'semibold'}><strong>Título:</strong> {event.title}</Text>
                  <Text fontWeight={'semibold'}><strong>Início:</strong> {new Date(event.start).toLocaleTimeString()}</Text>
                  <Text fontWeight={'semibold'}><strong>Fim:</strong> {new Date(event.end).toLocaleTimeString()}</Text>
                  <Text fontWeight={'semibold'}><strong>Status:</strong> {event.status}</Text>
                </Box>
              ))}
            </SimpleGrid>
              )
            : (
            <p>Nenhum detalhe disponível.</p>
              )}
        </DrawerBody>
        <DrawerFooter>
          <Button colorScheme='red' bg={'red'} mr={3} onClick={onClose}>
            Fechar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default DrawerInfos
