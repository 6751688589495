import { useQuery } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useGetRespostas = (payload) => {
  return useQuery(
    ['chat_useGetRespostas', { payload }],
    async () => {
      const response = await apiAdmin.get(`/v1/api/admin/scriptRespostas/obtendo?idDepartamento=${payload}`)
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: false,
      refetchInterval: false
    }
  )
}

export { useGetRespostas }
