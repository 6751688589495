import { useMutation, useQueryClient } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useMostrarEsconderTrilha = () => {
  const queryClient = useQueryClient()
  const alterarVisibilidadeTrilha = async (payload) => {
    const response = await apiAdmin.put('/v1/api/admin/gerenciamento/chatbot/visualizarEsconderTrilha', payload)

    if (response.status === 200) {
      queryClient.invalidateQueries('trilha_useAtivarInativar')
      mutation.isSuccess = true
      mutation.isError = false
    }

    if (response.status !== 200) {
      mutation.isSuccess = false
      mutation.isError = true
    }
  }

  const mutation = useMutation(alterarVisibilidadeTrilha)

  const UseRequestAlterarVisibilidadeTrilha = (payload) => {
    mutation.mutate(payload)
  }
  return {
    UseRequestAlterarVisibilidadeTrilha,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export { useMostrarEsconderTrilha }
