import { useQuery } from 'react-query'

import { executar } from '../../../features/chatbotWha/propostaSlice'
import store from '../../../features/index'
import api from '../../../services/api'
const { dispatch } = store
const useGetDadosDaProposta = ({ token }) => {
  return useQuery(
    ['useGetDadosDaProposta'],
    async () => {
      const response = await api.get(`/v1/api/public/obtendoDadosdaProposta?token=${token}`)
      const { data } = response
      dispatch(executar({ propostas: data }))
      return data
    },
    {
      staleTime: 5000 * 60 * 60 * 24,
      enabled: !!token
    }
  )
}

export { useGetDadosDaProposta }
