import React from 'react'

import {
  Card,
  CardHeader,
  Heading,
  Grid,
  AccordionPanel,
  AccordionButton,
  AccordionItem,
  Accordion,
  Box
} from '@chakra-ui/react'
import { useGetProcessamentos } from '../hooks/Processamentos/useGetProcessamentos'
import { DrawerEditar } from './DrawerEditar'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'

export const TabelaProcessos = () => {
  const { data } = useGetProcessamentos()
  const list = data?.processamentos || []

  return (
    <>
      <Accordion w={'100%'} allowToggle>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <Heading fontSize={16}>
                      MessageUrl | {Number(list.filter(processo => processo.processamento === 'messageUrl').length)} Processos
                    </Heading>
                  </Box>
                  {isExpanded
                    ? (
                      <MinusIcon fontSize='12px' />
                      )
                    : (
                      <AddIcon fontSize='12px' />
                      )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Grid
                  templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }}
                  maxH={'40vh'}
                  gap={4}
                  overflowX="scroll"
                >
                  {list.filter(processo => processo.processamento === 'messageUrl').map((processo, index) => (
                    <Card boxShadow={'xl'} key={index}>
                      <CardHeader display={'Flex'} alignContent={'center'} justifyContent={'space-between'}>
                        <Heading w={'90%'} size='xs' textTransform='uppercase'>{processo.tipoDeAcao ?? ' - '}</Heading>
                        <DrawerEditar processo={processo} />
                      </CardHeader>
                    </Card>
                  ))}
                </Grid>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <Heading fontSize={16}>
                      Select  | {Number(list.filter(processo => processo.processamento === 'Select').length)} Processos
                    </Heading>
                  </Box>
                  {isExpanded
                    ? (
                      <MinusIcon fontSize='12px' />
                      )
                    : (
                      <AddIcon fontSize='12px' />
                      )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Grid
                  templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }}
                  gap={4}
                  maxH={'40vh'}
                  overflowY="auto"
                >
                  {list.filter(processo => processo.processamento === 'Select').map((processo, index) => (
                    <Card boxShadow={'xl'} key={index}>
                      <CardHeader display={'Flex'} alignContent={'center'} justifyContent={'space-between'}>
                        <Heading size='xs' textTransform='uppercase'>{processo.tipoDeAcao ?? ' - '}</Heading>
                        <DrawerEditar processo={processo} />
                      </CardHeader>
                    </Card>
                  ))}
                </Grid>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <Heading fontSize={16}>
                      SelectPage  | {Number(list.filter(processo => processo.processamento === 'SelectPage').length)} Processos
                    </Heading>
                  </Box>
                  {isExpanded
                    ? (
                      <MinusIcon fontSize='12px' />
                      )
                    : (
                      <AddIcon fontSize='12px' />
                      )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Grid
                  templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }}
                  gap={4}
                  maxH={'40vh'}
                  overflowY="auto"
                >
                  {list.filter(processo => processo.processamento === 'SelectPage').map((processo, index) => (
                    <Card boxShadow={'xl'} key={index}>
                      <CardHeader display={'Flex'} alignContent={'center'} justifyContent={'space-between'}>
                        <Heading size='xs' textTransform='uppercase'>{processo.tipoDeAcao ?? ' - '}</Heading>
                        <DrawerEditar processo={processo} />
                      </CardHeader>
                    </Card>
                  ))}
                </Grid>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <Heading fontSize={16}>
                      FormWeb  | {Number(list.filter(processo => processo.processamento === 'FormWeb').length)} Processos
                    </Heading>
                  </Box>
                  {isExpanded
                    ? (
                      <MinusIcon fontSize='12px' />
                      )
                    : (
                      <AddIcon fontSize='12px' />
                      )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Grid
                  templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }}
                  gap={4}
                  maxH={'40vh'}
                  overflowY="auto"
                >
                  {list.filter(processo => processo.processamento === 'FormWeb').map((processo, index) => (
                    <Card boxShadow={'xl'} key={index}>
                      <CardHeader display={'Flex'} alignContent={'center'} justifyContent={'space-between'}>
                        <Heading size='xs' textTransform='uppercase'>{processo.tipoDeAcao ?? ' - '}</Heading>
                        <DrawerEditar processo={processo} />
                      </CardHeader>
                    </Card>
                  ))}
                </Grid>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    <Heading fontSize={16}>
                      Message  | {Number(list.filter(processo => processo.processamento === 'message').length)} Processos
                    </Heading>
                  </Box>
                  {isExpanded
                    ? (
                      <MinusIcon fontSize='12px' />
                      )
                    : (
                      <AddIcon fontSize='12px' />
                      )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Grid
                  templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }}
                  gap={4}
                  maxH={'40vh'}
                  overflowY="auto"
                >
                  {list.filter(processo => processo.processamento === 'message').map((processo, index) => (
                    <Card boxShadow={'xl'} key={index}>
                      <CardHeader display={'Flex'} alignContent={'center'} justifyContent={'space-between'}>
                        <Heading size='xs' textTransform='uppercase'>{processo.tipoDeAcao ?? ' - '}</Heading>
                        <DrawerEditar processo={processo} />
                      </CardHeader>
                    </Card>
                  ))}
                </Grid>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>

    </>
  )
}
