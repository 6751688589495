import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import React from 'react'

import {
  Box,
  Flex
} from '@chakra-ui/react'

import CardSeguranca from './components/CardSeguranca'
import DadosProposta from './components/DadosProposta'
import Formulario from './components/Formulario'
import SkeletonFormulario from './components/SkeletonFormulario'
import { useGetValidaToken } from './hooks/useGetValidaToken'

function Autenticacao () {
  const { abrirDadosProposta } = useSelector((state) => state.proposta)

  const url = window.location.search
  const searchParams = new URLSearchParams(url)
  let tk = searchParams.get('tk')
  tk = decodeURIComponent(tk)
  const { isLoading, data } = useGetValidaToken({ token: tk })

  return (
    <>
      {!tk && <Navigate to='/404'/> }
      <Box overflowX={'hidden'} height={'100vh'} display='flex' flexDirection='column' bg={'white'}>
        <Flex
          flex='1'
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'flex-start', md: 'center' }}
          justify='center'
          overflow='hidden'
        >
          {abrirDadosProposta
            ? (
            <DadosProposta token={tk}/>
              )
            : (
            <>
              {isLoading
                ? (
                <SkeletonFormulario/>
                  )
                : (
                    data?.linkValido && <Formulario token={tk}/>
                  )}
              <CardSeguranca/>
            </>
              )}
        </Flex>
      </Box>
    </>
  )
}

export default Autenticacao
