import { useQuery } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'
const useObetendoTabulacao = () => {
  return useQuery(
    'atendimento_obtendo-tabulacoes-atendimento',
    async () => {
      const response = await apiAdmin.get('v1/api/admin/atendimento/obtendo-tabulacoes-atendimento')
      return response.data
    }
  )
}

export { useObetendoTabulacao }
