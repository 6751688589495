import React from 'react'
import { useGetInstancias } from './hooks/useGetInstancias'
import { MdContentCopy } from 'react-icons/md'
import { Badge, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import Acoes from './Acoes'

export default function BuscarTodasAsInstancias () {
  const { data } = useGetInstancias()
  const toast = useToast()

  const copyToClipboard = (text, title) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title,
        description: 'Copiado com sucesso!',
        status: 'success',
        duration: 9000,
        isClosable: true
      })
    }).catch(_err => {
      toast({
        title,
        description: 'Falha ao copiar, tente novamente!',
        status: 'success',
        duration: 9000,
        isClosable: true
      })
    })
  }

  return (
    <Flex flexDir={'column'} p={4}>
      <TableContainer p={4} bg={'white'} rounded={'xl'} boxShadow={'xl'}>
        <Table size='sm'>
          <Thead>
            <Tr>
              <Th>instance/hook</Th>
              <Th textAlign={'center'}>status</Th>
              <Th textAlign={'center'}>enabled</Th>
              <Th textAlign={'center'}>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data && data.response.map((instancias, index) => (
              <Tr key={index}>
                <Td fontWeight={'bold'}>
                  <Flex cursor={'pointer'} onClick={() => copyToClipboard(instancias.instance, 'INSTACIA')} gap={2}>
                    <Text fontWeight={'bold'}>{instancias.instance} </Text>
                    <MdContentCopy />
                  </Flex>
                  <Flex cursor={'pointer'} onClick={() => copyToClipboard(instancias.hooks, 'HOOKS')} gap={2}>
                    <Text fontWeight={'bold'}>{instancias.hooks} </Text>
                    <MdContentCopy />
                  </Flex>
                </Td>
                <Td textAlign={'center'}>
                  <Badge colorScheme={instancias.status ? 'green' : 'red'}>
                    {instancias.status ? instancias.status : 'DISCONNECTED'}
                  </Badge>
                </Td>
                <Td textAlign={'center'}>
                  <Badge colorScheme={instancias.enabled ? 'green' : 'red'}>
                    {instancias.enabled ? 'ON' : 'OFF'}
                  </Badge>
                </Td>
                <Td fontWeight={'bold'} textAlign={'center'}>
                  <Acoes instancia={instancias.instance} status={instancias.status} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}
