import { useQuery } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'
const useObetendoAgentes = () => {
  return useQuery(
    'atendimento_useObtendoAgentes',
    async () => {
      const response = await apiAdmin.get('v1/api/admin/atendimento/obtendo/agentes/')
      return response.data
    }
  )
}

export { useObetendoAgentes }
