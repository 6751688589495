import React from 'react'

import { Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorMode } from '@chakra-ui/react'

import { useObtendoQuantidadeTrilhasMes } from '../hooks/useObtendoQuantidadeTrilhasMes'

export default function TableTrilhaMes () {
  const { colorMode } = useColorMode()
  const { data } = useObtendoQuantidadeTrilhasMes()
  const dadosQuantidadeTrilhaMes = data
  return (
    <>
      <Heading mt={8} fontSize={18} ml={4}>Quantidade de Trilhas Selecionadas Mensalmente</Heading>
      <Text fontSize={12} fontWeight={'semibold'} color={colorMode === 'light' ? 'black' : 'gray.200'} mb={2} ml={4} >Acompanhe a quantidade de seleção das trilhas atuais</Text>
      <TableContainer rounded={'2xl'} bg={colorMode === 'light' ? 'white' : 'gray.600'} padding={'10px 2px'} h={'150px'} overflowY={'scroll'} maxHeight={'400px'}>
        <Table variant='striped' colorScheme='green' size='sm'>
          <Thead>
            <Tr>
              <Th>trilhas Selecionadas</Th>
              <Th isNumeric>Quantidade de Seleção</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dadosQuantidadeTrilhaMes
              ? (
                  dadosQuantidadeTrilhaMes.map((dados) => (
                <Tr fontWeight={'bold'} key={dados.qtde}>
                  <Td>{dados.tags}</Td>
                  <Td paddingInlineEnd={'10'} isNumeric>{dados.qtde}</Td>
                </Tr>

                  ))
                )
              : (
              <Tr fontWeight={'bold'} >
                <Td>Nenhuma Trilha Selecionada</Td>
                <Td paddingInlineEnd={'10'} isNumeric>0</Td>
              </Tr>
                )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}
