/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Tooltip,
  Badge,
  Text,
  Flex
} from '@chakra-ui/react'
import { MdRestartAlt, MdSettings } from 'react-icons/md'
import { VscDebugStart } from 'react-icons/vsc'
import { IoClose, IoQrCode } from 'react-icons/io5'
import ScannerQRCode from './ScannerQRCode'
import { useStartInstancia } from './hooks/useStartInstancia'
import { useCloseInstancia } from './hooks/useCloseInstancia'
import { useResetInstancia } from './hooks/useResetInstancia'
import TesteConexaoInstancia from './TesteConexaoInstancia'

export default function Acoes ({ instancia, status }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { UseRequestStartInstancia } = useStartInstancia()
  const { UseRequestCloseInstancia } = useCloseInstancia()
  const { UseRequestResetInstancia } = useResetInstancia()

  return (
    <>
      <Tooltip hasArrow label="Gerenciar Instância" placement='left-start'>
        <Button onClick={onOpen}><MdSettings /></Button>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={4} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Text>{instancia}</Text>
            <Badge colorScheme={status ? 'green' : 'red'}>
              {status ? 'Online' : 'Offline'}
            </Badge>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex alignItems={'center'} justifyContent={'flex-start'} gap={2}>
              <VscDebugStart size={22} />
              <Text>Você pode iniciar a instância</Text>
            </Flex>
            <Flex alignItems={'center'} justifyContent={'flex-start'} gap={2}>
              <IoClose size={22} />
              <Text>Você pode parar a instância</Text>
            </Flex>
            <Flex alignItems={'center'} justifyContent={'flex-start'} gap={2}>
              <MdRestartAlt size={22} />
              <Text>Você pode reiniciar a instância</Text>
            </Flex>
            <Flex alignItems={'center'} justifyContent={'flex-start'} gap={2} ml={1}>
              <IoQrCode size={18} />
              <Text>Você pode escanear o QR-CODE da instância</Text>
            </Flex>
          </ModalBody>

          <ModalFooter gap={4}>
            <Tooltip hasArrow label="Encerrar Instância" bg='red' placement='top'>
              <Button cursor={status ? 'pointer' : 'not-allowed'} colorScheme='red' onClick={() => { UseRequestCloseInstancia({ instance: instancia }) }} ><IoClose /></Button>
            </Tooltip>
            <Tooltip hasArrow label="Reiniciar Instância" bg='orange' placement='top'>
              <Button cursor={status ? 'pointer' : 'not-allowed'} colorScheme='orange' onClick={() => { UseRequestResetInstancia({ instance: instancia }) }} ><MdRestartAlt /></Button>
            </Tooltip>
            <Tooltip hasArrow label="Iniciar Instância" bg='green' placement='top'>
              <Button colorScheme='green' onClick={() => { UseRequestStartInstancia({ instance: instancia }) }}>
                <VscDebugStart />
              </Button>
            </Tooltip>
            <ScannerQRCode instancia={instancia} />
            <TesteConexaoInstancia instancia={instancia} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
