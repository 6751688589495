import { useQuery } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useGetFiltrosArquivosCampanhas = () => {
  return useQuery(
    ['useGetFIltrosArquivos_campanhas_filtroCampanhas'],
    async () => {
      const response = await apiAdmin.get('/v1/api/admin/campanhas/obter-campanhas')
      return response.data
    }
  )
}

export { useGetFiltrosArquivosCampanhas }
