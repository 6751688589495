/* eslint-disable react/prop-types */
import React, { useState, memo } from 'react'
import { BsPersonAdd } from 'react-icons/bs'
import { FcCallTransfer } from 'react-icons/fc'

import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
  Box, Button, Checkbox, Icon, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure
} from '@chakra-ui/react'

import { useObetendoAgentes } from '../hooks/useObetendoAgentes'
import { useTransferirAtendimento } from '../hooks/useTransferirAtendimento'

function TransferirAtendimento ({ idProtocolo }) {
  const { UseRequestAlterarAtendimento } = useTransferirAtendimento()
  const { data } = useObetendoAgentes()
  const agentes = data || []

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedAgenteId, setSelectedAgenteId] = useState(null)
  return (
    <>
      <Tooltip hasArrow label='Transferir Atendimento' placement='top-start'>
        <Button _hover={{
          boxShadow: 'lg',
          bg: 'blue.100'
        }} onClick={onOpen}>
          <Icon fontSize={20} as={FcCallTransfer} />
        </Button>
      </Tooltip >

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transferir Parceiro de Atendente</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex='1' textAlign='left'>
                      Selecionar um agente para transferir
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel h={'35vh'} pb={4} overflow={'scroll'}>
                  <List spacing={3}>
                    {agentes?.map((dados) => (
                      <Box key={dados.idAcesso}>
                        <ListItem mb={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                          <ListIcon as={BsPersonAdd} color='green.500' />
                          <Text fontSize={'12px'}>{dados.nome}</Text>
                          <Checkbox
                            onChange={() => { dados.idAcesso === selectedAgenteId ? setSelectedAgenteId(null) : setSelectedAgenteId(dados.idAcesso) }}
                            value={dados.idAcesso}
                            size='sm'
                            colorScheme='red'
                            isChecked={dados.idAcesso === selectedAgenteId}
                            isDisabled={selectedAgenteId !== null && dados.idAcesso !== selectedAgenteId}
                          >
                          </Checkbox>
                        </ListItem>
                        <hr />
                      </Box>
                    ))}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button display={!selectedAgenteId ? 'none' : 'flex'} onClick={() => {
              UseRequestAlterarAtendimento(idProtocolo, selectedAgenteId)
              onClose()
            }} colorScheme='green'>Transferir atendimento</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default memo(TransferirAtendimento)
