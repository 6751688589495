import { useQuery } from 'react-query'

import { obtendoStatusAtendimento } from '../../../../../features/atendimento/atendimentoSlice'
import store from '../../../../../features/index'
import apiAdmin from '../../../../../services/apiAdmin'
const useObtendoAtendimentos = () => {
  const { dispatch } = store
  return useQuery(
    'atendimento_useObtendoAtendimentos',
    async () => {
      const response = await apiAdmin.get('/v1/api/admin/atendimento/obtendo/atendimentos')

      if (response.status === 200) {
        dispatch(obtendoStatusAtendimento({ listaDeAtendimentos: response.data?.atendimentos }))
      }
      return response.data?.atendimentos
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000,
      refetchInterval: 5000
    }
  )
}

export { useObtendoAtendimentos }
