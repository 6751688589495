import React, { useState } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  NumberInput,
  NumberInputField,
  VStack,
  HStack,
  IconButton,
  Box
} from '@chakra-ui/react'
import { MdOutlineWork } from 'react-icons/md'
import { BiTrash } from 'react-icons/bi'

export default function CriadorCampanhaComponent () {
  const [informacoesVagas, setInformacoesVagas] = useState(true)
  const [agenda, setAgenda] = useState(false)
  const [detalhes, setDetalhes] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef(null)

  const [formData, setFormData] = useState({
    id: 0,
    vaga: '',
    cep: '',
    logradouro: '',
    cidade: '',
    estado: '',
    recrutadora: '',
    valor: '',
    descricao: '',
    requisitos: [''],
    desejaveis: [''],
    beneficios: [''],
    agenda: {
      disponiveis: '',
      ocupados: '',
      limite: '',
      datas: [{ dia: '', hora: '' }]
    }
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleArrayChange = (e, index, arrayName) => {
    const newArray = [...formData[arrayName]]
    newArray[index] = e.target.value
    setFormData({ ...formData, [arrayName]: newArray })
  }

  const addArrayField = (arrayName) => {
    setFormData({
      ...formData,
      [arrayName]: [...formData[arrayName], '']
    })
  }

  const removeArrayField = (index, arrayName) => {
    const newArray = formData[arrayName].filter((_, i) => i !== index)
    setFormData({
      ...formData,
      [arrayName]: newArray
    })
  }

  const handleAgendaChange = (e, index, field) => {
    const newDates = [...formData.agenda.datas]
    newDates[index][field] = e.target.value
    setFormData({
      ...formData,
      agenda: { ...formData.agenda, datas: newDates }
    })
  }

  const addDateField = () => {
    setFormData({
      ...formData,
      agenda: { ...formData.agenda, datas: [...formData.agenda.datas, { dia: '', hora: '' }] }
    })
  }

  const removeDateField = (index) => {
    const newDates = formData.agenda.datas.filter((_, i) => i !== index)
    setFormData({
      ...formData,
      agenda: { ...formData.agenda, datas: newDates }
    })
  }

  const generateMarkdown = () => {
    return `
      <p><b>Recrutadora:</b> ${formData.recrutadora}</p>
      <p><b>Endereço:</b> ${formData.endereco}</p>
      <br>
      <p><b>Vaga para ${formData.vaga}</b></p>
      <br>
      <p><b>Descrição:</b></p>
      <p>${formData.descricao}</p>
      <ul>
        ${formData.requisitos.map(req => `<li>${req}</li>`).join('')}
      </ul>
      <br>
      <b>Requisitos</b>
      <ul>
        ${formData.requisitos.map(req => `<li>${req}</li>`).join('')}
      </ul>
      <br>
      <b>Desejável</b>
      <ul>
        ${formData.desejaveis.map(des => `<li>${des}</li>`).join('')}
      </ul>
      <br>
      <b>Benefícios</b>
      <ul>
        ${formData.beneficios.map(ben => `<li>${ben}</li>`).join('')}
      </ul>
      <br>
    `
  }

  const handleSubmit = () => {
    const { descricao, requisitos, desejaveis, beneficios, ...filteredFormData } = formData
    const markdownDetalhes = generateMarkdown()
    const finalFormData = { ...filteredFormData, detalhes: markdownDetalhes }
    console.log(finalFormData)
    setInformacoesVagas(true)
    setAgenda(false)
    setDetalhes(false)
    onClose()
  }

  return (
    <>
      <Flex
        _hover={{ bg: '#229544', color: 'white' }}
        cursor={'pointer'}
        rounded={'xl'}
        w={'100%'}
        boxShadow={'md'}
        h={'max-content'}
        p={4}
        alignItems={'center'}
        justifyContent={'space-between'}
        ref={btnRef}
        colorScheme='teal'
        onClick={onOpen}
      >
        <Text textTransform={'uppercase'} fontWeight={'semibold'}>
          Criar novas campanhas
        </Text>
        <MdOutlineWork size={30} />
      </Flex>

      <Drawer
        size={'full'}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Crie sua nova campanha</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4} align='stretch'>

              {informacoesVagas &&
                <Box>
                  <Text fontSize="xl" fontWeight="bold">Informações da vaga</Text>
                  <FormControl>
                    <FormLabel>Vaga</FormLabel>
                    <Input name="vaga" value={formData.vaga} onChange={handleInputChange} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>CEP</FormLabel>
                    <Input name="cep" value={formData.cep} onChange={handleInputChange} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Logradouro</FormLabel>
                    <Input name="logradouro" value={formData.logradouro} onChange={handleInputChange} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Cidade</FormLabel>
                    <Input name="cidade" value={formData.cidade} onChange={handleInputChange} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Estado</FormLabel>
                    <Input name="estado" value={formData.estado} onChange={handleInputChange} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Valor</FormLabel>
                    <Input name="valor" value={formData.valor} onChange={handleInputChange} />
                  </FormControl>
                </Box>
              }

              {agenda &&
                <Box>
                  <Text fontSize="xl" fontWeight="bold">Agenda</Text>
                  <FormControl>
                    <FormLabel>Disponíveis</FormLabel>
                    <NumberInput value={formData.agenda.disponiveis} onChange={(value) => setFormData({ ...formData, agenda: { ...formData.agenda, disponiveis: value } })}>
                      <NumberInputField name="disponiveis" />
                    </NumberInput>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Ocupados</FormLabel>
                    <NumberInput value={formData.agenda.ocupados} onChange={(value) => setFormData({ ...formData, agenda: { ...formData.agenda, ocupados: value } })}>
                      <NumberInputField name="ocupados" />
                    </NumberInput>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Limite</FormLabel>
                    <NumberInput value={formData.agenda.limite} onChange={(value) => setFormData({ ...formData, agenda: { ...formData.agenda, limite: value } })}>
                      <NumberInputField name="limite" />
                    </NumberInput>
                  </FormControl>
                  {formData.agenda.datas.map((date, index) => (
                    <Flex gap={2} alignItems={'center'} justifyContent={'space-between'} key={index} spacing={2}>
                      <FormControl>
                        <FormLabel>Dia</FormLabel>
                        <Input name="dia" value={date.dia} onChange={(e) => handleAgendaChange(e, index, 'dia')} />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Hora</FormLabel>
                        <Input name="hora" value={date.hora} onChange={(e) => handleAgendaChange(e, index, 'hora')} />
                      </FormControl>
                      <IconButton
                        colorScheme='red'
                        mt={8}
                        aria-label="Remover Data"
                        icon={<BiTrash size={22} />}
                        onClick={() => removeDateField(index)}
                      />
                    </Flex>
                  ))}
                  <Button mt={2} w={'100%'} colorScheme='green' onClick={addDateField}>Adicionar Data</Button>
                </Box>
              }

              {detalhes &&
                <Box pb={20}>
                  <Text fontSize="xl" fontWeight="bold">Detalhes</Text>
                  <FormControl>
                    <FormLabel>Recrutadora</FormLabel>
                    <Input name="recrutadora" value={formData.recrutadora} onChange={handleInputChange} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Endereço</FormLabel>
                    <Input name="endereco" value={formData.endereco} onChange={handleInputChange} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Descrição</FormLabel>
                    <Textarea name="descricao" value={formData.descricao} onChange={handleInputChange} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Requisitos</FormLabel>
                    {formData.requisitos.map((req, index) => (
                      <HStack mt={1} key={index} spacing={2}>
                        <Input value={req} onChange={(e) => handleArrayChange(e, index, 'requisitos')} />
                        <IconButton
                          colorScheme='red'
                          aria-label="Remover Requisito"
                          icon={<BiTrash size={22} />}
                          onClick={() => removeArrayField(index, 'requisitos')}
                        />
                      </HStack>
                    ))}
                    <Button w={'100%'} colorScheme='green' my={4} onClick={() => addArrayField('requisitos')}>Adicionar Requisito</Button>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Desejáveis</FormLabel>
                    {formData.desejaveis.map((des, index) => (
                      <HStack mt={1} key={index} spacing={2}>
                        <Input value={des} onChange={(e) => handleArrayChange(e, index, 'desejaveis')} />
                        <IconButton
                          colorScheme='red'
                          aria-label="Remover Desejável"
                          icon={<BiTrash size={22} />}
                          onClick={() => removeArrayField(index, 'desejaveis')}
                        />
                      </HStack>
                    ))}
                    <Button w={'100%'} colorScheme='green' my={4} onClick={() => addArrayField('desejaveis')}>Adicionar Desejável</Button>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Benefícios</FormLabel>
                    {formData.beneficios.map((ben, index) => (
                      <HStack mt={1} key={index} spacing={2}>
                        <Input value={ben} onChange={(e) => handleArrayChange(e, index, 'beneficios')} />
                        <IconButton
                          colorScheme='red'
                          aria-label="Remover Benefício"
                          icon={<BiTrash size={22} />}
                          onClick={() => removeArrayField(index, 'beneficios')}
                        />
                      </HStack>
                    ))}
                    <Button w={'100%'} colorScheme='green' my={4} onClick={() => addArrayField('beneficios')}>Adicionar Benefício</Button>
                  </FormControl>
                </Box>
              }

            </VStack>
          </DrawerBody>

          <DrawerFooter>
            {informacoesVagas
              ? (
                <>
                  <Button colorScheme='green' onClick={() => {
                    setInformacoesVagas(false)
                    setAgenda(true)
                  }}>Próximo</Button>
                </>
                )
              : agenda
                ? (
                  <>
                    <Button mr={1} onClick={() => {
                      setInformacoesVagas(true)
                      setAgenda(false)
                      setDetalhes(false)
                    }}>Voltar</Button>
                    <Button colorScheme='green' onClick={() => {
                      setAgenda(false)
                      setDetalhes(true)
                    }}>Próximo</Button>
                  </>
                  )
                : detalhes
                  ? (
                    <>
                      <Button mr={1} onClick={() => {
                        setInformacoesVagas(false)
                        setDetalhes(false)
                        setAgenda(true)
                      }}>Voltar</Button>
                      <Button colorScheme='red' mr={1} onClick={() => {
                        setInformacoesVagas(true)
                        setAgenda(false)
                        setDetalhes(false)
                        onClose()
                      }}>
                        Cancelar criação
                      </Button>
                      <Button colorScheme='green' onClick={handleSubmit}>Criar nova campanha</Button>
                    </>
                    )
                  : null}
          </DrawerFooter>

        </DrawerContent>
      </Drawer>
    </>
  )
}
