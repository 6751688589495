import React from 'react'
import { Card, Select, Text } from '@chakra-ui/react'
import { FormByTipoProcessamento } from './FormProcessos'

export const getFormByTipoPorcessamento = (props, tipoProcessamento, setTipoProc) => {
  return (
    <>
      <Card maxW={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
        <Text fontWeight={600}>
          Tipo de Processo
        </Text>
        <Select name='tipoProcesso' value={tipoProcessamento} onChange={(event) => {
          setTipoProc(event.target.value)
        }}>

          {
            props.processamento
              ? <option hidden value={props?.processamento}>{props?.processamento}</option>
              : ''
          }
          <option value={'Select'}>Select</option>
          <option value={'messageUrl'}>messageUrl</option>
          <option value={'message'}>message</option>
          <option value={'SelectPage'}>SelectPage</option>
          <option value={'FormWeb'}>FormWeb</option>
        </Select>
      </Card>

      <FormByTipoProcessamento dadosProcesso={props} tipoProcesso={tipoProcessamento} />
    </>
  )
}
