/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode
} from '@chakra-ui/react'
import { ButtonAdicionarEtapa } from './ButtonAdicionarEtapa'
import apiAdmin from '../../../../../services/apiAdmin'
import { useAlterarTrilha } from '../hooks/useAlterarTrilha'

export const StepperTrilha = ({ campanha }) => {
  const { colorMode } = useColorMode()
  const [stepIndex, setStepIndex] = useState(0)
  const [ArrayTrilhas, setArrayTrilhas] = useState([])
  const { UseRequestAlterarTrilha } = useAlterarTrilha()
  const [formValues, setFormValues] = useState({
    etapa: 1,
    mensagem: '',
    mensagemResposta: '',
    idCampanha: '',
    captura: false,
    opcoesTrilha: []
  })

  useEffect(() => {
    if (campanha) {
      const fetchTrilhas = async () => {
        try {
          const response = await apiAdmin.get('/v1/api/admin/chatbot-whatsapp/trilha-completa', {
            headers: { 'x-access-token': localStorage.getItem('token') },
            params: { idCampanha: campanha }
          })
          setArrayTrilhas(response.data)
        } catch (error) {
          console.error('Error fetching trilhas', error)
        }
      }
      fetchTrilhas()
    }
  }, [campanha])

  useEffect(() => {
    if (ArrayTrilhas.length > 0) {
      setFormValues({
        etapa: ArrayTrilhas[stepIndex]?.etapa || stepIndex + 1,
        mensagem: ArrayTrilhas[stepIndex]?.mensagem || '',
        mensagemResposta: ArrayTrilhas[stepIndex]?.mensagemResposta || '',
        idCampanha: ArrayTrilhas[stepIndex]?.idCampanha || '',
        captura: ArrayTrilhas[stepIndex]?.captura || false,
        opcoesTrilha: ArrayTrilhas[stepIndex]?.opcoesTrilha || []
      })
    }
  }, [stepIndex, ArrayTrilhas])

  const next = () => {
    if (stepIndex < ArrayTrilhas.length - 1) {
      setStepIndex(stepIndex + 1)
    }
  }

  const prev = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const updatedFormValues = { ...formValues, [name]: value }
    setFormValues(updatedFormValues)
    const updatedTrilhas = [...ArrayTrilhas]
    updatedTrilhas[stepIndex] = { ...updatedTrilhas[stepIndex], [name]: value }
    setArrayTrilhas(updatedTrilhas)
  }

  const handleCheckboxChange = (e) => {
    const updatedFormValues = { ...formValues, captura: e.target.checked }
    setFormValues(updatedFormValues)
    const updatedTrilhas = [...ArrayTrilhas]
    updatedTrilhas[stepIndex] = { ...updatedTrilhas[stepIndex], captura: e.target.checked }
    setArrayTrilhas(updatedTrilhas)
  }

  const handleOptionChange = (index, e) => {
    const { name, value } = e.target
    const updatedOptions = [...formValues.opcoesTrilha]
    updatedOptions[index] = { ...updatedOptions[index], [name]: value }
    const updatedFormValues = { ...formValues, opcoesTrilha: updatedOptions }
    setFormValues(updatedFormValues)
    const updatedTrilhas = [...ArrayTrilhas]
    updatedTrilhas[stepIndex] = { ...updatedTrilhas[stepIndex], opcoesTrilha: updatedOptions }
    setArrayTrilhas(updatedTrilhas)
  }

  const addOption = () => {
    const updatedOptions = [...formValues.opcoesTrilha, { opcao: '', valor: '', acao: '' }]
    setFormValues({ ...formValues, opcoesTrilha: updatedOptions })
    const updatedTrilhas = [...ArrayTrilhas]
    updatedTrilhas[stepIndex] = { ...updatedTrilhas[stepIndex], opcoesTrilha: updatedOptions }
    setArrayTrilhas(updatedTrilhas)
  }

  const handleEditTrilha = async (e) => {
    e.preventDefault()
    console.log(ArrayTrilhas, 'componente')
    UseRequestAlterarTrilha(ArrayTrilhas)
  }

  return (
    <>
      <Stepper index={stepIndex}>
        {ArrayTrilhas.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
            <Box flexShrink="0">
              <StepTitle>Etapa {step.etapa}</StepTitle>
            </Box>
            <StepSeparator />
          </Step>
        ))}
        <Step>
          <Box flexShrink="0">
            <ButtonAdicionarEtapa ArrayTrilha={ArrayTrilhas} idCampanha={campanha} />
          </Box>
          <StepSeparator />
        </Step>
      </Stepper>

      {ArrayTrilhas.length >= 1
        ? (
        <form id="form-trilhas" onSubmit={handleEditTrilha}>
          <FormControl id="etapa">
            <FormLabel>Etapa</FormLabel>
            <Input disabled value={formValues.etapa} name="etapa" />
          </FormControl>

          <FormControl id="mensagem">
            <FormLabel>Mensagem</FormLabel>
            <Input
              placeholder=" "
              value={formValues.mensagem}
              name="mensagem"
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="mensagemResposta">
            <FormLabel>Mensagem Resposta</FormLabel>
            <Input
              placeholder=" "
              value={formValues.mensagemResposta}
              name="mensagemResposta"
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="idCampanha">
            <FormLabel>ID Campanha</FormLabel>
            <Input disabled value={campanha} name="idCampanha" />
          </FormControl>

          <Divider mt={2} mb={2} />

          <FormControl id="captura">
            <Text>Possui Captura de resposta?</Text>
            <Checkbox isChecked={formValues.captura} onChange={handleCheckboxChange}>
              {formValues.captura ? 'SIM' : 'NÃO'}
            </Checkbox>
          </FormControl>

          {formValues.captura && (
            <Tabs>
              <TabList>
                {formValues.opcoesTrilha.map((option, index) => (
                  <Tab key={index}>Opção {index + 1}</Tab>
                ))}
                <Button onClick={addOption}>Adicionar Opção</Button>
              </TabList>
              <TabPanels>
                {formValues.opcoesTrilha.map((option, index) => (
                  <TabPanel key={index}>
                    <Box>
                      <FormControl id={`opcao-${index}`}>
                        <FormLabel>Texto da resposta</FormLabel>
                        <Input
                          placeholder="Nova opção"
                          value={option.opcoes}
                          name="opcoes"
                          onChange={(e) => handleOptionChange(index, e)}
                        />
                      </FormControl>
                      <FormControl id={`valor-${index}`}>
                        <FormLabel>Proxima etapa</FormLabel>
                        <Input
                          placeholder="Valor"
                          value={option.valor}
                          name="valor"
                          onChange={(e) => handleOptionChange(index, e)}
                        />
                      </FormControl>
                      <FormControl id={`acao-${index}`}>
                        <FormLabel>Ação</FormLabel>
                        <Input
                          placeholder="Ação"
                          value={option.acao}
                          name="acao"
                          onChange={(e) => handleOptionChange(index, e)}
                        />
                      </FormControl>
                    </Box>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          )}

          <Flex mt={6} justifyContent={'flex-end'} gap={4}>
            <Button onClick={prev} colorScheme={'gray'} disabled={stepIndex === 0}>
              Anterior
            </Button>
            <Button onClick={next} disabled={stepIndex >= ArrayTrilhas.length - 1}>
              Próximo
            </Button>
            <Button variant='outline' type="submit">Editar trilha</Button>
          </Flex>
        </form>
          )
        : (
          <Card bg={colorMode === 'light' ? 'orange.200' : 'orange.400'} m={'4rem auto'} w={'70%'}>
          <CardBody>
            <Text fontWeight={700} textTransform={'uppercase'}>Não há trilhas cadastradas</Text>
          </CardBody>
        </Card>
          )}
    </>
  )
}
