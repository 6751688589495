import React from 'react'

import { Box, Card, CardBody, CardHeader, Text, useColorMode } from '@chakra-ui/react'
import { TabelaProcessos } from './components/ListaProcessos'
import { GlobalProvider } from './context/contextPropsProvider'
import { DrawerCreate } from './components/DrawerCreate'

export default function Gerenciamento () {
  const { colorMode } = useColorMode()

  return (
    <GlobalProvider>
      <Box w={'100vw'} h={'100vh'} bg={colorMode === 'light' ? 'white' : 'gray.600'} padding={3} display={'flex'} flexDir={'column'} gridAutoColumns={3}>
        <Card bg={'transparent'}>
          <CardHeader fontWeight={800} fontSize={'xl'} display={'flex'} justifyContent={'space-between'}>
          <div>
            Biblioteca de processos
            <Text color={colorMode === 'light' ? 'gray.500' : 'gray.400'} fontWeight={500} fontSize={'sm'}>Uma listagem de todos o processos ativos no sistema do ChatBot atualmente</Text>
          </div>
          <DrawerCreate />
          </CardHeader>
          <CardBody>
            <TabelaProcessos />
          </CardBody>
        </Card>
      </Box>
    </GlobalProvider>
  )
}
