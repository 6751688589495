import { useQuery } from 'react-query'
import api from '../../../../services/api'

const useGetStatus = (payload) => {
  return useQuery(
    ['chat_useGetStatus', { payload }],
    async () => {
      const response = await api.get('/v1/api/public/confirmacao-emprestimo/obtendo-acompanhamento')
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000,
      refetchInterval: 5000
    }
  )
}

export { useGetStatus }
