/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { BsCheck2Circle, BsUpload } from 'react-icons/bs'

import {
  Button,
  Box,
  Icon,
  Flex,
  Input,
  Text,
  Select,
  Alert,
  AlertIcon
} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import { useGetFiltrosArquivosCampanhas } from '../hooks/useGetFiltrosArquivosCampanhas'
import useRequestUploudArquivo from '../hooks/useRequestUploudArquivo'

export default function ImportadorCampanhaComponent () {
  const queryClient = useQueryClient()
  const [icon, setIcon] = useState(false)
  const [flagAlert, setFlagAlert] = useState(false)
  const fileInputRef = React.useRef(null)
  const [campanhaSelecionada, setCampanhaSelecionada] = useState(null)
  const { data: opcoesDisparoCampanha } = useGetFiltrosArquivosCampanhas()
  const { enviarArquivo, isLoading } = useRequestUploudArquivo()

  const handleFileChange = (e) => {
    const selectedFile = e?.target?.files[0]
    if (selectedFile) {
      if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setIcon(true)
      } else {
        console.error('Selecione um arquivo Excel válido.')
        fileInputRef.current.value = ''
      }
    }
  }

  const handleCancel = () => {
    fileInputRef.current.value = ''
    setIcon(false)
    setFlagAlert(false)
  }

  const handleUpload = async () => {
    setFlagAlert(false)

    if (!campanhaSelecionada) {
      setFlagAlert(true)
      console.error('Selecione uma campanha para enviar.')
      return
    }

    if (fileInputRef?.current?.files?.length === 0) {
      setFlagAlert(true)
      return
    }

    const formData = new FormData()
    formData.append('file', fileInputRef.current.files[0])
    formData.append('campanhaId', campanhaSelecionada)

    try {
      await enviarArquivo(formData)
      fileInputRef.current.value = ''
      setIcon(false)
      queryClient.invalidateQueries(['useGetuseGetArquivosImportados'])
    } catch (error) {
      console.error('Erro ao enviar o arquivo:', error)
    }
  }

  return (
    <Flex justifyContent={'space-between'} flexDir={'column'} p={2} bg={'white'} w={'30%'} h={'90vh'}>

      <Box>

        <Text fontWeight={'bold'}>Insira o arquivo para importação e selecione uma campanha de destino.</Text>
        <Text fontWeight={'semibold'} color={'gray.600'} fontSize={14} mb={2}>
          *Somente arquivos excel*
        </Text>

        <Select
          placeholder="Selecione uma campanha"
          value={campanhaSelecionada}
          onChange={(e) => setCampanhaSelecionada(e.target.value)}
        >
          {opcoesDisparoCampanha?.map((item) => (
            <option key={item.id} value={item.id}>{item.nome}</option>
          ))}
        </Select>

        {(flagAlert) &&
          <Alert mt={4} status='info'>
            <AlertIcon />
            {campanhaSelecionada ? 'Anexe um arquivo para continuar' : 'Selecione uma campanha para enviar'}
          </Alert>
        }

        <Flex pos={'relative'} alignItems={'center'} justifyContent={'center'} mt={4} height={'150px'} boxShadow={'lg'} border={'1px solid #ccc'} rounded={'lg'}>
          <Icon pos={'absolute'} color={icon ? '#229544' : '#6B7280'} fontSize={60} as={icon ? BsCheck2Circle : BsUpload} />
          <Input opacity={0} height={'150px'} type='file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' onChange={handleFileChange} ref={fileInputRef} />
        </Flex>

      </Box>

      <Flex w={'100%'} mt={4}>
        <Button w={'100%'} mr={3} onClick={handleCancel}>
          Cancelar
        </Button>
        <Button w={'100%'} onClick={handleUpload} colorScheme='green' isLoading={isLoading}>
          Importar candidatos
        </Button>
      </Flex>
    </Flex>
  )
}
