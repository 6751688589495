import { useQuery } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useObtendoQuantidadeAtendimentoAgenteMes = () => {
  return useQuery(
    'atendimento_useObtendoQuantidadeAtendimentoAgenteMes',
    async () => {
      const response = await apiAdmin.get('/v1/api/admin/dashboardPainelDeAtendimento/quantidadesDeAtentimentosPeloAgentePorMes')
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000 * 1000 * 5,
      refetchInterval: 300000
    }
  )
}

export { useObtendoQuantidadeAtendimentoAgenteMes }
