import React from 'react'
import { useSelector } from 'react-redux'

import { Card, CardBody, CardHeader, Box, Heading, Grid, useColorMode } from '@chakra-ui/react'

import Stats from './components/Stats'
import TabelaPorDepartamentos from './components/TabelaPorDepartamentos'
import TabelaPorMenu from './components/TabelaPorMenu'
import TabelaPorUsuario from './components/TabelaPorUsuario'
import DashboardAgendamentoComponent from '../rh/dashboard-agendamento'
export default function Home () {
  const { features } = useSelector((state) => state.perfil)
  const { colorMode } = useColorMode()

  return (
    <>
      {features.includes('feat_dashboard')
        ? (
          <>
            <Box px={4} pt={2} bg={colorMode === 'light' ? '' : 'gray.500'}>
              <Card>
                <CardBody>
                  <CardHeader m={'-2'}>
                    <Heading size='sm'>Status de Atendimento </Heading>
                    <Heading fontSize={12} color='gray.500'>Acopanhamento de uso do CHAT </Heading>
                  </CardHeader>
                  <Stats />
                </CardBody>
              </Card>
            </Box>
            <Grid px={4} pb={2} templateColumns='repeat(2, 1fr)' gap={6} bg={colorMode === 'light' ? '' : 'gray.500'}>
              <Box mt={2}>
                <Card>
                  <CardBody>
                    <CardHeader m={'-2'}>
                      <Heading size='sm'>Departamentos</Heading>
                      <Heading fontSize={12} color='gray.500'>Quantidade de acessos por (Status Fechado) </Heading>
                    </CardHeader>
                    <TabelaPorDepartamentos />
                  </CardBody>
                </Card>
              </Box>
              <Box mt={2}>
                <Card>
                  <CardBody>
                    <CardHeader m={'-2'}>
                      <Heading size='sm'>Menus</Heading>
                      <Heading fontSize={12} color='gray.500'>Quantidade de acessos  por (Status Fechado)</Heading>
                    </CardHeader>
                    <TabelaPorMenu />
                  </CardBody>
                </Card>
              </Box>
            </Grid>

            <Box px={4} mb={4} bg={colorMode === 'light' ? '' : 'gray.500'}>
              <Card>
                <CardBody>
                  <CardHeader m={'-2'}>
                    <Heading size='sm'>Menus</Heading>
                    <Heading fontSize={12} color='gray.500'>Quantidade de acessos  por Usuário</Heading>
                  </CardHeader>
                  <TabelaPorUsuario />
                </CardBody>
              </Card>
            </Box>
          </>
          )
        : (
          <>
            {features?.includes('modulo_rh') && (
              <>
                  <DashboardAgendamentoComponent />
              </>
            )}
          </>
          )}
    </>
  )
}
