import React, { useState } from 'react'
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, useColorMode, useDisclosure } from '@chakra-ui/react'
import { FaRegEdit } from 'react-icons/fa'
import { useUpdateProcessamento } from '../hooks/Processamentos/useUpdateProcessamentos'
import { getFormByTipoPorcessamento } from './getFormByTipoProc'

export const DrawerEditar = (processo) => {
  const payload = {}
  const btnRef = React.useRef()
  const cancelRef = React.useRef()
  const { colorMode } = useColorMode()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { UseRequestAtualizarProcesso } = useUpdateProcessamento()
  const { isOpen: aberto, onOpen: abrir, onClose: fechar } = useDisclosure()
  const [tipoProcessamentoSelecionado, setTipoProcessamentoSelecionado] = useState(processo.processo.processamento)

  const handleSave = () => {
    const arrayCampos = document.querySelectorAll('#form-editarProc div > input,select,textarea')

    arrayCampos.forEach((htmlElement) => {
      if (htmlElement.name) {
        if (htmlElement.name === 'idProcessamento') {
          payload[htmlElement.name] = parseInt(htmlElement.value)
        } else {
          payload[htmlElement.name] = htmlElement.value
        }
      }
    })

    document.getElementById('form-editarProc').reset()
    return UseRequestAtualizarProcesso(payload)
  }

  return (
    <>
      <Button ref={btnRef} onClick={onOpen} variant='solid' colorScheme='gray'>
        <FaRegEdit size={18} />
      </Button>

      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size={'full'}
      >
        <DrawerOverlay />
        <DrawerContent bg={colorMode === 'light' ? 'white' : 'gray.600'}>
          <DrawerCloseButton />
          <DrawerHeader>Editar processo: {processo.processo.tipoDeAcao} </DrawerHeader>

          <DrawerBody>
            {getFormByTipoPorcessamento(processo.processo, tipoProcessamentoSelecionado, setTipoProcessamentoSelecionado)}
          </DrawerBody>

          <DrawerFooter >
            <Flex justifyContent={'space-between'} alignItems={'center'} p={2}>
              <Flex>

                <Button variant='outline' mr={3} onClick={onClose}>
                  Cancelar
                </Button>

                <Button colorScheme='green' onClick={() => {
                  abrir()
                }}>
                  Salvar
                </Button>
              </Flex>
            </Flex>
            <>

              <AlertDialog
                isOpen={aberto}
                leastDestructiveRef={cancelRef}
                onClose={fechar}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                      Salvar alterações
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Deseja prosseguir?As alterações aplicadas ao processo afetam a produção.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={fechar}>
                        Cancel
                      </Button>
                      <Button colorScheme='green' onClick={() => {
                        handleSave()
                        fechar()
                      }} ml={3}>
                        confirmar
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
