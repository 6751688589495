import React from 'react'
import {
  Flex, Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text
} from '@chakra-ui/react'
import CriadorCampanhaComponent from './components/CriadorCampanha'
import EditarCampanhaComponent from './components/EditarCampanha'
import infos from '../json/infos.json'
import ImportadorCampanhaComponent from './components/ImportadorCampanha'

export default function GerenciamentoImportacao () {
  return (
    <Flex w={'100%'} alignItems={'center'} justifyContent={'center'}>
      <Flex
        flexDir={'column'}
        bg={'white'}
        p={2}
        w={'70%'}
        h={'90vh'}
        borderRight={'solid gray 0.5px'}
      >
        <CriadorCampanhaComponent />

        <Accordion border={'transparent'} mt={4} allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton
                _expanded={{ bg: '#229544', color: 'white' }}
                _hover={{ bg: '#229544', color: 'white' }}
                rounded={'xl'}
                w={'100%'}
                boxShadow={'md'}
                h={'max-content'}
                p={4}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Text textTransform={'uppercase'} fontWeight={'semibold'}>
                  Editar suas campanhas
                </Text>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {infos?.map((campanha) => (
                <EditarCampanhaComponent key={campanha.id} infos={campanha} />
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>

      <ImportadorCampanhaComponent />
    </Flex>
  )
}
