/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Text, Textarea, Tooltip, useDisclosure } from '@chakra-ui/react'
import { MdWhatsapp } from 'react-icons/md'
import { useEnviarMensagem } from '../hooks/useEnviarMensagem'
import data from '../data.json'

export default function ModalDisparoMsg ({ dadosAniversariante, nomeUsuario, celularUsuario }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()
  const { UseRequestEnviarMensagem } = useEnviarMensagem()
  const [mensagem, setMensagem] = useState('')
  const [mensagemComPalavrasOfensivas, setMensagemComPalavrasOfensivas] = useState(false)

  const sendMSG = () => {
    if (mensagemComPalavrasOfensivas) {
      return
    }

    const payload = {
      responsavel: nomeUsuario,
      celularResponsavel: celularUsuario,
      aniversariante: dadosAniversariante.aniversariante,
      celularAniversariante: dadosAniversariante.celular,
      mensagem
    }

    UseRequestEnviarMensagem(payload)
    onClose()
  }

  const handleTextareaChange = (event) => {
    const texto = event.target.value
    const palavroes = data.palavroes

    let contemPalavrasOfensivas = false

    palavroes.forEach((palavra) => {
      const regex = new RegExp(`\\b${palavra}\\b`, 'gi')
      if (texto.match(regex)) {
        contemPalavrasOfensivas = true
      }
    })

    setMensagem(texto)
    setMensagemComPalavrasOfensivas(contemPalavrasOfensivas)
  }

  const openModal = (eventClick) => {
    if (eventClick) {
      onOpen()
    }
  }

  return (
    <>
      <Tooltip hasArrow placement='left' label={dadosAniversariante.isWhatsApp ? 'Enviar Whats App' : 'Impossibilitado de Enviar Whats App'}>
        <Button pointerEvents={dadosAniversariante.isWhatsApp ? 'all' : 'all'}
          onClick={() => openModal(dadosAniversariante.isWhatsApp)}
          color={'white'} bg={dadosAniversariante.isWhatsApp ? '#229544' : 'red'} colorScheme={dadosAniversariante.isWhatsApp ? 'green' : 'red'} mr={6}><MdWhatsapp size={30} /></Button>
      </Tooltip>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              <Text>
              Enviar parabenização
              </Text>
              <Text fontSize={14} display={mensagemComPalavrasOfensivas ? 'initial' : 'none'} color={'red'}>A mensagem contém palavras ofensivas. <br /> Por favor, remova ou altere-as antes de enviar.</Text>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Textarea value={mensagem} onChange={handleTextareaChange} style={{ color: mensagemComPalavrasOfensivas ? 'red' : 'inherit' }}></Textarea>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme={mensagemComPalavrasOfensivas ? 'red' : 'green'} bg={mensagemComPalavrasOfensivas ? 'red' : '#229544'} pointerEvents={ mensagemComPalavrasOfensivas ? 'none' : 'all'} onClick={sendMSG} ml={3} disabled={mensagemComPalavrasOfensivas}>
                Enviar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
