/* eslint-disable no-mixed-operators */

import React from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'

import { OBTENDO_PERFIL } from './features/perfil/perfilSlice'
import AdminLayout from './layouts/Admin'
import ChatApp from './layouts/Chat'
import PainelAtendimento from './layouts/PainelAtendimento'
import Public from './layouts/Public'
import Grafico from './pages/admin/atendimento/grafico'
import Atendimento from './pages/admin/atendimento/painel/index'
import Relatorio from './pages/admin/atendimento/relatorio'
import Gerenciamento from './pages/admin/gerenciamento/menus'
import Home from './pages/admin/home/index'
import GerenciamentoImportacao from './pages/admin/campanhas/gerenciamento/index'
import ChatbotWha from './pages/chatbotWha/index'
import PAGE401 from './pages/errors/401/index'
import PAGE404 from './pages/errors/404/index'
import PAGE500 from './pages/errors/500/index'
import PaginaFormularios from './pages/paginaFormularios/index'
import PaginaPainelAtendimento from './pages/paginaPainelAtendimento/index'
import PaginaResposta from './pages/paginaResposta/index'
import Login from './pages/public/login/index'
import apiAdmin from './services/apiAdmin'
import HistoricoAtendimento from './pages/admin/historicoAtendimento'
import Tabulacao from './pages/admin/tabulacoes'
import GerenciadorChatbotMenu from './pages/admin/gerenciamento/menus/components/GerenciadorChatbot'
import GerenciadorChatbotProcessamentos from './pages/admin/gerenciamento/processamentos/index'
import Aniversario from './pages/public/aniversario'
import AniversarioCanal from './pages/public/aniversario-canal'
import AgendaCompromissos from './pages/public/agenda-compromisso/index'
import GerenciamentoWhatsapp from './pages/admin/gerenciamento/trilhas'
import Instancias from './pages/admin/gerenciamento/instancias'
import ConfirmacaoPropostasWpp from './pages/admin/confirmacao-propostas'
import ConfirmacaoPropostasWppPublic from './pages/public/confirmacao-propostas'
import ConfirmacaoPropostasWppHistorico from './pages/public/confirmacao-propostas-historico'
import AgendaCompromissoCalendario from './pages/admin/agenda-compromisso'
import DashboardAgendamentoComponent from './pages/admin/rh/dashboard-agendamento'

export default function Router () {
  const { features } = useSelector((state) => state.perfil)

  const location = useLocation()
  const { data, isLoading } = useQuery(
    ['chat_useGetPerfil'],
    async () => {
      const response = await apiAdmin.get('/v1/api/admin/conta/obtendo')
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60 * 5,
      refetchInterval: 60000 * 60,
      enabled: location.pathname.includes('admin')
    }
  )
  function verifyUseFeature (keyFeature, features) {
    if (features === undefined) return

    return features.includes(keyFeature)
  }

  const routing = useRoutes([
    {
      path: '/chatResponse',
      element: <ChatApp />,
      children: [
        { path: 'selectPage', element: <PaginaResposta /> },
        { path: 'formWeb', element: <PaginaFormularios /> }

      ]
    },
    {
      path: '/public',
      element: <Public />,
      children: [
        { path: 'login', element: <Login /> }
      ]
    },
    {
      path: '/public',
      element: <Public />,
      children: [
        { path: '404', element: <PAGE404 /> }
      ]
    },
    {
      path: '/public',
      element: <Public />,
      children: [
        { path: '401', element: <PAGE401 /> }
      ]
    },
    {
      path: '/public',
      element: <Public />,
      children: [
        { path: '500', element: <PAGE500 /> }
      ]
    },
    {
      path: '/public',
      element: <Public />,
      children: [
        { path: 'chatbotWha', element: <ChatbotWha /> },
        { path: 'aniversario', element: <Aniversario /> },
        { path: 'aniversario-canal', element: <AniversarioCanal /> },
        { path: 'agenda-compromisso', element: <AgendaCompromissos /> },
        { path: 'confirmacao-wpp', element: <ConfirmacaoPropostasWppPublic /> },
        { path: 'confirmacao-wpp-historico', element: <ConfirmacaoPropostasWppHistorico /> }
      ]
    },
    {
      path: '/admin',
      element: <AdminLayout />,
      children: [
        { path: 'instancias', element: verifyUseFeature('modulo_atendimento', features) ? <Instancias /> : <Navigate to='/public/401' replace /> },
        { path: 'home', element: verifyUseFeature('auth', features) ? <Home /> : <Navigate to='/public/401' replace /> },
        { path: 'confirmacaoWpp', element: verifyUseFeature('modulo_atendimento', features) ? <ConfirmacaoPropostasWpp /> : <Navigate to='/public/401' replace /> },
        { path: 'atendimento', element: verifyUseFeature('modulo_atendimento', features) ? <Atendimento /> : <Navigate to='/public/401' replace /> },
        { path: 'historicoAtendimento', element: verifyUseFeature('modulo_atendimento', features) ? <HistoricoAtendimento /> : <Navigate to='/public/401' replace /> },
        { path: 'gerenciador-chatbot', element: verifyUseFeature('modulo_atendimento', features) ? <Gerenciamento /> : <Navigate to='/public/401' replace /> },
        { path: 'gerenciador-menu', element: verifyUseFeature('modulo_atendimento', features) ? <GerenciadorChatbotMenu /> : <Navigate to='/public/401' replace /> },
        { path: 'gerenciador-processamentos', element: verifyUseFeature('modulo_atendimento', features) ? <GerenciadorChatbotProcessamentos /> : <Navigate to='/public/401' replace /> },
        { path: 'gerenciador-whatsapp', element: verifyUseFeature('modulo_rh', features) ? <GerenciamentoWhatsapp /> : <Navigate to='/public/401' replace /> },
        { path: 'grafico', element: verifyUseFeature('modulo_atendimento', features) ? <Grafico /> : <Navigate to='/public/401' replace /> },
        { path: 'relatorio', element: verifyUseFeature('modulo_atendimento', features) ? <Relatorio /> : <Navigate to='/public/401' replace /> },
        { path: 'tabulacao', element: verifyUseFeature('modulo_atendimento', features) ? <Tabulacao /> : <Navigate to='/public/401' replace /> },
        { path: 'campanhas', element: verifyUseFeature('modulo_campanhas', features) ? <GerenciamentoImportacao /> : <Navigate to='/public/401' replace /> },
        { path: 'agenda-compromissos', element: verifyUseFeature('modulo_campanhas', features) ? <AgendaCompromissoCalendario /> : <Navigate to='/public/401' replace /> },
        { path: 'dashboard-agenda-compromissos', element: verifyUseFeature('modulo_rh', features) ? <DashboardAgendamentoComponent /> : <Navigate to='/public/401' replace /> }
      ]
    },
    {
      path: '/atendimento',
      element: <PainelAtendimento />,
      children: [
        { path: 'chat', element: <PaginaPainelAtendimento /> }
      ]
    },
    { path: '*', element: <Navigate to='/public/404' replace /> },
    { path: '/', element: <Navigate to='/app/chat' replace /> }
  ])
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (data !== undefined) {
      dispatch(OBTENDO_PERFIL(data))
    }
  }, [dispatch, data])

  if (isLoading || features.length === 0 && location.pathname.includes('admin')) {
    return <>Loading</>
  }

  return routing
}
