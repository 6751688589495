/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  NumberInput,
  NumberInputField,
  VStack,
  HStack,
  IconButton,
  Box,
  Divider
} from '@chakra-ui/react'
import { MdOutlineWork } from 'react-icons/md'
import { BiTrash } from 'react-icons/bi'

export default function EditarCampanhaComponent ({ infos }) {
  const detalhes = infos.detalhes
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef(null)
  const [formData, setFormData] = useState({
    id: infos.id || '',
    vaga: infos.vaga || '',
    cep: infos.cep || '',
    logradouro: infos.logradouro || '',
    cidade: infos.cidade || '',
    estado: infos.estado || '',
    recrutadora: infos.recrutadora || '',
    valor: infos.valor || '',
    descricao: infos.descricao || '',
    requisitos: infos.requisitos || [],
    desejaveis: infos.desejaveis || [],
    beneficios: infos.beneficios || [],
    agenda: { ...infos.agenda }
  })

  useEffect(() => {
    if (infos && Array.isArray(infos.requisitos)) {
      setFormData({
        id: infos.id || '',
        vaga: infos.vaga || '',
        cep: infos.cep || '',
        logradouro: infos.logradouro || '',
        cidade: infos.cidade || '',
        estado: infos.estado || '',
        recrutadora: infos.recrutadora || '',
        valor: infos.valor || '',
        descricao: infos.descricao || '',
        requisitos: infos.requisitos || [],
        desejaveis: infos.desejaveis || [],
        beneficios: infos.beneficios || [],
        agenda: { ...infos.agenda }
      })
    }
  }, [infos])

  function parseJobDetails (html) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    const getNextUl = (element) => {
      while (element && element.tagName !== 'UL') {
        element = element.nextElementSibling
      }
      return element ? Array.from(element.querySelectorAll('li')).map(li => li.textContent.trim()) : []
    }

    const sections = Array.from(doc.querySelectorAll('p, b'))
    let descricao = []
    let requisitos = []
    let desejavel = []
    let beneficios = []

    sections.forEach((section, index) => {
      const text = section.textContent.trim()
      if (text.includes('Descrição:')) {
        const nextElement = sections[index + 1]
        if (nextElement && nextElement.tagName === 'P') {
          descricao.push(nextElement.textContent.trim())
        }
        descricao = descricao.concat(getNextUl(nextElement))
      } else if (text.includes('Requisitos')) {
        requisitos = getNextUl(section.nextElementSibling)
      } else if (text.includes('Desejável')) {
        desejavel = getNextUl(section.nextElementSibling)
      } else if (text.includes('Benefícios')) {
        beneficios = getNextUl(section.nextElementSibling)
      }
    })

    return {
      descricao,
      requisitos,
      desejavel,
      beneficios
    }
  }

  const jobDetails = parseJobDetails(detalhes)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleArrayChange = (e, index, arrayName) => {
    const newArray = [...formData[arrayName]]
    newArray[index] = e.target.value
    setFormData({ ...formData, [arrayName]: newArray })
  }

  const addArrayField = (arrayName) => {
    setFormData({
      ...formData,
      [arrayName]: [...formData[arrayName], '']
    })
  }

  const removeArrayField = (index, arrayName) => {
    const newArray = formData[arrayName].filter((_, i) => i !== index)
    setFormData({
      ...formData,
      [arrayName]: newArray
    })
  }

  const handleAgendaChange = (e, index, field) => {
    const newDates = [...formData.agenda.datas]
    newDates[index][field] = e.target.value
    setFormData({
      ...formData,
      agenda: { ...formData.agenda, datas: newDates }
    })
  }

  const addDateField = () => {
    setFormData({
      ...formData,
      agenda: {
        ...formData.agenda,
        datas: [...formData.agenda.datas, { dia: '', hora: '' }]
      }
    })
  }

  const removeDateField = (index) => {
    const newDates = formData.agenda.datas.filter((_, i) => i !== index)
    setFormData({
      ...formData,
      agenda: { ...formData.agenda, datas: newDates }
    })
  }

  const generateMarkdown = () => {
    return `
    <p><b>Recrutadora:</b> ${formData.recrutadora}</p>
    <p><b>Endereço:</b> ${formData.logradouro}, ${formData.cidade} - ${formData.estado}</p>
    <br>
    <p><b>Vaga para ${formData.vaga}</b></p>
    <br>
    <p><b>Descrição:</b></p>
    <p>${jobDetails.descricao[0]}</p>
    <ul>
      ${jobDetails.requisitos.map(req => `<li>${req}</li>`).join('')}
    </ul>
    <br>
    <b>Desejável</b>
    <ul>
      ${jobDetails.desejavel.map(des => `<li>${des}</li>`).join('')}
    </ul>
    <br>
    <b>Benefícios</b>
    <ul>
      ${jobDetails.beneficios.map(ben => `<li>${ben}</li>`).join('')}
    </ul>
    <br>
  `
  }

  const handleSubmit = () => {
    const { descricao, requisitos, desejaveis, beneficios, ...filteredFormData } = formData
    const markdownDetalhes = generateMarkdown()
    const finalFormData = { ...filteredFormData, detalhes: markdownDetalhes }
    console.log('Updated data:', finalFormData)
    onClose()
  }

  return (
    <>
      <Flex
        _hover={{ bg: '#229544', color: 'white' }}
        cursor={'pointer'}
        rounded={'xl'}
        w={'100%'}
        boxShadow={'md'}
        h={'max-content'}
        p={4}
        alignItems={'center'}
        justifyContent={'space-between'}
        ref={btnRef}
        colorScheme='teal'
        onClick={onOpen}
      >
        <Text textTransform={'uppercase'} fontWeight={'semibold'}>
          Editar campanha
        </Text>
        <MdOutlineWork size={30} />
      </Flex>

      <Drawer
        size={'full'}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Editar informações da campanha</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4} align='stretch'>

              <Box>
                <Text fontSize="xl" fontWeight="bold">Informações da vaga</Text>
                <FormControl>
                  <FormLabel>Vaga</FormLabel>
                  <Input name="vaga" value={formData.vaga} onChange={handleInputChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>CEP</FormLabel>
                  <Input name="cep" value={formData.cep} onChange={handleInputChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Logradouro</FormLabel>
                  <Input name="logradouro" value={formData.logradouro} onChange={handleInputChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Cidade</FormLabel>
                  <Input name="cidade" value={formData.cidade} onChange={handleInputChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Estado</FormLabel>
                  <Input name="estado" value={formData.estado} onChange={handleInputChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Valor</FormLabel>
                  <Input name="valor" value={formData.valor} onChange={handleInputChange} />
                </FormControl>
              </Box>

              <Divider />

              <Box>
                <Text fontSize="xl" fontWeight="bold">Agenda</Text>
                <FormControl>
                  <FormLabel>Disponíveis</FormLabel>
                  <NumberInput value={formData.agenda.disponiveis} onChange={(value) => setFormData({ ...formData, agenda: { ...formData.agenda, disponiveis: value } })}>
                    <NumberInputField name="disponiveis" />
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>Ocupados</FormLabel>
                  <NumberInput value={formData.agenda.ocupados} onChange={(value) => setFormData({ ...formData, agenda: { ...formData.agenda, ocupados: value } })}>
                    <NumberInputField name="ocupados" />
                  </NumberInput>
                </FormControl>
                <FormControl>
                  <FormLabel>Limite</FormLabel>
                  <NumberInput value={formData.agenda.limite} onChange={(value) => setFormData({ ...formData, agenda: { ...formData.agenda, limite: value } })}>
                    <NumberInputField name="limite" />
                  </NumberInput>
                </FormControl>
                {formData.agenda.datas.map((date, index) => (
                  <HStack key={index} spacing={2}>
                    <FormControl>
                      <FormLabel>Dia</FormLabel>
                      <Input name="dia" value={date.dia} onChange={(e) => handleAgendaChange(e, index, 'dia')} />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Hora</FormLabel>
                      <Input name="hora" value={date.hora} onChange={(e) => handleAgendaChange(e, index, 'hora')} />
                    </FormControl>
                    <IconButton
                      mt={8}
                      colorScheme='red'
                      aria-label='Remover data'
                      icon={<BiTrash size={22} />}
                      onClick={() => removeDateField(index)}
                    />
                  </HStack>
                ))}
                <Button mt={4} w={'100%'} colorScheme='green' onClick={addDateField}>Adicionar nova data</Button>
              </Box>

              <Divider />

              <Box>
                <Text fontSize="xl" fontWeight="bold">Detalhes da vaga</Text>
                <FormControl>
                  <FormLabel>Recrutadora</FormLabel>
                  <Input name="recrutadora" value={formData.recrutadora} onChange={handleInputChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Descrição</FormLabel>
                  <Textarea name="descricao" value={jobDetails.descricao[0]} onChange={handleInputChange} />
                </FormControl>

                <FormControl>
                  <FormLabel>Requisitos</FormLabel>
                  {jobDetails.requisitos.map((req, index) => (
                    <Flex key={index} align="center" gap={2}>
                      <Input
                        mt={1}
                        value={req}
                        onChange={(e) => handleArrayChange(e, index, 'requisitos')}
                        placeholder="Requisito"
                      />
                      <IconButton
                        colorScheme='red'
                        icon={<BiTrash size={22} />}
                        aria-label="Remover requisito"
                        onClick={() => removeArrayField(index, 'requisitos')}
                      />
                    </Flex>
                  ))}
                  <Button my={4} w={'100%'} colorScheme='green' onClick={() => addArrayField('requisitos')}>Adicionar requisito</Button>
                </FormControl>

                <FormControl>
                  <FormLabel>Desejáveis</FormLabel>
                  {jobDetails.desejavel.map((des, index) => (
                    <Flex key={index} align="center" gap={2}>
                      <Input
                        mt={1}
                        value={des}
                        onChange={(e) => handleArrayChange(e, index, 'desejaveis')}
                        placeholder="Desejável"
                      />
                      <IconButton
                        aria-label="Remover desejável"
                        colorScheme='red'
                        icon={<BiTrash size={22} />}
                        onClick={() => removeArrayField(index, 'desejaveis')}
                      />
                    </Flex>
                  ))}
                  <Button my={4} w={'100%'} colorScheme='green' onClick={() => addArrayField('desejaveis')}>Adicionar desejável</Button>
                </FormControl>

                <FormControl>
                  <FormLabel>Benefícios</FormLabel>
                  {jobDetails.beneficios.map((ben, index) => (
                    <Flex key={index} align="center" gap={2}>
                      <Input
                        mt={1}
                        value={ben}
                        onChange={(e) => handleArrayChange(e, index, 'beneficios')}
                        placeholder="Benefício"
                      />
                      <IconButton
                        aria-label="Remover benefício"
                        colorScheme='red'
                        icon={<BiTrash size={22} />}
                        onClick={() => removeArrayField(index, 'beneficios')}
                      />
                    </Flex>
                  ))}
                  <Button my={4} w={'100%'} colorScheme='green' onClick={() => addArrayField('beneficios')}>Adicionar benefício</Button>
                </FormControl>

              </Box>

            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <Button mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme="green" onClick={handleSubmit}>
              Salvar alterações
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
