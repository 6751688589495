import { useMutation } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useApagarMensagem = () => {
  const apagarMensagem = async (payload) => {
    await apiAdmin.put('/v1/api/admin/atendimento/apagar-mensagem', payload)
  }

  const mutation = useMutation(apagarMensagem)

  const UseRequestApagarMensagem = (idProtocolo, idMensagem) => {
    mutation.mutate({
      idProtocolo,
      idMensagem
    })
  }
  return {
    UseRequestApagarMensagem,
    isLoading: mutation.isLoading
  }
}

export { useApagarMensagem }
