/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { CardExibicaoInput, CardExibicaoTextArea } from './CardExibicaoEditar'
import { Box, Card, Icon, Input, Text } from '@chakra-ui/react'
import { BsCheck2Circle, BsFillCloudUploadFill } from 'react-icons/bs'
import api from '../../../../../services/apiAdmin'

export const FormByTipoProcessamento = ({ dadosProcesso, tipoProcesso }) => {
  const fileInputRef = React.useRef(null)
  const [icon, setIcon] = useState(false)
  const [inputMensagemValue, setinputMensagemValue] = useState(dadosProcesso.mensagem ?? '')
  const [inputTipoDeAcaoValue, setinputTipoDeAcaoValue] = useState(dadosProcesso.tipoDeAcao ?? '')

  const handleFileChange = (e) => {
    // Criando um novo objeto FormData
    const formData = new FormData()

    // Adicionando o arquivo ao objeto FormData
    formData.append('file', e.target.files[0])

    // Fazendo a requisição usando fetch
    fetch(`${api}/v1/api/admin/bibliotecaProcessos/arquivo`, {
      headers: { 'x-access-token': localStorage.getItem('token') },
      method: 'POST', // ou outro método HTTP apropriado
      body: formData // Passando o objeto FormData como corpo da requisição
    })
      .then(async (response) => {
        const locationFile = await response.json()
        setinputMensagemValue(locationFile.location)
        setIcon(true)
      })
      .catch(error => {
        console.error(error)
        setIcon(false)
      })
  }

  switch (tipoProcesso) {
    case 'Select':
      return (
        <>
        <form id='form-editarProc'>
          {
            dadosProcesso.idProcessamento
              ? <CardExibicaoInput ValorCampo={dadosProcesso.idProcessamento} LabelCampo={'ID'} name={'idProcessamento'} disabled={true} />
              : ''
          }

          <CardExibicaoTextArea ValorCampo={dadosProcesso.query} LabelCampo={'query'} />

          <Card maxW={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
            <Text fontWeight={600}>
              Tipo de Ação
            </Text>
            <Input name='tipoDeAcao' value={inputTipoDeAcaoValue} onChange={(event) =>
              setinputTipoDeAcaoValue(event.target.value)
            } />
          </Card>
        </form>
        </>
      )
    case 'SelectPage':
      return (
        <>
        <form id='form-editarProc'>
          {
            dadosProcesso.idProcessamento
              ? <CardExibicaoInput ValorCampo={dadosProcesso.idProcessamento} LabelCampo={'ID'} name={'idProcessamento'} disabled={true} />
              : ''
          }

          <CardExibicaoTextArea ValorCampo={dadosProcesso.query} LabelCampo={'query'} disabled={true} />

            <Card w={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
              <Text fontWeight={600} textTransform={'capitalize'}>
                Mensagem
              </Text>
              <Input name='mensagem' value={inputMensagemValue} onChange={(event) => {
                setinputMensagemValue(event.target.value)
              }} />
            </Card>

          <Card maxW={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
            <Text fontWeight={600}>
              Tipo de Ação
            </Text>
            <Input name='tipoDeAcao' value={inputTipoDeAcaoValue} onChange={(event) =>
              setinputTipoDeAcaoValue(event.target.value)
            } />
          </Card>
        </form>
        </>
      )
    case 'messageUrl':
      return (
        <>
        <form id='form-editarProc'>
          {
            dadosProcesso.idProcessamento
              ? <CardExibicaoInput ValorCampo={dadosProcesso.idProcessamento} LabelCampo={'ID'} name={'idProcessamento'} disabled={true} />
              : ''
          }

          <Card w={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
            <Text fontWeight={600} textTransform={'capitalize'}>
              Mensagem
            </Text>
            <Input name='mensagem' value={inputMensagemValue} onChange={(event) => {
              setinputMensagemValue(event.target.value)
            }} />
          </Card>

          <Card maxW={'99%'} maxH={'9rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
            <Text fontWeight={600}>
              Arquivo
            </Text>
            <Box height={'100px'} maxW={'99%'} alignItems={'center'} justifyItems={'center'} boxShadow={'lg'} border={'1px solid #ccc'} rounded={'lg'}>
              <Icon color={icon ? '#229544' : 'red'} pos={'absolute'} left={'42%'} top={'42%'} fontSize={60} as={icon ? BsCheck2Circle : BsFillCloudUploadFill} />
              <Input opacity={0} height={'150px'} type='file' onChange={handleFileChange} ref={fileInputRef} />
            </Box>
          </Card>

          <Card maxW={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
            <Text fontWeight={600}>
              Tipo de Ação
            </Text>
            <Input name='tipoDeAcao' value={inputTipoDeAcaoValue} onChange={(event) =>
              setinputTipoDeAcaoValue(event.target.value)
            } />
          </Card>
        </form>
        </>
      )
    case 'message':
      return (
        <>
        <form id='form-editarProc'>
          {
            dadosProcesso.idProcessamento
              ? <CardExibicaoInput ValorCampo={dadosProcesso.idProcessamento} LabelCampo={'ID'} name={'idProcessamento'} disabled={true} />
              : ''
          }

            <Card w={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
              <Text fontWeight={600} textTransform={'capitalize'}>
                Mensagem
              </Text>
              <Input name='mensagem' value={inputMensagemValue} onChange={(event) => {
                setinputMensagemValue(event.target.value)
              }} />
            </Card>

          <Card maxW={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
            <Text fontWeight={600}>
              Tipo de Ação
            </Text>
            <Input name='tipoDeAcao' value={inputTipoDeAcaoValue} onChange={(event) =>
              setinputTipoDeAcaoValue(event.target.value)
            } />

          </Card>
        </form>
        </>
      )
    case 'FormWeb':
      return (
        <>
        <form id='form-editarProc'>
          {
            dadosProcesso.idProcessamento
              ? <CardExibicaoInput ValorCampo={dadosProcesso.idProcessamento} LabelCampo={'ID'} name={'idProcessamento'} disabled={true} />
              : ''
          }

            <Card w={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
              <Text fontWeight={600} textTransform={'capitalize'}>
                Mensagem
              </Text>
              <Input name='mensagem' value={inputMensagemValue} onChange={(event) => {
                setinputMensagemValue(event.target.value)
              }} />
            </Card>

          <Card maxW={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
            <Text fontWeight={600}>
              Tipo de Ação
            </Text>
            <Input name='tipoDeAcao' value={inputTipoDeAcaoValue} onChange={(event) =>
              setinputTipoDeAcaoValue(event.target.value)
            } />
          </Card>
        </form>
        </>
      )
    case 'painelDeAtendimento':
      return (
        <>
        <form id='form-editarProc'>
          {
            dadosProcesso.idProcessamento
              ? <CardExibicaoInput ValorCampo={dadosProcesso.idProcessamento} LabelCampo={'ID'} name={'idProcessamento'} disabled={true} />
              : ''
          }

          <Card w={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
            <Text fontWeight={600}>
              Nome do Formulario
            </Text>
            <Card maxW={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
              <Text fontWeight={600}>
                Tipo de Ação
              </Text>
              <Input name='tipoDeAcao' value={inputTipoDeAcaoValue} onChange={(event) =>
                setinputTipoDeAcaoValue(event.target.value)
              } />
            </Card>
          </Card>

            <Card w={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
              <Text fontWeight={600} textTransform={'capitalize'}>
                Mensagem
              </Text>
              <Input name='mensagem' value={inputMensagemValue} onChange={(event) => {
                setinputMensagemValue(event.target.value)
              }} />
            </Card>

          <Card maxW={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
            <Text fontWeight={600}>
              Tipo de Ação
            </Text>
            <Input name='tipoDeAcao' value={inputTipoDeAcaoValue} onChange={(event) =>
              setinputTipoDeAcaoValue(event.target.value)
            } />
          </Card>
        </form>
        </>
      )
    default:
      return (
        ''
      )
  }
}
