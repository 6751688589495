import { useQuery } from 'react-query'

import store from '../../../../features/index'
import apiAdmin from '../../../../services/apiAdmin'
import { obtendoDadosAtendimento } from '../../../../features/atendimento/atendimentoSlice'
const useObtendoAtendimentos = (dataInicial, dataHoje) => {
  const { dispatch } = store
  const mes = new Date().getMonth() + 1
  const ano = new Date().getFullYear()
  return useQuery(
    'atendimento_useObtendoAtendimentos',
    async () => {
      const response = await apiAdmin.get(`/v1/api/admin/atendimento/obtendo-historico-atendimentos?dataInicial=${dataInicial ?? `${ano}-${mes}-01`}&dataFinal=${dataHoje ?? new Date().toISOString().split('T')[0]}`)
      if (response.status === 200) {
        dispatch(obtendoDadosAtendimento({ listaDeAtendimentos: response.data?.atendimentos }))
      }
      return response.data?.atendimentos
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: false
    }
  )
}

export { useObtendoAtendimentos }
