import { Badge, Box, Flex, Skeleton, Spinner, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { useGetCampanhas } from './hooks/useGetCampanhas'
import { FcApproval, FcDisclaimer, FcEndCall, FcHome, FcMoneyTransfer, FcPositiveDynamic } from 'react-icons/fc'
import { MdImportContacts } from 'react-icons/md'
import { GiPayMoney } from 'react-icons/gi'

export default function DashboardAgendamentoComponent () {
  const { data, isLoading } = useGetCampanhas()
  const resultadosCampanhas = data || []

  return (
    <Box p={4}>
      <Flex flexDir={'column'} w={'100%'} alignItems={'center'} justifyContent={'center'} gap={2}>
        <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} gap={2}>
          <Box boxShadow={'lg'} rounded={'xl'} p={2} bg={'white'} w={'100%'}>
            <Text fontWeight={'semibold'}>AGENDADOS</Text>
            <Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
              {isLoading
                ? (
                  <Spinner />
                  )
                : (
                  <>
                    <Text fontSize={22}>{resultadosCampanhas.quantidadePorMotivos[0].motivo_Agendado}</Text>
                    <FcApproval size={32} />
                  </>
                  )}
            </Flex>
          </Box>
          <Box boxShadow={'lg'} rounded={'xl'} p={2} bg={'white'} w={'100%'}>
            <Text fontWeight={'semibold'}>REPROVADO / AJUDA DE CUSTO</Text>
            <Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
              {isLoading
                ? (
                  <Spinner />
                  )
                : (
                  <>
                    <Text fontSize={22}>{resultadosCampanhas.quantidadePorMotivos[0].motivo_AjudaDeCusto}</Text>
                    <FcMoneyTransfer size={32} />
                  </>
                  )}
            </Flex>
          </Box>
          <Box boxShadow={'lg'} rounded={'xl'} p={2} bg={'white'} w={'100%'}>
            <Text fontWeight={'semibold'}>REPROVADO / FALTA DE BENEFÍCIOS</Text>
            <Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
              {isLoading
                ? (
                  <Spinner />
                  )
                : (
                  <>
                    <Text fontSize={22}>{resultadosCampanhas.quantidadePorMotivos[0].motivo_Beneficios}</Text>
                    <GiPayMoney color='orange' size={32} />
                  </>
                  )}
            </Flex>
          </Box>
          <Box boxShadow={'lg'} rounded={'xl'} p={2} bg={'white'} w={'100%'}>
            <Text fontWeight={'semibold'}>REPROVADO / SEM INTERESSE</Text>
            <Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
              {isLoading
                ? (
                  <Spinner />
                  )
                : (
                  <>
                    <Text fontSize={22}>{resultadosCampanhas.quantidadePorMotivos[0].motivo_SemInteresse}</Text>
                    <FcDisclaimer size={32} />
                  </>
                  )}
            </Flex>
          </Box>
        </Flex>
        <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} gap={2}>
          <Box boxShadow={'lg'} rounded={'xl'} p={2} bg={'white'} w={'100%'}>
            <Text fontWeight={'semibold'}>REPROVADO / SEM INTERAÇÃO</Text>
            <Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
              {isLoading
                ? (
                  <Spinner />
                  )
                : (
                  <>
                    <Text fontSize={22}>{resultadosCampanhas.quantidadePorMotivos[0].motivo_SemInteração}</Text>
                    <FcEndCall size={32} />
                  </>
                  )}
            </Flex>
          </Box>
          <Box boxShadow={'lg'} rounded={'xl'} p={2} bg={'white'} w={'100%'}>
            <Text fontWeight={'semibold'}>REPROVADO / PREFERÊNCIA DE REGIME</Text>
            <Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
              {isLoading
                ? (
                  <Spinner />
                  )
                : (
                  <>
                    <Text fontSize={22}>{resultadosCampanhas.quantidadePorMotivos[0].motivo_Regime}</Text>
                    <MdImportContacts color='blue' size={32} />
                  </>
                  )}
            </Flex>
          </Box>
          <Box boxShadow={'lg'} rounded={'xl'} p={2} bg={'white'} w={'100%'}>
            <Text fontWeight={'semibold'}>REPROVADO / BUSCA HOME OFFICE</Text>
            <Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
              {isLoading
                ? (
                  <Spinner />
                  )
                : (
                  <>
                    <Text fontSize={22}>{resultadosCampanhas.quantidadePorMotivos[0].motivo_HomeOffice}</Text>
                    <FcHome size={32} />
                  </>
                  )}
            </Flex>
          </Box>
          <Box boxShadow={'lg'} rounded={'xl'} p={2} bg={'white'} w={'100%'}>
            <Text fontWeight={'semibold'}>TOTAL</Text>
            <Flex w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
              {isLoading
                ? (
                  <Spinner />
                  )
                : (
                  <>
                    <Text fontSize={22}>{resultadosCampanhas.quantidadePorMotivos[0].total}</Text>
                    <FcPositiveDynamic size={32} />
                  </>
                  )}
            </Flex>
          </Box>
        </Flex>
      </Flex>

      {isLoading
        ? (
          <Stack mt={4}>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
          )
        : (
          <TableContainer mt={4} maxH={'400px'} overflowY={'scroll'} css={{
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'green',
              borderRadius: '10px'
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1'
            }
          }}>
            <Table bg={'white'} rounded={'xl'} size='sm'>
              <Thead>
                <Tr>
                  <Th>Candidato</Th>
                  <Th textAlign={'center'}>Celular</Th>
                  <Th textAlign={'center'}>Campanha</Th>
                  <Th textAlign={'center'}>Motivo</Th>
                </Tr>
              </Thead>
              <Tbody >
                {resultadosCampanhas?.dadosSelecao?.map((result) => (
                  <Tr key={result?.celular}>
                    <Td>{result?.nome}</Td>
                    <Td textAlign={'center'}>{result?.celular}</Td>
                    <Td textAlign={'center'}>{result?.nomeCampanha}</Td>
                    <Td textAlign={'center'}>
                      {result?.motivo === 'Agendado'
                        ? (
                          <Badge colorScheme='green'>{result.motivo}</Badge>
                          )
                        : (
                          <Badge colorScheme='red'>{result.motivo}</Badge>
                          )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          )}

    </Box>
  )
}
