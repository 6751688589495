import { useMutation } from 'react-query'
import { useState } from 'react'
import api from '../../api/api'

const useCloseAllInstancia = () => {
  const [instanceCloseAll, setIntanceCloseAll] = useState(null)

  const closeAllInstancia = async () => {
    const response = await api.post('/api/instances/closeAllInstances')
    return response.data
  }

  const mutation = useMutation(closeAllInstancia, {
    onSuccess: (data) => {
      setIntanceCloseAll(data)
    },
    onError: (error) => {
      console.error('onError:', error)
    }
  })

  const UseRequestCloseAllInstancia = () => {
    mutation.mutate()
  }

  return {
    UseRequestCloseAllInstancia,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    instanceCloseAll
  }
}

export { useCloseAllInstancia }
