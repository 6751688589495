import React from 'react'
import { useSelector } from 'react-redux'

import { Box, GridItem, Grid, Card, Flex, CardHeader, Text, CardBody, Badge, Image } from '@chakra-ui/react'

import logo from '../../assets/icon.png'
import formatDataHoraChat from '../../helpers/dataHora/formatDataHoraChat'
import checkPageStatus from '../../helpers/verificaNotificacao'
import socket from '../../services/socket-config'
import BlocoInformacaoDeAtedimento from './components/BlocoInformacaoDeAtedimento'
import CaixaDeMensagem from './components/CaixaDeMensagem'
import Mensagens from './components/Mensagens'
import TrilhasAtendimento from './components/TrilhasAtendimento'
import { useAlterarStatusOnlineOffline } from './hooks/useAlterarStatusOnlineOffline'
import { useCancelarAtendimentoAutomaticamente } from './hooks/useCancelarAtendimentoAutomaticamente'

export default function PaginaPainelAtendimento () {
  const { informacao } = useSelector((state) => state.blocoInformacaoAtendimento)
  const { UseRequestuseCancelamentoDeAtendimento } = useCancelarAtendimentoAutomaticamente()
  const { UseRequestAlterarStatusOnlineOffline } = useAlterarStatusOnlineOffline()

  const search = window.location.search
  const params = new URLSearchParams(search)
  const codePage = params.get('codePage')

  React.useEffect(() => {
    const { idProtocolo, idAgente } = informacao

    if (!idProtocolo || !idAgente) return
    const roomKey = `${idProtocolo}-${idAgente}`

    const handleVisibilityChange = () => {
      if (document.hidden) {
        // A página não está visível, então ouça notificações
        if (informacao.status !== 'Fechado' && informacao.status !== 'Cancelado (Por Falta de Interação)') {
          UseRequestAlterarStatusOnlineOffline({
            online: false,
            idProtocolo
          })
        }

        socket.emit('joinRoom', roomKey)
        socket.on(`notificacao-painel-atendimento-cliente-${roomKey}`, (notification) => {
          checkPageStatus(notification.message)
        })
      } else {
        // A página está visível, então pare de ouvir notificações
        if (informacao.status !== 'Fechado' && informacao.status !== 'Cancelado (Por Falta de Interação)') {
          UseRequestAlterarStatusOnlineOffline({
            online: true,
            idProtocolo
          })
        }

        socket.emit('leaveRoom', roomKey)
        socket.off(`notificacao-painel-atendimento-cliente-${roomKey}`)
      }
    }

    // Adicionar o ouvinte de visibilidade
    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Remover o ouvinte ao desmontar o componente
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [UseRequestAlterarStatusOnlineOffline, informacao])

  const [interacaoDetectada, setInteracaoDetectada] = React.useState(false)
  const [sessaoEncerrada, setSessaoEncerrada] = React.useState(false)

  const encerrarAutomaticamente = () => {
    setSessaoEncerrada(true)
    UseRequestuseCancelamentoDeAtendimento({
      codePage,
      idProtocolo: informacao.idProtocolo
    })
  }

  React.useEffect(() => {
    function detectarInteracao () {
      setInteracaoDetectada(true)
    }

    document.addEventListener('mousemove', detectarInteracao)
    document.addEventListener('click', detectarInteracao)
    document.addEventListener('keypress', detectarInteracao)
  }, [])

  React.useEffect(() => {
    if (!sessaoEncerrada && informacao.status !== 'Cancelado (Por Falta de Interação)' && informacao.status !== 'Fechado') {
      const interval = setInterval(() => {
        if (interacaoDetectada) {
          setInteracaoDetectada(false)
        } else if (informacao.status !== 'Fechado') {
          encerrarAutomaticamente()
        }
      }, 300000)

      return () => clearInterval(interval)
    }
  }, [interacaoDetectada, encerrarAutomaticamente, sessaoEncerrada, informacao.status])

  return (
    <Box bg={'gray.100'} overflow={'hidden'} className='App'>
      <Text boxShadow={'2xl'} color={'white'} bg={'#229544'} p={2} borderRadius={'25px 0 25px 25px'} mt={2} fontSize={22} display={'flex'} alignItems={'center'} justifyContent={'center'} zIndex={99} pos={'fixed'} right={4}>
        <Image mr={2} w={'40px'} src={logo} />
        Chat Mais Valor
      </Text>
      <Grid
        templateAreas={`
                  "nav main"
                  "nav footer"`}
        gridTemplateRows={'663px 0px'}
        gridTemplateColumns={'255px'}
        h='100vh'
        gap='1'
        color='blackAlpha.900'
        fontWeight='bold'
      >
        <GridItem pl='2' area={'nav'}>
          <BlocoInformacaoDeAtedimento codePage={codePage} />
        </GridItem>
        <GridItem bg={'gray.100'} pl='2' area={'main'} sx={{
          height: '100vh'
        }}>
          <Card h={'90vh'} w={'100%'} ml={'-4px'} mt={10} p='2'>
            <CardHeader mb={-8}>
              <Flex spacing='4'>
                <Flex flex='1' gap='4' flexWrap='wrap'>
                  <Box mt={6}>
                    {informacao.status === 'Aguardando Atendimento' && (
                      <Text mt={-4} fontSize={18}> Por favor, em alguns segundos você será atendimento!</Text>
                    )}

                    {informacao.status === 'Em Trilha' && (
                      <Text mt={-4} fontSize={18}> Selecione uma trilha para iniciar o atendimento !</Text>
                    )}

                    {(informacao.status === 'Em Andamento' || informacao.status === 'Fechado') && (
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} w={'100%'} >
                        <Box mt={-10} ml={-4}>
                          <Badge p={2} colorScheme='whiteAlpha' bg={'blue.500'} borderRadius='0 10px 10px 10px'>
                            <Text fontSize={14}>Início de Atendimento: {formatDataHoraChat(informacao.primeiraInteracaoAgente)}</Text>
                            <Text fontSize={12}> Tempo de Atendimento: {informacao.tempoDeAtendimento === '00:00:00' ? '😀 Agora' : informacao.tempoDeAtendimento}</Text>
                          </Badge>
                        </Box>

                      </Box>
                    )}

                  </Box>
                </Flex>
              </Flex>
            </CardHeader>
            <CardBody mt={6} borderTop={'1px solid #ccc'} >
              {informacao.status === 'Em Trilha'
                ? (
                <>
                  <TrilhasAtendimento />
                </>
                  )
                : (
                <>
                  <Mensagens />
                </>
                  )}
              <Box justifyContent={'center'} display={'flex'} alignItems={'center'} gap={3}>
                <CaixaDeMensagem />
              </Box>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </Box>
  )
}
