/* eslint-disable react/prop-types */
/* eslint-disable new-cap */
import React, { useEffect, useRef } from 'react'
import { Button, Text, VStack, Table, Thead, Tbody, Tr, Th, Td, TableContainer, ModalFooter, Modal, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react'
import { useObtendoUsuariosAgendados } from '../hooks/useObtendoUsuariosAgendados'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { FaRegFilePdf } from 'react-icons/fa6'
import { SiMicrosoftexcel } from 'react-icons/si'

const ModalInfos = ({ isOpen, onClose, eventTitle, eventId, eventStatus }) => {
  const { data, refetch } = useObtendoUsuariosAgendados(eventId)
  const usuariosAgendados = data?.usuariosAgendados || []

  const tableRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      refetch()
    }
  }, [isOpen, refetch])

  const exportToExcel = () => {
    const ws = XLSX.utils.table_to_sheet(tableRef.current)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'UsuariosAgendados')
    XLSX.writeFile(wb, 'relatorio.xlsx')
  }

  const exportToPDF = () => {
    const doc = new jsPDF()
    doc.text(eventTitle, 10, 10)
    doc.autoTable({ html: tableRef.current })
    doc.save('relatorio.pdf')
  }

  return (
    <Modal size={usuariosAgendados.length > 0 ? 'full' : 'md'} isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>Detalhes do Evento</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight="bold" fontSize="xl">
            {eventTitle}
          </Text>
          <Text fontWeight="semibold" mt={-2} color="gray.600" fontSize="md" mb={4}>
            Status: {eventStatus}
          </Text>
          {usuariosAgendados.length > 0
            ? (
            <VStack spacing={4} align="stretch">
              <TableContainer>
                <Table size="sm" ref={tableRef}>
                  <Thead>
                    <Tr>
                      <Th textAlign="left">Nome</Th>
                      <Th textAlign="center">Celular</Th>
                      <Th textAlign="center">Campanha</Th>
                      <Th textAlign="center">Recrutador</Th>
                      <Th textAlign="center">Hora Inicial</Th>
                      <Th textAlign="center">Hora Final</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {usuariosAgendados.map((usuario, index) => (
                      <Tr key={index}>
                        <Td textAlign="left">{usuario.nome}</Td>
                        <Td textAlign="center">{usuario.celular}</Td>
                        <Td textAlign="center">{usuario.nomeCampanha}</Td>
                        <Td textAlign="center">{usuario.recrutador}</Td>
                        <Td textAlign="center">{usuario.horaInicial}</Td>
                        <Td textAlign="center">{usuario.horaFinal}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </VStack>
              )
            : (
            <Text>Nenhum usuário agendado nesse horário.</Text>
              )}
        </ModalBody>
        <ModalFooter>
          <Button mr={2} alignItems={'center'} justifyContent={'center'} gap={2} display={usuariosAgendados.length > 0 ? 'flex' : 'none'} colorScheme="green" onClick={exportToExcel}>
            <Text>Exportar para Excel</Text>
            <SiMicrosoftexcel size={20}/>
          </Button>
          <Button alignItems={'center'} justifyContent={'center'} gap={2} display={usuariosAgendados.length > 0 ? 'flex' : 'none'} colorScheme="red" onClick={exportToPDF}>
            <Text>Exportar para PDF</Text>
            <FaRegFilePdf size={20}/>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalInfos
