/* eslint-disable react/react-in-jsx-scope */
import { AddIcon } from '@chakra-ui/icons'
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, useDisclosure } from '@chakra-ui/react'
import { getFormByTipoPorcessamento } from './getFormByTipoProc'
import { useState } from 'react'
import { useCadastrarProcessamento } from '../hooks/Processamentos/useCreateProcessos'

export const DrawerCreate = () => {
  const payload = {}
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { UseRequestCadastrarProcesso } = useCadastrarProcessamento()
  const [tipoProcessamentoSelecionado, setTipoProcessamentoSelecionado] = useState(' ')

  const cadastrarProc = () => {
    const arrayCampos = document.querySelectorAll('#form-cadastro div > input,select,textarea')

    arrayCampos.forEach((htmlElement) => {
      if (htmlElement.name) {
        payload[htmlElement.name] = htmlElement.value
      }
    })
    document.getElementById('form-cadastro').reset()
    return UseRequestCadastrarProcesso(payload)
  }

  return (
    <>
      <Button leftIcon={<AddIcon />} colorScheme='whatsapp' onClick={onOpen}>Criar novo processo</Button>
      <Drawer isOpen={isOpen} onClose={onClose} size={'lg'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Cadastrar Processo</DrawerHeader>

          <DrawerBody>
            <form
              id='form-cadastro'
              onSubmit={(event) => {
                event.preventDefault()
              }}
            >
              {getFormByTipoPorcessamento({}, tipoProcessamentoSelecionado, setTipoProcessamentoSelecionado)}
            </form>
          </DrawerBody>

          <DrawerFooter borderTopWidth='1px'>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='whatsapp' onClick={() => { cadastrarProc() }}>Cadastrar</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
