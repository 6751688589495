/* eslint-disable react/prop-types */
import React from 'react'
import { format } from 'sql-formatter'
import './style/QueryFormat.css' // Importando o arquivo CSS com estilos

export default function QueryFormat ({ query }) {
  const palavrasChaveMySQL = ['SELECT', 'FROM', 'WHERE', 'AND', 'OR', 'ORDER BY', 'GROUP BY', 'LIMIT', 'INSERT INTO', 'UPDATE', 'DELETE', 'CREATE', 'ALTER', 'TABLE', 'INDEX', 'JOIN', 'INNER JOIN', 'LEFT JOIN', 'RIGHT JOIN', 'ON', 'AS', 'DISTINCT', 'HAVING', 'LIKE', 'BETWEEN', 'IN', 'IS NULL', 'IS NOT NULL']

  const queryFormatted = format(query, {
    language: 'mysql',
    tabWidth: 2,
    keywordCase: 'upper',
    linesBetweenQueries: 2
  })

  const highlightKeywords = (text) => {
    // Cria uma expressão regular com todas as palavras-chave
    const regex = new RegExp('\\b(' + palavrasChaveMySQL.join('|') + ')\\b', 'ig')

    // Divide o texto em tokens usando as palavras-chave como delimitadores
    const tokens = text.split(regex)

    // Aplica a coloração apenas às palavras-chave
    const coloredText = tokens.map((token, index) => (
      palavrasChaveMySQL.includes(token.toUpperCase())
        ? <span key={index} className="keyword">{token}</span>
        : token
    ))

    return coloredText
  }

  const formattedWithHighlights = highlightKeywords(queryFormatted)

  return (
    <div className="formatted-query-container">
      <pre className="formatted-query" style={{ height: '10rem', paddingInline: '10px', paddingBlock: '5px', overflowY: 'scroll' }}>
        {formattedWithHighlights}
      </pre>
    </div>
  )
}
