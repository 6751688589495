import { useQuery } from 'react-query'

import apiAdmin from '../../../../../../services/apiAdmin'

const useGetProcessamentos = () => {
  return useQuery(
    ['chat_useGetProcessamentos'],
    async () => {
      const response = await apiAdmin.get('/v1/api/admin/bibliotecaProcessos/obtendo')
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 60000,
      refetchInterval: false
    }
  )
}

export { useGetProcessamentos }
