import { useQuery } from 'react-query'
import apiAdmin from '../../../../services/apiAdmin'

const useObtendoInfoTabulacoes = (dataInicial, dataHoje, correspondecia) => {
  return useQuery(
    'use_ObtendoInfoTabulacoes',
    async () => {
      const response = await apiAdmin.get(`/v1/api/admin/dashboardPainelDeAtendimento/quantidade-tabulacoes-trilha?dataInicial=${dataInicial ?? new Date().toISOString().split('T')[0]}&dataFinal=${dataHoje ?? new Date().toISOString().split('T')[0]}${correspondecia === undefined ? '&correspondente[]=1&correspondente[]=0' : correspondecia}`)

      return response.data
    },
    {
      refetchOnWindowFocus: false,
      staleTime: false,
      refetchInterval: false
    }
  )
}

export { useObtendoInfoTabulacoes }
