import { Spinner, VStack, useColorMode } from '@chakra-ui/react'
import React, { Suspense } from 'react'
import CardsTabulacao from './components/cards/CardsTabulacao'
import TableTabulacao from './components/table/TableTabulacao'

export default function Tabulacao () {
  const { colorMode } = useColorMode()

  return (
    <VStack w={'100%'} bg={colorMode === 'light' ? 'white' : 'gray.600'}>
      <Suspense fallback={<Spinner />}>
          <CardsTabulacao />
        <TableTabulacao />
      </Suspense>
    </VStack>
  )
}
