/* eslint-disable react/prop-types */
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useCreateNovoMenu } from '../hooks/useCreateNovoMenu'

export default function CreateMenu ({ idDepartamento }) {
  console.log(idDepartamento)
  const [nomeMenu, setNomeMenu] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { UseRequestCreateNovoMenu } = useCreateNovoMenu()

  const handleCreateDepartamento = () => {
    setNomeMenu('')
    UseRequestCreateNovoMenu({
      idDepartamento,
      nome: nomeMenu
    })
    onClose()
  }

  return (
    <>
      <Button colorScheme='green' bg={'#229544'} onClick={onOpen}>
        Novo Menu
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody mt={4}>
            <Input
              value={nomeMenu}
              onChange={(e) => setNomeMenu(e.target.value)}
              placeholder="Nome do Menu"
            />
          </ModalBody>

          <ModalFooter>
            <Button w={'100%'} colorScheme='red' mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button w={'100%'} colorScheme='green' bg={'#229544'} onClick={handleCreateDepartamento}>
              Criar Menu
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
