/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/react'

import { atualizandoDadosAtendimento } from '../../../../../features/atendimento/atendimentoSlice'
import { useConfirmacaoAtendimento } from '../hooks/useConfirmacaoAtendimento'

function AlertDialogExample ({ isOpen, onClose, aceite }) {
  const { dadosAtendimento } = useSelector(state => state.atendimento)
  const { UseRequestConfirmacaoAtendimento, isSuccess } = useConfirmacaoAtendimento()
  const dispatch = useDispatch()
  const executandoConfirmacaoAtendimento = (idProtocolo, aceite) => {
    onClose()
    UseRequestConfirmacaoAtendimento(idProtocolo, aceite)
  }
  const cancelRef = React.useRef()

  useEffect(() => {
    if (isSuccess) {
      dispatch(atualizandoDadosAtendimento({
        status: aceite ? 'Em Andamento' : 'Cancelado'
      }))
    }
  }, [isSuccess, dispatch, aceite])

  return (
    <>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {aceite ? 'Iniciar Atendimento' : 'Recusar Atendimento'}
            </AlertDialogHeader>

            <AlertDialogBody>
              {aceite ? 'Deseja iniciar o atendimento ?' : 'Deseja recusar o atendimento ?'}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button bg={aceite ? '#229544' : 'red'} color='white'
                onClick={() => {
                  executandoConfirmacaoAtendimento(dadosAtendimento.idProtocolo, aceite)
                }} ml={3}>
                {aceite ? 'Iniciar' : 'Recusar'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default AlertDialogExample
