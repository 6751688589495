import React from 'react'

import { Stat, StatLabel, StatNumber, Box, SkeletonText, StatGroup } from '@chakra-ui/react'

import { useGetStatsAtendimentos } from '../hooks/useGetStatsAtendimentos'
function Stats () {
  const { isLoading, data } = useGetStatsAtendimentos()
  const listasStats = data?.stats || []

  const paletaCoresStats = {
    Aberto: 'orange.400',
    Cancelado: 'red',
    Fechado: 'green.400'
  }
  return (
    <>
      {!isLoading
        ? (
        <StatGroup>
          {listasStats.map(({ qtde, status, index }) => (
            <Stat key={index} bg={paletaCoresStats[status]} borderRadius='2xl' p={5} border='1px' textColor='White' borderColor='gray.200' mr={5}>
              <StatLabel fontWeight={'bold'}>{status}</StatLabel>
              <StatNumber>{qtde}</StatNumber>
            </Stat>
          ))}
        </StatGroup>
          )
        : (
        <>
          <Box padding='6' boxShadow='lg' bg='white'>
            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
          </Box>
        </>
          )}
    </>
  )
}

export default Stats
