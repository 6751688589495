import React from 'react'

import { Box, Grid, GridItem, useColorMode } from '@chakra-ui/react'

import TableTrilhaAgenteMes from './components/TableTrilhaAgenteMes'
import TableTrilhaAgenteStatus from './components/TableTrilhaAgenteStatus'
import TableTrilhaMes from './components/TableTrilhaMes'
import TableTrilhaUsuarioMes from './components/TableTrilhaUsuarioMes'

export default function Relatorio () {
  const { colorMode } = useColorMode()
  return (
    <>
      <Box mb={8} pb={8} bg={colorMode === 'light' ? 'white' : 'gray.500'} padding={'5px 15px'}>
        <Grid mb={6} gap={2} templateColumns='repeat(2, 1fr)' >
          <GridItem bg={colorMode === 'light' ? 'white' : 'gray.600'} rounded={'lg'} p={2} boxShadow={'lg'}>
            <TableTrilhaMes/>
          </GridItem>
          <GridItem bg={colorMode === 'light' ? 'white' : 'gray.600'} rounded={'lg'} p={2} boxShadow={'lg'}>
            <TableTrilhaAgenteMes/>
          </GridItem>
        </Grid>
        <Box mt={6} bg={colorMode === 'light' ? 'white' : 'gray.600'} rounded={'lg'} p={2} boxShadow={'lg'}>
          <TableTrilhaAgenteStatus/>
        </Box>
        <Box mb={'14'} mt={6} bg={colorMode === 'light' ? 'white' : 'gray.600'} rounded={'lg'} p={2} boxShadow={'lg'}>
          <TableTrilhaUsuarioMes/>
        </Box>
      </Box>
    </>
  )
}
