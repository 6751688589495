import { useMutation, useQueryClient } from 'react-query'
import apiAdmin from '../../../../../services/apiAdmin'

const useAlterarTrilha = () => {
  const queryClient = useQueryClient()

  const alterarTrilha = async (payload) => {
    console.log(payload, 'hook')
    const response = await apiAdmin.put('/v1/api/admin/chatbot-whatsapp/editar-trilha', payload, {
      headers: { 'x-access-token': localStorage.getItem('token') }
    })

    return response
  }

  const mutation = useMutation(alterarTrilha, {
    onSuccess: () => {
      queryClient.invalidateQueries('gerenciamento_AlterarTrilha')
    }
  })

  const UseRequestAlterarTrilha = (payload) => {
    mutation.mutate(payload)
    // window.location.reload()
  }

  return {
    UseRequestAlterarTrilha,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export { useAlterarTrilha }
