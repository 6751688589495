/* eslint-disable camelcase */
import React, { useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import ptBrLocale from '@fullcalendar/core/locales/pt-br'
import { useToast, useDisclosure } from '@chakra-ui/react'
import '../style/calendario.css'

import { useObtendoAgendaCalendario } from '../hooks/useObtendoAgendaCalendario'
import DrawerInfos from './Drawer'
import ModalInfos from './Modal'
import formatDataHoraChat from '../../../../helpers/dataHora/formatDataHoraChat'

const Calendar = () => {
  const toast = useToast()
  const { data } = useObtendoAgendaCalendario()
  const [selectedEvents, setSelectedEvents] = useState([])
  const { onOpen, onClose } = useDisclosure()
  const [isModalOpen, setModalOpen] = useState(false)
  const [eventTitle, setEventTitle] = useState('')
  const [eventStatus, setEventStatus] = useState('')
  const [eventId, setEventId] = useState('')
  const [dataEvento, setDataEvento] = useState('')
  const timeSlots = data?.map(event => {
    const usuarios_agendados = event.usuarios_agendados || 0
    const limite_agenda = event.limite_agenda || 0
    return {
      ...event,
      title: `${event.title} ${usuarios_agendados}/${limite_agenda}`,
      backgroundColor: '#229544',
      borderColor: '#229544'
    }
  }) || []

  const handleDateClick = (info) => {
    setDataEvento(info.dateStr)
    const dateStr = info.dateStr
    const eventsForTheDay = timeSlots.filter(event => {
      const eventDate = new Date(event.start).toISOString().slice(0, 10)
      return eventDate === dateStr
    })

    if (eventsForTheDay.length > 0) {
      const appointmentsForTheDay = timeSlots.filter(a => a.dataCompromisso === dateStr)
      const combinedEvents = eventsForTheDay.map(event => ({
        ...event,
        ...appointmentsForTheDay.find(a => a.configId === event.configId)
      }))

      setSelectedEvents(combinedEvents)
      onOpen()
    } else {
      toast({
        title: 'MAIS VALOR INFORMA!',
        description: `Nenhum evento no dia ${formatDataHoraChat(dateStr)}`,
        status: 'warning',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }

  const handleEventClick = (info) => {
    setEventStatus(info.event.extendedProps.status)
    setEventTitle(info.event.title)
    setEventId(info.event.extendedProps.idAgendaCompromisso)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <div className="main">
      <div className="calendar-container">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'today,prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
          }}
          locale={ptBrLocale}
          events={timeSlots}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          height="90%"
          dayMaxEventRows={3}
        />
      </div>
      {dataEvento && <DrawerInfos dateStr={dataEvento} onClose={onClose} eventsData={selectedEvents} />}
      {eventId && <ModalInfos eventId={eventId} isOpen={isModalOpen} onClose={closeModal} eventTitle={eventTitle} eventStatus={eventStatus} />}
    </div>
  )
}

export default Calendar
