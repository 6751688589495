import { Box, Button, Flex, Select, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorMode } from '@chakra-ui/react'
import React, { memo, useState } from 'react'
import { useObtendoInfoTabulacoes } from '../../hooks/useObtendoInfoTabulacoes'
import { FcClock } from 'react-icons/fc'
import { FaRobot } from 'react-icons/fa6'
import ExcelButton from '../../../../../layouts/components/ExcelButton'
import FiltroTabulacao from '../filtro/FiltroTabulacao'

function formatarData (data) {
  const dataObj = new Date(data)

  const dia = String(dataObj.getDate()).padStart(2, '0')
  const mes = String(dataObj.getMonth() + 1).padStart(2, '0')
  const ano = dataObj.getFullYear()

  return `${dia}/${mes}/${ano}`
}

function TableTabulacao () {
  const { colorMode } = useColorMode()
  const [filtroSupervisor, setFiltroSupervisor] = useState(null)
  const [filtroParceiro, setFiltroParceiro] = useState(null)
  const { data, isLoading } = useObtendoInfoTabulacoes()
  const itemsPerPage = 10
  const [currentPage, setCurrentPage] = useState(0)

  const changePage = (page) => {
    setCurrentPage(page)
  }

  const filteredData = data
    ? data.listaTabulacoes.filter((info) => {
      if (
        (!filtroSupervisor || info.supervisor === filtroSupervisor) &&
        (!filtroParceiro || info.parceiro === filtroParceiro)
      ) {
        return true
      }
      return false
    })
    : []

  const paginatedFilteredData = filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)

  const totalPages = Math.ceil(filteredData.length / itemsPerPage)

  const hasFilteredData = filteredData.length > 0

  const uniqueSupervisors = data ? [...new Set(data.listaTabulacoes.map((info) => info.supervisor))] : []
  const uniquePartners = data ? [...new Set(data.listaTabulacoes.map((info) => info.parceiro))] : []

  return (
    <>
      {isLoading
        ? (
          <Box display="flex" flexDir={'column'} justifyContent="center" alignItems="center" mt={4}>
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="green.500" size="xl" />
            <Text>Carregando Dados de Tabulação...</Text>
          </Box>)
        : (
          <>
            <Flex gap={4} paddingInline={2} alignItems={'center'} justifyContent={'flex-end'} w={'100%'}>
              <Box w={'15%'}>
                <Text fontWeight={'bold'}>Filtros sobre a tabela:</Text>
              </Box>

              <Box gap={1} w={'85%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Select variant='filled' placeholder='Supervisores' onChange={(e) => setFiltroSupervisor(e.target.value)}>
                  {uniqueSupervisors.sort().map((supervisor, index) => (<option key={index} value={supervisor}>{supervisor}</option>))}
                </Select>
                <Select variant='filled' placeholder='Parceiros' onChange={(e) => setFiltroParceiro(e.target.value)}>
                  {uniquePartners.sort().map((parceiro, index) => (<option key={index} value={parceiro}>{parceiro}</option>))}
                </Select>
              </Box>
              <FiltroTabulacao />
            </Flex>
            <TableContainer h={'55vh'} overflowY={'scroll'} paddingInline={2}>
              <Table textTransform={'uppercase'} variant={'striped'} size='sm' bg={'transparent'} fontSize={12} fontWeight={'bold'}>
                <Thead w={'max-content'} pos={'sticky'} top={0} bg={colorMode === 'light' ? 'gray.300' : 'gray.800'} color={'black'} fontWeight={'bold'} zIndex={2}>
                  <Tr w={'max-content'} fontSize={12}>
                    <Th>Parceiro</Th >
                    <Th w={'max-content'} textAlign={'center'}>Agente</Th>
                    <Th w={'max-content'} textAlign={'center'}>Supervisor</Th>
                    <Th w={'max-content'} textAlign={'center'}>Trilha</Th>
                    <Th w={'max-content'} textAlign={'center'}>Tabulacao</Th>
                    <Th w={'max-content'} textAlign={'center'}>Corresponde</Th>
                    <Th w={'max-content'} textAlign={'center'}>Auto <br /> Atendimento</Th>
                    <Th w={'max-content'} textAlign={'center'}>Opção <br /> Chatbot</Th>
                    <Th w={'max-content'} textAlign={'center'}>Criação <br /> Proposta</Th>
                  </Tr >
                </Thead >
                <Tbody>
                  {data && hasFilteredData
                    ? (
                        paginatedFilteredData.map((info, index) => (
                        <Tr fontSize={12} key={index}>
                          <Td fontSize={12} maxW={'250px'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} >{info.parceiro}
                            <br />
                            <Text display={'flex'} color={'gray.800'}><FcClock fontSize={16} />{formatarData(info.dataAtendimento)}</Text>
                          </Td>
                          <Td fontSize={12} maxW={'150px'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} textAlign={'center'}>{info.agente}</Td>
                          <Td fontSize={12} maxW={'150px'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} >{info.supervisor}</Td>
                          <Td fontSize={12} maxW={'150px'} whiteSpace={'wrap'} overflow={'hidden'} textOverflow={'ellipsis'} textAlign={'center'}>#{info.trilha}</Td>
                          <Td maxW={'120px'} fontSize={12} whiteSpace={'wrap'} textAlign={'center'}>{info.tabulacao}</Td>
                          <Td fontSize={12} maxW={'50px'} whiteSpace={'wrap'} overflow={'hidden'} textOverflow={'ellipsis'} textAlign={'center'} >{info.corresponde}</Td>
                          <Td fontSize={12} >
                            <Box mr={-12} ml={8}>
                              {info.tabulacao_auto_atendimento === 'Sim' ? <FaRobot color='green' fontSize={24} /> : <FaRobot color='red' fontSize={24} />}
                            </Box>
                          </Td>
                          <Td fontSize={12} maxW={'200px'} whiteSpace={'wrap'} overflow={'hidden'} textOverflow={'ellipsis'} textAlign={'center'} >{info.opcao}</Td>
                          <Td fontSize={12} textAlign={'center'}>{info.dataProposta}</Td>
                        </Tr>
                        ))
                      )
                    : (
                      <Tr>
                        <Td colSpan={9} textAlign="center">
                          Não há dados com esse filtro.
                        </Td>
                      </Tr>
                      )}
                </Tbody>
              </Table >
            </TableContainer >

            {data && data.listaTabulacoes.length > itemsPerPage && (
              <Flex w={'100%'} justifyContent={'space-between'}>
                <Box w={'350px'} ml={4} mt={-1} py={1} mr={'-36'} display="flex" justifyContent={'center'} alignItems={'center'} bg={colorMode === 'light' ? 'gray.200' : 'gray.600'}>
                  {currentPage !== 0
                    ? (
                    <Button
                      cursor="pointer"
                      padding={2}
                      onClick={() => changePage(currentPage - 1)}
                    >
                      Anterior
                    </Button>
                      )
                    : (
                    <Button
                      cursor="not-allowed"
                      padding={2}
                    >
                      Anterior
                    </Button>
                      )}

                  {[...Array(totalPages).keys()].filter(pageNumber => pageNumber >= currentPage - (currentPage % 5) && pageNumber < currentPage - (currentPage % 5) + 5).map((pageNumber) => (
                    <Text
                      key={pageNumber}
                      cursor="pointer"
                      padding={2}
                      onClick={() => changePage(pageNumber)}
                      border={currentPage === pageNumber ? '1px solid green' : 'none'}
                      borderRadius="md"
                      marginRight={2}
                      bg={currentPage === pageNumber ? 'green' : 'none'}
                      color={currentPage === pageNumber ? 'white' : 'black'}
                    >
                      {pageNumber + 1}
                    </Text>
                  ))}

                  {currentPage !== totalPages - 1 && totalPages !== 0
                    ? (
                    <Button
                      cursor="pointer"
                      padding={2}
                      onClick={() => changePage(currentPage + 1)}
                    >
                      Próximo
                    </Button>
                      )
                    : (
                    <Button
                      cursor="not-allowed"
                      padding={2}
                    >
                      Próximo
                    </Button>
                      )}
                </Box>
                <ExcelButton data={filteredData} name={'Tabulacoes'} />
              </Flex>
            )}

          </>
          )}
    </>
  )
}

export default memo(TableTabulacao)
