/* eslint-disable react/prop-types */
import { Card, Input, Text, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'
import QueryFormat from './QueryFormat'

export const CardExibicaoInput = ({ ValorCampo, LabelCampo, disabled, name }) => {
  const [inputValue, setinputValue] = useState(ValorCampo)

  return (
    <>
      <Card w={'99%'} maxH={'6rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
        <Text fontWeight={600} textTransform={'capitalize'}>
          {LabelCampo}
        </Text>
        <Input name={name} disabled={disabled || false} value={inputValue} type={'number'} onChange={(event) => { setinputValue(event.target.value) }} />
      </Card>
    </>
  )
}

export const CardExibicaoTextArea = ({ ValorCampo, LabelCampo }) => {
  const [TextareaValue, setTextareaValue] = useState(ValorCampo || ' ')
  const [visualizarQuery, setVisualizarQuery] = useState(false)

  const changeQuery = (value) => {
    setTextareaValue(value)
  }

  const exibiQuery = (query) => {
    return <QueryFormat query={query} />
  }
  return (
    <>
      <Card w={'99%'} maxH={'20rem'} display={'flex'} justifyContent={'center'} alignContent={'center'} boxShadow={'lg'} p={3} m={2}>
        <Text fontWeight={600} textTransform={'capitalize'}>
          {LabelCampo}
        </Text>
        {visualizarQuery && TextareaValue !== '' && exibiQuery(TextareaValue)}
        <Textarea name={LabelCampo} value={TextareaValue}
          onChange={(event) => { changeQuery(event.target.value) }}
          onBlur={() => { setVisualizarQuery(true) }}
          onFocus={() => { setVisualizarQuery(false) }} />
      </Card>
    </>
  )
}
