import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useCreateNovoDepartamento } from '../hooks/useCreateNovoDepartamento'

export default function CreateDepartamento () {
  const [departamentoNome, setDepartamentoNome] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { UseRequestCreateNovoDepartamento } = useCreateNovoDepartamento()

  const handleCreateDepartamento = () => {
    UseRequestCreateNovoDepartamento({ nome: departamentoNome })
    onClose()
  }

  return (
    <>
      <Button colorScheme='green' bg={'#229544'} onClick={onOpen}>
        Novo Departamento
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody mt={4}>
            <Input
              value={departamentoNome}
              onChange={(e) => setDepartamentoNome(e.target.value)}
              placeholder="Nome do Departamento"
            />
          </ModalBody>

          <ModalFooter>
            <Button w={'100%'} colorScheme='red' mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button w={'100%'} colorScheme='green' bg={'#229544'} onClick={handleCreateDepartamento}>
              Criar Departamento
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
