import React, { useState } from 'react'
import { IoSendSharp } from 'react-icons/io5'
import { useSelector } from 'react-redux'

import { Button, Flex, Grid, Icon, Spinner, Tag, TagLabel, VStack } from '@chakra-ui/react'

import { useEnviarTrilaSelecionada } from '../hooks/useEnviarTrilaSelecionada'
import { useGetobtendoTrilha } from '../hooks/useGetObtendoTrilhas'
import DialogAbrirOutros from './DialogAbirTrilhaOutros'

export default function TrilhasAtendimento () {
  const { UseRequestuseEnvioDeTrilha, isLoading } = useEnviarTrilaSelecionada()
  const { informacao } = useSelector((state) => state.blocoInformacaoAtendimento)
  const { data } = useGetobtendoTrilha()
  const trilhas = data || []

  const [selectedTag, setSelectedTag] = useState(null)
  const [showAlert, setShowAlert] = useState(false)

  const handleTagClick = (trilha) => {
    if (!trilha.id) {
      setShowAlert(true)
      setSelectedTag(trilha.id)
      return
    }

    setSelectedTag(trilha.id)
    setShowAlert(false)
  }

  const enviarTrilhaSelecionada = () => {
    UseRequestuseEnvioDeTrilha(
      {
        idTrilha: selectedTag,
        codePage,
        idProtocolo: informacao.idProtocolo
      }
    )
  }

  const handleTrilhaCreated = () => {
    setShowAlert(false)
  }

  const search = window.location.search
  const params = new URLSearchParams(search)
  const codePage = params.get('codePage')

  return (
    <VStack ml={-4} w={'100%'} alignItems={'left'} justifyContent={'left'}>
      <Flex p={4} justifyContent={'center'} alignItems={'center'} w={'max-content'}>
        <Grid alignItems={'center'} justifyContent={'center'} templateColumns='repeat(2, 1fr)' gap={6}>
          {trilhas.map((tags) => (
            <Tag
              textTransform={'capitalize'}
              _hover={{ color: 'green' }}
              cursor={'pointer'}
              w={'max-content'}
              size={'lg'}
              key={tags.id}
              variant='outline'
              colorScheme={selectedTag === tags.id ? 'green' : 'gray'}
              onClick={() => handleTagClick(tags)}>
              <TagLabel >#{tags.nome}</TagLabel>
            </Tag>
          ))}
        </Grid>
      </Flex>
      {showAlert && <DialogAbrirOutros onClose={() => setShowAlert(false)} onTrilhaCreated={handleTrilhaCreated} />}
      {selectedTag &&
        <Button
          onClick={() => enviarTrilhaSelecionada()}
          _hover={{
            bg: '#229544'
          }}
          fontSize={18}
          gap={2}
          justifyContent={'space-around'}
          alignItems={'center'}
          display={'flex'}
          bottom={8}
          right={'2'}
          position={'fixed'}
          textTransform={'uppercase'}
          w={'200px'}
          color={'white'}
          bg={'green'}
        >
          {!isLoading ? 'Enviar' : 'Enviando'}
          {isLoading ? <Spinner /> : <Icon mr={-4} fontSize={24} as={IoSendSharp} />}
        </Button>
      }
    </VStack>
  )
}
