import React, { memo } from 'react'

import { Box, Skeleton, SkeletonCircle, useColorMode } from '@chakra-ui/react'

function SkeletonSidebarContatos () {
  const { colorMode } = useColorMode()
  return (
    <>
      <Box padding='6' bg={colorMode === 'light' ? 'white' : 'gray.500'}>
        <SkeletonCircle size='10' />
        <Skeleton height='3px' mt={2} />
        <Skeleton height='3px' mt={2} />
        <Skeleton height='3px' mt={2} />
      </Box>
      <Box padding='6' bg={colorMode === 'light' ? 'white' : 'gray.500'}>
        <SkeletonCircle size='10' />
        <Skeleton height='3px' mt={2} />
        <Skeleton height='3px' mt={2} />
        <Skeleton height='3px' mt={2} />
      </Box>
      <Box padding='6' bg={colorMode === 'light' ? 'white' : 'gray.500'}>
        <SkeletonCircle size='10' />
        <Skeleton height='3px' mt={2} />
        <Skeleton height='3px' mt={2} />
        <Skeleton height='3px' mt={2} />
      </Box>
      <Box padding='6' bg={colorMode === 'light' ? 'white' : 'gray.500'}>
        <SkeletonCircle size='10' />
        <Skeleton height='3px' mt={2} />
        <Skeleton height='3px' mt={2} />
        <Skeleton height='3px' mt={2} />
      </Box>
      <Box padding='6' bg={colorMode === 'light' ? 'white' : 'gray.500'}>
        <SkeletonCircle size='10' />
        <Skeleton height='3px' mt={2} />
        <Skeleton height='3px' mt={2} />
        <Skeleton height='3px' mt={2} />
      </Box>
      <Box padding='6' bg={colorMode === 'light' ? 'white' : 'gray.500'}>
        <SkeletonCircle size='10' />
        <Skeleton height='3px' mt={2} />
        <Skeleton height='3px' mt={2} />
        <Skeleton height='3px' mt={2} />
      </Box>
    </>
  )
}

export default memo(SkeletonSidebarContatos)
