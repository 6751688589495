import { useMutation } from 'react-query'

import api from '../../../services/api'

const useGetConfirmacaoProposta = () => {
  const confirmarProposta = async (payload) => {
    const response = await api.post('/v1/api/public/confirmacaoProposta', payload)

    if (response.status === 200) {
      mutation.isSuccess = true
      mutation.isError = false
    }

    if (response.status !== 200) {
      mutation.isSuccess = false
      mutation.isError = true
    }
  }

  const mutation = useMutation(confirmarProposta)

  const UseRequestConfirmacaoProposta = (token, confirme, motivo) => {
    mutation.mutate({ token, confirme, motivo })
  }
  return {
    UseRequestConfirmacaoProposta,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export {
  useGetConfirmacaoProposta
}
