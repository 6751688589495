/* eslint-disable multiline-ternary */
import React, { useState, useRef, useEffect } from 'react'
import { BsSearch } from 'react-icons/bs'
import { FcEndCall } from 'react-icons/fc'
import { useQueryClient } from 'react-query'
import { useSelector, useDispatch } from 'react-redux'

import { Box, Icon, HStack, Text, Avatar, Input, Button, Badge, Image, useDisclosure, Flex, Tooltip, Textarea, useColorMode } from '@chakra-ui/react'

import init from '../../../../../assets/init.png'
import selecaoTag from '../../../../../assets/selecaoTag.png'
import wellcome from '../../../../../assets/wellcome.png'
import { obtendoDadosAtendimento } from '../../../../../features/atendimento/atendimentoSlice'
import checkPageStatus from '../../../../../helpers/verificaNotificacao'
import { useManipulacaoDeMensagem } from '../../../../../layouts/provider/ManipulacaoDeMensagens.js'
import socket from '../../../../../services/socket-config.js'
import { useEnvioDeMensagem } from '../hooks/useEnvioDeMensagem'
import { useObtendoMensagensAtendimentos } from '../hooks/useObtendoMensagensAtendimentos'
import useUploadArquivo from '../hooks/useUploadDeArquivos'
import ChatBalloon from './BaloesMensagens'
import DialogCorfimarAtendimento from './DialogCorfimarAtendimento'
import DialogEncerrarAtendimento from './DialogEncerrarAtendimento'
import RoadMapChatbot from './RoadMapChatbot'
import SkeletonMessagensChat from './SkeletonMensagensChat'
import TransferirAtendimento from './TransferirAtendimento'
import UploadArquivos from './UploadDeArquivos'

function ChatMensagens () {
  const { colorMode } = useColorMode()
  const { handleMenuItem } = useManipulacaoDeMensagem()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { dadosAtendimento, listaDeAtendimentos } = useSelector((state) => state.atendimento)
  const [inputText, setInputText] = useState('')
  const [pastedImage, setPastedImage] = useState(null)
  const [file, setFile] = useState(null)
  const { isLoading, data, isError } = useObtendoMensagensAtendimentos(dadosAtendimento.idProtocolo)
  const [showInput, setShowInput] = useState(false)
  const [setInputWidth] = useState(0)
  const [aceite, setAceite] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const baseScroll = useRef(null)
  const [loadScroll, setloadScroll] = useState(false)
  const queryClient = useQueryClient()
  const inputRef = useRef(null)
  const mensagens = data?.mensagens || []
  const statusAtendimentos = data?.statusAtendimento || []
  const [resize] = useState('none')
  const dispatch = useDispatch()
  const { enviarArquivo } = useUploadArquivo()
  const { UseRequestuseEnvioDeMensagem } = useEnvioDeMensagem()
  const idProtocolo = dadosAtendimento.idProtocolo

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData || window.Clipboard
    if (clipboardData) {
      const items = Array.from(clipboardData.items)
      const imageItem = items.find((item) => item.type.indexOf('image') !== -1)

      if (imageItem) {
        e.preventDefault()
        const file = imageItem.getAsFile()
        const imageURL = URL.createObjectURL(file)
        setFile(file)
        setPastedImage(imageURL)
      }
    }
  }

  const handleImageLoad = async () => {
    if (pastedImage !== null) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('idProtocolo', dadosAtendimento.idProtocolo)
      await enviarArquivo(formData)
    }
  }

  const fecharModalDeEnvioDeImagem = () => {
    setPastedImage(null)
  }

  useEffect(() => {
    if (showInput) {
      inputRef.current.focus()
    }
  }, [showInput])

  useEffect(() => {
    if (isError) {
      queryClient.removeQueries('atendimento_useObtendoMensagensAtendimentos')
      queryClient.invalidateQueries('atendimento_useObtendoMensagensAtendimentos')

      queryClient.removeQueries('atendimento_useObtendoAtendimentos')
      queryClient.invalidateQueries('atendimento_useObtendoAtendimentos')
      dispatch(obtendoDadosAtendimento({
        dadosAtendimento: {}
      }))
    }
  }, [isError, queryClient, dispatch])

  const createdRoomsRef = useRef(new Set())
  useEffect(() => {
    const handleVisibilityChange = () => {
      for (const atendimento of listaDeAtendimentos) {
        const roomKey = `${atendimento.idProtocolo}-${atendimento.idUsuario}`
        if (!createdRoomsRef.current.has(roomKey)) {
          createdRoomsRef.current.add(roomKey)
          if (document.hidden) {
            socket.emit('joinRoom', roomKey)
            socket.on(`notificacao-painel-atendimento-${roomKey}`, (notification) => {
              checkPageStatus(notification.message, atendimento)
            })
          }
        }
      }
    }

    // Adicionar o ouvinte de visibilidade
    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Remover o ouvinte ao desmontar o componente
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  })

  const handleFocus = () => {
    setShowInput(true)
    setInputWidth(30)
  }

  const handleCloseInput = () => {
    setShowInput(false)
    setInputWidth(30)
  }

  useEffect(() => {
    if (loadScroll) {
      const element = baseScroll.current
      element.scrollTop = element.scrollHeight
    }
  }, [loadScroll])

  const handleEnterPress = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault()
      setInputText(inputText + '\n')
    } else if (e.key === 'Enter') {
      const payload = {
        mensagem: inputText,
        direcao: 'out',
        idProtocolo: dadosAtendimento.idProtocolo
      }

      UseRequestuseEnvioDeMensagem(payload)
      setInputText('')
      setloadScroll(true)
    }
  }

  const statusAtendimento = {
    'Aguardando Atendimento': 'yellow',
    'Em Andamento': 'green',
    Fechado: 'gray',
    'Cancelado (Por Falta de Interação)': 'red'
  }

  const isDadosAtendimentoEmpty = Object.keys(dadosAtendimento).length === 0
  const isStatusCancelado = dadosAtendimento.status === 'Cancelado'

  if (isDadosAtendimentoEmpty || isStatusCancelado) {
    return (
      <>
        <Box mt={20} bg={colorMode === 'light' ? 'white' : 'gray.600'} display={'flex'} alignItems={'center'} flexDir={'column'} justifyContent={'center'}>
          <Image w={'450px'} src={wellcome} />
          <Text fontWeight={'bold'} fontSize={'22'}>Bem-vindo ao nosso Sistema de Mensagens para Dúvidas Operacionais!</Text>
          <Text fontWeight={'bold'} fontSize={'18'}>Para iniciar os atendimentos selecione um dos usuarios ao lado esquerdo da tela.</Text>
        </Box>
      </>
    )
  }

  const handleChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const filteredElements = mensagens.filter((element) =>
    element.mensagem.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <Box
      zIndex={9}
      bg={colorMode === 'light' ? 'white' : 'gray.500'}
      borderLeft={'1px solid #ccc'}
      display={'flex'}
      flexDir={'column'} fontWeight={'medium'} h={'84vh'} p={4} justifyContent='space-between' >
      <Box bg={colorMode === 'light' ? 'white' : 'gray.500'} pos={'relative'} zIndex={9} mb={'-12'} display={'flex'} alignItems={'center'} fontWeight={'bold'} textTransform={'uppercase'} fontSize={'18px'}>
        <Avatar src={`${dadosAtendimento.foto}`} > </Avatar>
        <Box zIndex={9}>
          <Text fontSize={14} color={'black'} w={'95%'} ml={2}>{dadosAtendimento.usuario}</Text>
          <Box display={'flex'}>
            <Text fontSize={'12px'} ml={2}>{dadosAtendimento.tipoParceiro}</Text>
            <Text textTransform={'capitalize'} fontSize={'12px'} ml={2}>{dadosAtendimento.trilha}</Text>
            <Badge ml={2} colorScheme={statusAtendimento[statusAtendimentos]} fontSize={12}>{statusAtendimentos} </Badge>
            {mensagens[0] &&
              <Text fontSize={'12px'} ml={2}>Início do Atendimento: {mensagens[0].data.slice(11, 16)}</Text>
            }
          </Box>
        </Box>
      </Box>
      {dadosAtendimento.status === 'Em Andamento' || dadosAtendimento.status === 'Fechado' || dadosAtendimento.status === 'Cancelado (Por Falta de Interação)' ? (
        <>
          <Box zIndex={9} mt={-2} bg={colorMode === 'light' ? showInput ? 'white' : '' : !showInput ? '' : 'gray.500'} h={'90px'} w={'100%'}
            display={'flex'} justifyContent={'end'} gap={4}>
            {showInput
              ? (
                <Input
                  ref={inputRef}
                  value={searchTerm}
                  ml={'-6'}
                  width={'79%'}
                  h={'40px'}
                  placeholder={'Buscar Mensagens...'}
                  bg={'gray.100'}
                  pl={4}
                  onBlur={handleCloseInput}
                  variant='unstyled'
                  transform={'initial'}
                  transition='10s'
                  onChange={handleChange}
                />
                )
              : (
                <Button onClick={handleFocus} zIndex={9}>
                  <Icon as={BsSearch} />
                </Button>
                )}

            <Tooltip hasArrow label='Encerrar Atendimento' placement='top-start'>
              <Button display={dadosAtendimento.status === 'Fechado' ? 'none' : 'flex'}
                onClick={() => {
                  onOpen()
                }}
                color={'white'} _hover={{
                  boxShadow: 'lg',
                  bg: 'red.200'
                }}>
                <Icon transform='rotate(270deg)' as={FcEndCall} />
              </Button>
            </Tooltip>
            {dadosAtendimento.status === 'Em Andamento' &&
              <TransferirAtendimento key={idProtocolo} idProtocolo={idProtocolo} />
            }
          </Box>
          <RoadMapChatbot key={idProtocolo} idProtocolo={idProtocolo} />

          <Box borderTop={'1px solid #ccc'} pt={4} w={'100%'} my={4} h={'100%'}
            overflow={handleMenuItem ? 'hidden' : 'scroll'} ref={baseScroll}>
            {/* componente de Baloes de Mensagens */}
            {isLoading
              ? <SkeletonMessagensChat />
              : filteredElements.length > 0
                ? (
                    filteredElements.map((message) => (
                    <ChatBalloon
                      searchTerm={searchTerm}
                      key={message.id}
                      text={message.mensagem}
                      sender={message.direcao}
                      perfil={message.foto}
                      hora={message.data.slice(11, 16)}
                      name={message.autor}
                      mimetype={message.mimetype}
                      originalname={message.originalname}
                      mensagemLida={message.mensagemLida}
                      idProtocolo={dadosAtendimento.idProtocolo}
                      idMensagem={message.id}
                      ocultarMensagem={message.ocultarMensagem}
                      mensagemRespondida={message?.mensagemRespondida}
                      mensagensRespondida_direcao={message?.mensagensRespondida_direcao}
                      mensagemRespondida_mimetype={message?.mensagemRespondida_mimetype}
                      mensagemRespondida_originalname={message?.mensagemRespondida_originalname}
                    />
                    ))
                  )
                : (
                  <Box display={'flex'} flexDir={'column'} alignItems={'center'} justifyContent={'center'} h={'100%'}>
                    <Text mt={4} fontWeight={'bold'} fontSize={'18px'}>Nenhuma mensagem encontrada!</Text>
                  </Box>
                  )
            }
            {/* componente de Baloes de Mensagens */}
          </Box>
          <HStack bg={colorMode === 'light' ? 'white' : 'gray.500'} h={83} display={dadosAtendimento.status === 'Fechado' || dadosAtendimento.status === 'Cancelado (Por Falta de Interação)' ? 'none' : 'flex'} >
            <UploadArquivos />
            <Textarea
              ref={inputRef}
              fontWeight={'bold'}
              variant='unstyled'
              paddingInline={'10px'}
              borderRadius={'20px'}
              bg={'gray.300'}
              resize={resize}
              w={'62%'} right={2}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyDown={handleEnterPress}
              onPaste={handlePaste} pos={'absolute'} bottom={5}
            />
          </HStack>
          {pastedImage && (
            <Box
              mr={2}
              bottom={0} justifyContent={'center'}
              bg={'gray.100'} padding={2} alignItems={'center'}
              gap={2} flexDir={'column'} zIndex={99} h='80vh' display={'flex'} pos={'absolute'}>

              <Image
                src={pastedImage}
                alt='Imagem colada'
                maxW='90%'
              />
              <Flex mt={4} gap={6}>
                <Button onClick={fecharModalDeEnvioDeImagem}
                  w={'150px'}
                  color={'black'}
                  bg={'gray.300'}
                >Cancelar
                </Button>
                <Button onClick={() => {
                  handleImageLoad()
                  fecharModalDeEnvioDeImagem()
                }}
                  w={'150px'}
                  color={'white'}
                  bg={'#229544'}
                >Enviar
                </Button>
              </Flex>
            </Box>
          )}
          <DialogEncerrarAtendimento isOpen={isOpen} onClose={onClose} />
        </>
      ) : dadosAtendimento.status === 'Aguardando Atendimento'
        ? (
          <>
            <Box display={'flex'} flexDir={'column'} alignItems={'center'} justifyContent={'center'}>
              <Image mt={10} mb={-8} width={'400px'} src={init} />
              <Text mb={6} fontSize={'18px'} >Painel de atendimento Mais Valor</Text>
              <Text w={'650px'} textAlign={'center'} color={'black'} mb={28} fontSize={'20'} fontWeight={'bold'}> {dadosAtendimento.usuario} necessita de ajuda, deseja iniciar um atendimento ?</Text>

              <Box mt={-20} w={'100%'} display={'flex'} justifyContent={'center'} gap={4}>
                <Button
                  onClick={() => {
                    onOpen()
                    setAceite(true)
                  }}
                  ml={4} bg={'#229544'} color={'white'} _hover={{
                    boxShadow: 'lg',
                    bg: 'green'
                  }}

                >
                  Iniciar Atendimento
                </Button>
                <Button bg={'red.500'} color={'white'} _hover={{
                  boxShadow: 'lg',
                  bg: 'red'
                }}
                  onClick={() => {
                    onOpen()
                    setAceite(false)
                  }}
                >
                  Recusar Atendimento
                </Button>
              </Box>

              <DialogCorfimarAtendimento isOpen={isOpen} onClose={onClose} aceite={aceite} />
            </Box>
          </>
          )
        : dadosAtendimento.status === 'Em Trilha'
          ? (
            <>
              <Box display={'flex'} flexDir={'column'} alignItems={'center'} justifyContent={'center'}>
                <Image mt={10} mb={-8} width={'400px'} src={selecaoTag} />
                <Text mt={4} mb={6} fontSize={'18px'} >Painel de atendimento Mais Valor</Text>
                <Text w={'650px'} textAlign={'center'} color={'black'} mb={28} fontSize={'20'} fontWeight={'bold'}> {dadosAtendimento.usuario} esta em trilha, aguarde enquanto um pré atendimento é selecionado !</Text>

                <DialogCorfimarAtendimento isOpen={isOpen} onClose={onClose} aceite={aceite} />
              </Box>
            </>
            )
          : null}

    </Box>
  )
}

export default React.memo(ChatMensagens)
