import { useMutation } from 'react-query'
import { useState } from 'react'
import api from '../../api/api'

const useStartInstancia = () => {
  const [instanceStart, setIntanceStart] = useState(null)

  const startInstancia = async (payload) => {
    const response = await api.post('/api/instances/startInstance', payload)
    return response.data
  }

  const mutation = useMutation(startInstancia, {
    onSuccess: (data) => {
      setIntanceStart(data)
    },
    onError: (error) => {
      console.error('onError:', error)
    }
  })

  const UseRequestStartInstancia = (payload) => {
    mutation.mutate(payload)
  }

  return {
    UseRequestStartInstancia,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    instanceStart
  }
}

export { useStartInstancia }
