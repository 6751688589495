import React, { useState } from 'react'
import { Box, Flex, Heading, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { useGetStatus } from './hooks/useGetStatus'
import { IoCheckmarkDone } from 'react-icons/io5'
import { FcAlarmClock, FcApproval, FcCancel, FcExpired, FcSearch } from 'react-icons/fc'

export default function ConfirmacaoPropostasWpp () {
  const [showDetails, setShowDetails] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const { data } = useGetStatus()
  const resultStatusAcompanhamentos = data || []
  const resultListaStatusAcompanhamentos = data || []

  const statusAcompanhamentos = resultStatusAcompanhamentos.statusAcompanhamentos || []
  const firstStatus = statusAcompanhamentos[0] || {}

  const filteredStatusList = resultListaStatusAcompanhamentos.listaStatusAcompanhamentos?.filter((item) => {
    if (showDetails === 'aguardando_confirmacao_whats') {
      return item.status === 'AGUARDANDO CONFIRMACAO WHATS'
    }
    if (showDetails === 'confirmacao_whats_aprovada') {
      return item.status === 'CONFIRMACAO WHATS APROVADA'
    }
    if (showDetails === 'confirmacao_whats_reprovada') {
      return item.status === 'CONFIRMACAO WHATS REPROVADA'
    }
    if (showDetails === 'confirmacao_whatsapp_nao_realizada') {
      return item.status === 'CONFIRMACAO WHATSAPP NAO REALIZADA'
    }
    return true
  }).filter((item) => {
    if (searchTerm === '') {
      return true
    }
    const lowercasedSearchTerm = searchTerm.toLowerCase()
    return (
      item.nome.toLowerCase().includes(lowercasedSearchTerm) ||
      item.proposta.toLowerCase().includes(lowercasedSearchTerm) ||
      item.telefone_confirmacao.toLowerCase().includes(lowercasedSearchTerm)
    )
  }) || []

  const handleShowDetails = (props) => {
    setShowDetails('')
    setTimeout(() => {
      setShowDetails(props)
    }, 2500)
  }
  return (
    <>
      <Flex alignItems={'center'} justifyContent={'flex-start'} p={4} gap={2}>
        <Heading size={'md'} textTransform={'uppercase'}>Controle de Filas: </Heading>
        <Flex alignItems={'center'} justifyContent={'center'}>
          <Text size={18} textTransform={'uppercase'} fontWeight={'semibold'} mb={-1.5}>Confirmação de Propostas</Text>
          <IoCheckmarkDone size={30} color='blue' />
        </Flex>
      </Flex>

      <Flex mt={4} px={4} gap={6}>
        <Box onClick={() => { handleShowDetails('aguardando_confirmacao_whats') }} cursor={'pointer'} w={'450px'} p={4} boxShadow={'lg'} rounded={'xl'} bg={'white'}>
          <Text fontWeight={'semibold'}>Aguardando Confirmação</Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            <Text fontWeight={'bold'} fontSize={30}>
              {firstStatus.aguardando_confirmacao_whats < 10 ? `0${firstStatus.aguardando_confirmacao_whats}` : firstStatus.aguardando_confirmacao_whats}
            </Text>
            <FcAlarmClock size={32} />
          </Flex>
        </Box>
        <Box onClick={() => { handleShowDetails('confirmacao_whats_aprovada') }} cursor={'pointer'} w={'450px'} p={4} boxShadow={'lg'} rounded={'xl'} bg={'white'}>
          <Text fontWeight={'semibold'}>Confirmação Aprovada</Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            <Text fontWeight={'bold'} fontSize={30}>
              {firstStatus.confirmacao_whats_aprovada < 10 ? `0${firstStatus.confirmacao_whats_aprovada}` : firstStatus.confirmacao_whats_aprovada}
            </Text>
            <FcApproval size={32} />
          </Flex>
        </Box>
        <Box onClick={() => { handleShowDetails('confirmacao_whats_reprovada') }} cursor={'pointer'} w={'450px'} p={4} boxShadow={'lg'} rounded={'xl'} bg={'white'}>
          <Text fontWeight={'semibold'}>Confirmação Reprovada</Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            <Text fontWeight={'bold'} fontSize={30}>
              {firstStatus.confirmacao_whats_reprovada < 10 ? `0${firstStatus.confirmacao_whats_reprovada}` : firstStatus.confirmacao_whats_reprovada}
            </Text>
            <FcCancel size={32} />
          </Flex>
        </Box>
        <Box onClick={() => { handleShowDetails('confirmacao_whatsapp_nao_realizada') }} cursor={'pointer'} w={'450px'} p={4} boxShadow={'lg'} rounded={'xl'} bg={'white'}>
          <Text fontWeight={'semibold'}>Confirmação Não Realizada</Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            <Text fontWeight={'bold'} fontSize={30}>
              {firstStatus.confirmacao_whatsapp_nao_realizada < 10 ? `0${firstStatus.confirmacao_whatsapp_nao_realizada}` : firstStatus.confirmacao_whatsapp_nao_realizada}
            </Text>
            <FcExpired size={40} />
          </Flex>
        </Box>
      </Flex>

      <Flex mt={4} bg={'white'} alignItems={'center'} justifyContent={'center'} ml={4} boxShadow={'lg'} px={4} w={'98%'} mx={'auto'} rounded={'xl'}>
        <FcSearch size={22} />
        <Input
          ml={4}
          variant={'flushed'}
          mt={-0.5}
          placeholder='Busque por nome, proposta ou telefone...'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Flex>

      {showDetails !== '' &&
        <TableContainer css={{
          '&::-webkit-scrollbar': {
            width: '4px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'green',
            borderRadius: '10px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1'
          }
        }} maxH={'500px'} overflowY={'scroll'} mx={'auto'} w={'98%'} mt={4} p={4} bg={'white'} rounded={'xl'} boxShadow={'lg'}>
          <Table size='sm' >
            <Thead>
              <Tr>
                <Th textAlign={'left'}>Cliente</Th>
                <Th textAlign={'center'}>Proposta</Th>
                <Th textAlign={'center'}>Banco</Th>
                <Th textAlign={'center'}>Tipo</Th>
                <Th textAlign={'center'}>Telefone</Th>
                <Th textAlign={'center'}>Analista</Th>
                <Th textAlign={'center'}>Data de Envio</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredStatusList.length > 0
                ? (
                    filteredStatusList.map((item) => (
                    <Tr key={item.telefone_confirmacao}>
                      <Td fontWeight={'bold'} textAlign={'left'}>{item.nome}</Td>
                      <Td fontWeight={'bold'} textAlign={'center'}>{item.proposta}</Td>
                        <Td fontWeight={'bold'} textAlign={'center'}>{item.banco_origi}</Td>
                      <Td fontWeight={'bold'} textAlign={'center'}>{item.tipo}</Td>
                      <Td fontWeight={'bold'} textAlign={'center'}>{item.telefone_confirmacao}</Td>
                      <Td fontWeight={'bold'} textAlign={'center'}>{item.responsavel2}</Td>
                      <Td fontWeight={'bold'} textAlign={'center'}>{item.data_atualizacao}</Td>
                    </Tr>
                    ))
                  )
                : (
                  <Tr>
                    <Td colSpan={6} textAlign="left">Não tem nenhum cliente no status selecionado!</Td>
                  </Tr>
                  )}
            </Tbody>
          </Table>
        </TableContainer>
      }
    </>
  )
}
