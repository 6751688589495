/* eslint-disable react/prop-types */
import React from 'react'
import { FcAlarmClock, FcApproval, FcDecision, FcDisclaimer, FcDoNotMix, FcEndCall, FcHighPriority, FcPositiveDynamic, FcReadingEbook, FcSms } from 'react-icons/fc'
import { Box, Flex, Spinner, Text, useMediaQuery } from '@chakra-ui/react'

export default function CardsComponent ({ cardColors, showDetails, isLoading, handleCardClick, firstStatus, total, calculatePercentage }) {
  const [isMobile] = useMediaQuery('(max-width: 500px)')
  return (
    <>
      <Flex pb={isMobile && 2} overflowX={isMobile ? 'scroll' : 'none'} mt={4} px={4} gap={2} sx={{
        '&::-webkit-scrollbar': {
          height: '4px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '10px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
        <Box color={showDetails === 'propostas_Priorizadas' ? 'white' : 'black'} onClick={() => handleCardClick('propostas_Priorizadas')} cursor={'pointer'} w={'100%'} p={4} boxShadow={'lg'} rounded={'xl'} bg={showDetails === 'propostas_Priorizadas' ? cardColors.propostas_Priorizadas : 'white'}>
          <Text fontWeight={'semibold'} >Fluxo Interrompido</Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            {isLoading
              ? <Spinner size={'md'} />
              : (
              <>
                <Text fontWeight={'bold'} fontSize={30}>{firstStatus.propostas_Priorizadas < 10 ? `0${firstStatus.propostas_Priorizadas}` : firstStatus.propostas_Priorizadas}</Text>
              </>
                )}
            <FcDoNotMix size={32} />
          </Flex>
        </Box>

        <Box color={showDetails === 'propostas_enviadas' ? 'white' : 'black'} onClick={() => handleCardClick('propostas_enviadas')} cursor={'pointer'} w={'100%'} p={4} boxShadow={'lg'} rounded={'xl'} bg={showDetails === 'propostas_enviadas' ? cardColors.propostas_enviadas : 'white'}>
          <Text fontWeight={'semibold'} >Mensagem Enviada</Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            {isLoading
              ? <Spinner size={'md'} />
              : (
              <>
                <Text fontWeight={'bold'} fontSize={30}>{firstStatus.propostas_enviadas < 10 ? `0${firstStatus.propostas_enviadas}` : firstStatus.propostas_enviadas}</Text>
                <Text fontWeight={'semibold'} fontSize={22}>{calculatePercentage(firstStatus.propostas_enviadas)}%</Text>
              </>
                )}
            <FcSms size={32} />
          </Flex>
        </Box>

        <Box color={showDetails === 'propostas_interagiu' ? 'white' : 'black'} onClick={() => handleCardClick('propostas_interagiu')} cursor={'pointer'} w={'100%'} p={4} boxShadow={'lg'} rounded={'xl'} bg={showDetails === 'propostas_interagiu' ? cardColors.propostas_interagiu : 'white'}>
          <Text fontWeight={'semibold'}>Mensagem Interagida</Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            {isLoading
              ? <Spinner size={'md'} />
              : (
              <>
                <Text fontWeight={'bold'} fontSize={30}>{firstStatus.propostas_interagiu < 10 ? `0${firstStatus.propostas_interagiu}` : firstStatus.propostas_interagiu}</Text>
                <Text fontWeight={'semibold'} fontSize={22}>{calculatePercentage(firstStatus.propostas_interagiu)}%</Text>
              </>
                )}
            <FcReadingEbook size={32} />
          </Flex>
        </Box>

        <Box color={showDetails === 'propostas_Aprovado' ? 'white' : 'black'} onClick={() => handleCardClick('propostas_Aprovado')} cursor={'pointer'} w={'100%'} p={4} boxShadow={'lg'} rounded={'xl'} bg={showDetails === 'propostas_Aprovado' ? cardColors.propostas_Aprovado : 'white'}>
          <Text fontWeight={'semibold'}>Proposta Aprovada</Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            {isLoading
              ? <Spinner size={'md'} />
              : (
              <>
                <Text fontWeight={'bold'} fontSize={30}>{firstStatus.propostas_Aprovado < 10 ? `0${firstStatus.propostas_Aprovado}` : firstStatus.propostas_Aprovado}</Text>
                <Text fontWeight={'semibold'} fontSize={22}>{calculatePercentage(firstStatus.propostas_Aprovado)}%</Text>
              </>
                )}
            <FcApproval size={32} />
          </Flex>
        </Box>

        <Box color={showDetails === 'propostas_atendimentoEncerradoTTL' ? 'white' : 'black'} onClick={() => handleCardClick('propostas_atendimentoEncerradoTTL')} cursor={'pointer'} w={'100%'} p={4} boxShadow={'lg'} rounded={'xl'} bg={showDetails === 'propostas_atendimentoEncerradoTTL' ? cardColors.propostas_atendimentoEncerradoTTL : 'white'}>
          <Text fontWeight={'semibold'}>Expirados / Solucionadas</Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            {isLoading
              ? <Spinner size={'md'} />
              : (
              <>
                <Text fontWeight={'bold'} fontSize={30}>{firstStatus.propostas_atendimentoEncerradoTTL < 10 ? `0${firstStatus.propostas_atendimentoEncerradoTTL}` : firstStatus.propostas_atendimentoEncerradoTTL}</Text>
                <Text fontWeight={'semibold'} fontSize={22}>{calculatePercentage(firstStatus.propostas_atendimentoEncerradoTTL)}%</Text>
              </>
                )}
            <FcAlarmClock color='red' size={32} />
          </Flex>
        </Box>
      </Flex>

      <Flex pb={isMobile && 2} overflowX={isMobile ? 'scroll' : 'none'} mt={4} px={4} gap={2} sx={{
        '&::-webkit-scrollbar': {
          height: '4px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '10px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
        <Box color={showDetails === 'nao_solucionada' ? 'white' : 'black'} onClick={() => handleCardClick('nao_solucionada')} cursor={'pointer'} w={'100%'} p={4} boxShadow={'lg'} rounded={'xl'} bg={showDetails === 'nao_solucionada' ? cardColors.nao_solucionada : 'white'}>
          <Text fontWeight={'semibold'}>Expirados / Não Solucionadas</Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            {isLoading
              ? <Spinner size={'md'} />
              : (
              <>
                <Text fontWeight={'bold'} fontSize={30}>{firstStatus.nao_solucionada < 10 ? `0${firstStatus.nao_solucionada}` : firstStatus.nao_solucionada}</Text>
                  <Text fontWeight={'semibold'} fontSize={22}>{calculatePercentage(firstStatus.nao_solucionada)}%</Text>
              </>
                )}
            <FcDecision size={32} />
          </Flex>
        </Box>
        <Box color={showDetails === 'propostas_NaoSolicitada' ? 'white' : 'black'} onClick={() => handleCardClick('propostas_NaoSolicitada')} cursor={'pointer'} w={'100%'} p={4} boxShadow={'lg'} rounded={'xl'} bg={showDetails === 'propostas_NaoSolicitada' ? cardColors.propostas_NaoSolicitada : 'white'}>
          <Text fontWeight={'semibold'}>Proposta não solicitada</Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            {isLoading
              ? <Spinner size={'md'} />
              : (
              <>
                <Text fontWeight={'bold'} fontSize={30}>{firstStatus.propostas_NaoSolicitada < 10 ? `0${firstStatus.propostas_NaoSolicitada}` : firstStatus.propostas_NaoSolicitada}</Text>
                <Text fontWeight={'semibold'} fontSize={22}>{calculatePercentage(firstStatus.propostas_NaoSolicitada)}%</Text>
              </>
                )}
            <FcDisclaimer size={32} />
          </Flex>
        </Box>

        <Box color={showDetails === 'propostas_InformacoesDivergentes' ? 'white' : 'black'} onClick={() => handleCardClick('propostas_InformacoesDivergentes')} cursor={'pointer'} w={'100%'} p={4} boxShadow={'lg'} rounded={'xl'} bg={showDetails === 'propostas_InformacoesDivergentes' ? cardColors.propostas_InformacoesDivergentes : 'white'}>
          <Text fontWeight={'semibold'}>Informações divergentes </Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            {isLoading
              ? <Spinner size={'md'} />
              : (
              <>
                <Text fontWeight={'bold'} fontSize={30}>{firstStatus.propostas_InformacoesDivergentes < 10 ? `0${firstStatus.propostas_InformacoesDivergentes}` : firstStatus.propostas_InformacoesDivergentes}</Text>
                <Text fontWeight={'semibold'} fontSize={22}>{calculatePercentage(firstStatus.propostas_InformacoesDivergentes)}%</Text>
              </>
                )}
            <FcHighPriority size={32} />
          </Flex>
        </Box>

        <Box color={showDetails === 'propostas_TelefoneNaoMePertence' ? 'white' : 'black'} onClick={() => handleCardClick('propostas_TelefoneNaoMePertence')} cursor={'pointer'} w={'100%'} p={4} boxShadow={'lg'} rounded={'xl'} bg={showDetails === 'propostas_TelefoneNaoMePertence' ? cardColors.propostas_TelefoneNaoMePertence : 'white'}>
          <Text fontWeight={'semibold'}>Telefone não me pertence </Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            {isLoading
              ? <Spinner size={'md'} />
              : (
              <>
                <Text fontWeight={'bold'} fontSize={30}>{firstStatus.propostas_TelefoneNaoMePertence < 10 ? `0${firstStatus.propostas_TelefoneNaoMePertence}` : firstStatus.propostas_TelefoneNaoMePertence}</Text>
                <Text fontWeight={'semibold'} fontSize={22}>{calculatePercentage(firstStatus.propostas_TelefoneNaoMePertence)}%</Text>
              </>
                )}
            <FcEndCall color='red' size={32} />
          </Flex>
        </Box>

        <Box color={showDetails === 'total' ? 'white' : 'black'} onClick={() => handleCardClick('total')} cursor={'pointer'} w={'100%'} p={4} boxShadow={'lg'} rounded={'xl'} bg={showDetails === 'total' ? cardColors.total : 'white'}>
          <Text fontWeight={'semibold'}>Total</Text>
          <Flex alignItems={'center'} justifyContent={'space-between'} mt={4}>
            {isLoading ? <Spinner size={'md'} /> : <Text fontWeight={'bold'} fontSize={30}>{total < 10 ? `0${total}` : total}</Text>}
            <FcPositiveDynamic size={32} />
          </Flex>
        </Box>
      </Flex>
    </>
  )
}
