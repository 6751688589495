/* eslint-disable react/prop-types */
import { Flex, Input } from '@chakra-ui/react'
import React from 'react'
import { FcSearch } from 'react-icons/fc'

export default function InputComponent ({ isAutomatic, searchTerm, setSearchTerm }) {
  return (
    <Flex display={isAutomatic ? 'none' : 'flex'}
      mt={4} bg={'white'} alignItems={'center'} justifyContent={'center'} ml={4} boxShadow={'lg'} px={4} w={'98%'} mx={'auto'} rounded={'xl'}>
      <FcSearch size={22} />
      <Input
        ml={4}
        variant={'flushed'}
        mt={-0.5}
        placeholder='Busque por nome, proposta ou telefone...'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </Flex>
  )
}
