import { useMutation } from 'react-query'

import api from '../../../services/api'

const useGetAutenticacaoProposta = () => {
  const enviarSenha = async ({ token, senha }) => {
    await api.get(`/v1/api/public/autenticacacoVisualizarProposta?token=${token}&senha=${senha}`)
  }

  const mutation = useMutation(enviarSenha)

  const UseRequestAutenticacaoProposta = (token, senha) => {
    mutation.mutate({ token, senha })
  }

  return {
    UseRequestAutenticacaoProposta,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export default useGetAutenticacaoProposta
