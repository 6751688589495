import { useQuery } from 'react-query'

import { obtendoStatusAtendimento } from '../../../../../features/atendimento/atendimentoSlice'
import store from '../../../../../features/index'
import apiAdmin from '../../../../../services/apiAdmin'

const useObtendoStatusAgente = () => {
  const { dispatch } = store
  return useQuery(
    'atendimento_alterarStatusChat',
    async () => {
      const response = await apiAdmin.get('/v1/api/admin/atendimento/obtendo/status?idDepartamento=3&emUso=false')
      const status = response.data?.status.filter(item => item.aplicado === true)[0]
      dispatch(obtendoStatusAtendimento({ statusAtendimento: status }))
      return response.data?.status
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000 * 1000 * 5,
      refetchInterval: 300000
    }
  )
}

export { useObtendoStatusAgente }
