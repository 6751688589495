import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Grid,
  Menu,
  Text,
  Button,
  Stat,
  StatLabel,
  StatHelpText,
  StatGroup,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Flex,
  Image,
  useMediaQuery,
  Box,
  Link
} from '@chakra-ui/react'
import { TimeIcon } from '@chakra-ui/icons'
import PropTypes from 'prop-types'
import { useObterAgenda } from '../hooks/useObterAgenda'
import logo from '../../../../assets/icon.png'
import block from '../images/block.png'
import confirm from '../images/confirm.png'
import useRequestAdicionarEventoAgenda from '../hooks/useRequestAdicionarEventoAgenda'
import formatDataHoraChat from '../../../../helpers/dataHora/formatDataHoraChat'

const Agenda = ({ campanhaId, baseId }) => {
  const { adicionarEVentoAgenda, isLoading: isLoadingEvento, isSuccess } = useRequestAdicionarEventoAgenda()
  const { data, isLoading } = useObterAgenda({ campanhaId, baseId })
  const [isMobile] = useMediaQuery('(max-width: 700px)')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [item, setItem] = useState(null)
  const lista = data || []

  const message = `Olá fui selecionado para o processo seletivo da vaga #${campanhaId}, tenho algumas dúvidas e gostaria de esclarecer, poderia me ajudar por gentileza?`
  const messageConfirmado = `Olá marquei um processo seletivo da vaga #${campanhaId}, tenho algumas dúvidas e gostaria de esclarecer, poderia me ajudar por gentileza?`
  const encodedMessage = lista?.usuarioJaPossuiAgendamento ? encodeURIComponent(messageConfirmado) : encodeURIComponent(message)

  function obtemValorAtributoDaUrl (atributo) {
    const urlObj = new URL(window.location.href)
    const params = new URLSearchParams(urlObj.search)
    const valorAtributoUrl = parseInt(params.get(atributo))
    return valorAtributoUrl
  }

  const handleConfirmarAgendamento = () => {
    adicionarEVentoAgenda({
      campanhaId: obtemValorAtributoDaUrl('campanhaId'),
      baseId: obtemValorAtributoDaUrl('baseId'),
      idAgendaCompromisso: item.id
    })
    onClose()
  }

  const abrirDialog = (compromisso) => { onOpen() }

  const compromissosAgrupados = lista?.agenda?.reduce((acc, compromisso) => {
    const data = formatDataHoraChat(compromisso.dataCompromisso).slice(0, 11)
    if (!acc[data]) {
      acc[data] = []
    }
    acc[data].push(compromisso)
    return acc
  }, {})

  return (
    <Card h={'100vh'} bg={'white'} w={'100%'} overflowY={'scroll'}>
      <Image mt={4} p={2} borderRadius={'50%'} boxShadow={'xl'} border={'1px solid green'} src={logo} alt='Logo Empresa' w={'80px'} h={'80px'} mx={'auto'} />
      <CardHeader display={lista?.agendaBloqueada || lista?.usuarioJaPossuiAgendamento ? 'none' : 'block'} fontWeight="bold" fontSize="xl" mb={-4}>
        <Flex flexDir={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'left' : 'center'} justifyContent={'flex-start'} gap={isMobile ? 0 : 2}>
          <Text>Agenda: </Text>
          <Text>{lista?.compromisso}</Text>
        </Flex>
        <Text color="gray.600" fontSize={16}>
          Essa evento é uma ação de Recursos Humanos do Grupo Mais Valor.
        </Text>
      </CardHeader>
      <CardBody>
        {isLoading
          ? (<Text textAlign="center" color="gray.500">Carregando...</Text>)
          : isSuccess
            ? (<Text fontWeight="bold" fontSize="lg">Agendamento confirmado com sucesso!.</Text>)
            : (
              <Grid gap={2}>
                <Text fontWeight="bold" fontSize="lg">
                  {lista?.agenda && 'Agende sua entrevista! 😅'}
                </Text>
                <Menu>
                  {lista?.usuarioJaPossuiAgendamento
                    ? (
                      <>
                        <Text mt={-4} textAlign={'center'} fontWeight="bold" fontSize="lg">Você já possui um agendamento marcado.</Text>
                        <Flex flexDir={isMobile ? 'column' : 'row'} alignItems={'center'} justifyContent={'center'}>
                          <Text dangerouslySetInnerHTML={{ __html: lista?.localizacao?.replaceAll('\n', '<br>') }} />
                          <Image mt={isMobile ? 20 : 0} w={isMobile ? '350px' : '550px'} src={confirm} alt='agenda mais valor confirmada' />
                        </Flex>
                      </>
                      )
                    : lista?.agendaBloqueada
                      ? (
                        <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'}>
                          <Text textAlign={'center'} fontWeight="bold" fontSize="lg">Agenda bloqueada, pois o limite de candidatos foi atingido.</Text>
                          <Image mt={isMobile ? 20 : 0} w={isMobile ? '350px' : '550px'} src={block} alt='agenda mais valor bloqueado' />
                        </Flex>
                        )
                      : (
                        <Flex flexDir={isMobile ? 'column' : 'row'} gap={2} >
                          {Object.keys(compromissosAgrupados).map(data => (
                            <Box key={data} w={'250px'} py={2} px={4} rounded={'xl'} boxShadow={'lg'} >
                              <Text fontWeight="bold" mt={4}>{data}</Text>
                              {compromissosAgrupados[data].map((compromisso) => (
                                <StatGroup mt={2} key={compromisso.id}>
                                  <Stat>
                                    <StatLabel display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={2} mb={1}>
                                      <TimeIcon /> {compromisso.horaInicial}
                                    </StatLabel>
                                    <StatHelpText>
                                      <Button colorScheme="teal" onClick={() => { abrirDialog(compromisso.id); setItem(compromisso) }} size='sm'>
                                        Confirmar Agendamento
                                      </Button>
                                    </StatHelpText>
                                  </Stat>
                                </StatGroup>
                              ))}
                            </Box>
                          ))}
                        </Flex>
                        )}
                </Menu>
              </Grid>
              )}
      </CardBody>
      <CardFooter>
        <Flex justify="center">
          <Text color="gray.600" fontWeight="semibold" fontSize={14}>
            Dúvidas? Entre em contato com o RH.<br />
            <Link
              href={`https://wa.me/5511960140407?text=${encodedMessage}`}
              color="teal.500"
              isExternal
            >
              WhatsApp (11) 96014-0407
            </Link>
          </Text>
        </Flex>
      </CardFooter>

      {/* Dialog para confirmar o agendamento */}
      <AlertDialog isOpen={isOpen} leastDestructiveRef={undefined} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmar Agendamento
            </AlertDialogHeader>
            <AlertDialogBody>
              Tem certeza de que deseja agendar o compromisso selecionado?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>Cancelar</Button>
              <Button colorScheme="teal" ml={3} isLoading={isLoadingEvento} onClick={handleConfirmarAgendamento}>
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  )
}

Agenda.propTypes = {
  campanhaId: PropTypes.number.isRequired,
  baseId: PropTypes.string.isRequired
}

export default Agenda
