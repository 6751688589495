import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { Input, Progress, Box } from '@chakra-ui/react'

import api from '../../../services/api'
import UploadArquivos from './UploadDeArquivos'

export default function CaixaDeMensagem () {
  const queryClient = useQueryClient()
  const [mensagem, SetMensagens] = React.useState(null)
  const { informacao } = useSelector((state) => state.blocoInformacaoAtendimento)

  const handlerEnviandoMensagens = useMutation(async () => {
    const response = await api.post('/v1/api/public/inserirMensagem', {
      mensagem,
      direcao: 'in',
      idProtocolo: informacao.idProtocolo
    })
    return response.data
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('chat_useGetMensagensAtendimento', {
        idProtocolo: informacao.idProtocolo
      })
      SetMensagens('')
    }

  })

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (mensagem !== '' || mensagem !== null) { handlerEnviandoMensagens.mutate() }
    }
  }

  return (
    <>
      <Box mt={'4'} w={'100%'}>
        {informacao.status === 'Em Andamento' && (
          <>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              gap={4}
            >
              <UploadArquivos/>
              <Input
                fontWeight={'bold'}
                variant='unstyled'
                height={'50px'}
                paddingInline={'10px'}
                borderRadius={'20px'}
                bg={'gray.300'}
                color={'black'}
                value={mensagem}
                mt={2}
                onChange={(e) => SetMensagens(e.target.value)}
                onKeyDown={handleKeyPress}
                p='2'
                size='sm'
              />
            </Box>
          </>
        )}

        {handlerEnviandoMensagens.isLoading && (
          <Progress colorScheme={'whatsapp'} mt={2} size='xs' isIndeterminate />
        )}
      </Box>
    </>
  )
}
