/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Select,
  Text
} from '@chakra-ui/react'

import {
  atualizandoDadosAtendimento
} from '../../../../../features/atendimento/atendimentoSlice'

import { useEncerrarAtendimento } from '../hooks/useEncerrarAtendimento'
import { useObetendoTabulacao } from '../hooks/useObetendoTabulacao'
import { useTabularAtendimento } from '../hooks/useTabularAtendimento'

function DialogEncerrarAtendimento ({ isOpen, onClose }) {
  const { dadosAtendimento } = useSelector(state => state.atendimento)
  const { UseRequestEncerrarAtendimento, isSuccess } = useEncerrarAtendimento()
  const dispatch = useDispatch()
  const cancelRef = React.useRef()
  const { UseRequestTabularAtendimento } = useTabularAtendimento()
  const tabulacao = useObetendoTabulacao()
  const dadosTabulacao = tabulacao?.data || []

  const [primeiraOpcaoSelecionada, setPrimeiraOpcaoSelecionada] = useState(false)
  const [segundaOpcaoSelecionada, setSegundaOpcaoSelecionada] = useState('')
  const [botaoEncerrarHabilitado, setBotaoEncerrarHabilitado] = useState(false)

  useEffect(() => {
    if (primeiraOpcaoSelecionada !== '' && segundaOpcaoSelecionada !== '') {
      setBotaoEncerrarHabilitado(true)
    } else {
      setBotaoEncerrarHabilitado(false)
    }
  }, [primeiraOpcaoSelecionada, segundaOpcaoSelecionada])

  const resetDialogState = () => {
    setPrimeiraOpcaoSelecionada(false)
    setSegundaOpcaoSelecionada('')
    setBotaoEncerrarHabilitado(false)
  }

  const handleCloseDialog = () => {
    resetDialogState()
    onClose()
  }

  const executandoEncerramentoAtendimento = (idProtocolo) => {
    onClose()
    UseRequestEncerrarAtendimento(idProtocolo)
    UseRequestTabularAtendimento(dadosAtendimento.idProtocolo, segundaOpcaoSelecionada, primeiraOpcaoSelecionada)
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(atualizandoDadosAtendimento({
        status: 'Fechado'
      }))
    }
  }, [isSuccess, dispatch])

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleCloseDialog}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Encerramento de Atendimento
          </AlertDialogHeader>

          <AlertDialogBody>
            Antes de encerrar o atendimento... <br /> O assunto selecionado corresponde com o que foi dito no chat?

            <Text mt={4}>Diga se o assunto era correspondente</Text>
            <Select
              placeholder='-- Selecione uma opção --'
              onChange={(e) => setPrimeiraOpcaoSelecionada(e.target.value === 'true')}
              value={primeiraOpcaoSelecionada}
            >
              <option value='true'>Sim, corresponde</option>
              <option value='false'>Não corresponde</option>
            </Select>

            <Text mt={4}>Diga qual assunto seria correspondente</Text>
            <Select
              placeholder='-- Selecione uma opção --'
              onChange={(e) => setSegundaOpcaoSelecionada(e.target.value)}
              value={segundaOpcaoSelecionada}
            >
              {dadosTabulacao.map((dados, index) => (
                <option key={index} value={dados.tabulacaoId}>{dados.tabulacao}</option>
              ))}
            </Select>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleCloseDialog}>
              Cancelar
            </Button>
            <Button
              display={botaoEncerrarHabilitado ? '' : 'none'}
              bg='red'
              color='white'
              onClick={() => { executandoEncerramentoAtendimento(dadosAtendimento.idProtocolo) }}
              ml={3}
            >
              Encerrar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default DialogEncerrarAtendimento
