import { useQuery } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useGetCampanhas = () => {
  return useQuery(
    ['chat_useGetCampanhas'],
    async () => {
      const response = await apiAdmin.get('/v1/api/admin/campanhas/obter-campanhas', { headers: { 'x-access-token': localStorage.getItem('token') } })
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: false,
      refetchInterval: false
    }
  )
}

export { useGetCampanhas }
