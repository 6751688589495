/* eslint-disable react/no-children-prop */
import React, { useState } from 'react'
import { GrNewWindow } from 'react-icons/gr'

import { Button, FormControl, FormLabel, Icon, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'

import { useCriarNovaTrilha } from '../hooks/useEnviarNovaTrilha'

export default function CreateNovaTrilha () {
  const { UseRequestCriarNovaTrilha } = useCriarNovaTrilha()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [trilhaInput, setTrilhaInput] = useState('')

  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  const handleInputChange = (event) => {
    const inputValue = event.target.value
    const words = inputValue.trim().split(' ')
    if (inputValue.length <= 50 && words.length <= 5) {
      setTrilhaInput(inputValue)
    }
  }

  const enviarNovaTrilha = () => {
    UseRequestCriarNovaTrilha(
      {
        nome: trilhaInput
      }
    )
  }

  return (
    <>
      <Button rounded={'50%'} w={'50px'} h={'50px'} colorScheme='green' pos={'fixed'} bottom={4} right={4} onClick={onOpen}>
        <Icon fontSize={20} as={GrNewWindow} />
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Criar Nova Trilha</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl mt={2}>
              <FormLabel>Trilha</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  color='gray'
                  fontSize='1.2em'
                  children='#'
                />
                <Input
                  color={'black'}
                  fontWeight={'semibold'}
                  placeholder='Sua Nova Trilha'
                  value={trilhaInput}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>Cancelar</Button>
            <Button onClick={() => { enviarNovaTrilha(); onClose(); setTrilhaInput('') }} colorScheme='green'>
              Criar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
