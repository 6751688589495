import React from 'react'

import { GridItem, Grid, useColorMode } from '@chakra-ui/react'

import checkPageStatus from '../../../../helpers/verificaNotificacao'
import socket from '../../../../services/socket-config.js'
import ChatMensagens from './components/ChatMensagens'
import SidebarListaContatos from './components/SidebarListaContatos'

export default function Home () {
  const { colorMode } = useColorMode()
  React.useEffect(() => {
    const roomKey = 'notificacao-novo-atendimento-3'
    socket.emit('joinRoom', roomKey)
    socket.on(`notification-${roomKey}`, (notification) => {
      checkPageStatus(notification.message)
    })

    const roomKey2 = 'notificacao-de-atendimento-aceito-3'
    socket.emit('joinRoom', roomKey2)
    socket.on(`notification-${roomKey2}`, (notification) => {
      checkPageStatus(notification.message)
    })
  }, [])

  return (
    <>
      <Grid
        bg={colorMode === 'light' ? 'white' : 'gray.600'}
        overflow={'hidden !important'}
        templateAreas={`"header header"
                  "nav main"
                  "nav footer"`}
        gridTemplateRows={'2px 1fr 3px'}
        gridTemplateColumns={'400px 1fr'}
        gap='1'
        color='blackAlpha.700'
        fontWeight='bold'
      >
        <GridItem bg={colorMode === 'light' ? 'white' : 'gray.600'} area={'nav'} overflow={'hidden !important'}>
          <SidebarListaContatos />
        </GridItem>
        <GridItem zIndex={9} mt={1} bg={colorMode === 'light' ? 'white' : 'gray.600'} area={'main'} overflow={'hidden !important'}>
          <ChatMensagens />
        </GridItem>
      </Grid>
    </>
  )
}
