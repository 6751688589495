import { useQuery } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useObtendoQuantidadeTrilhasUsuarioMes = () => {
  return useQuery(
    'atendimento_useObtendoQuantidadeTrilhasUsuarioMes',
    async () => {
      const response = await apiAdmin.get('/v1/api/admin/dashboardPainelDeAtendimento/quantidadeTrilhasSelecionadosPeloUsuarioPorMes')
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000 * 1000 * 5,
      refetchInterval: 300000
    }
  )
}

export { useObtendoQuantidadeTrilhasUsuarioMes }
