/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Badge, Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { FaArrowDownShortWide, FaArrowUpWideShort } from 'react-icons/fa6'
import { motion } from 'framer-motion'
import formatDataHoraChat from '../../../../helpers/dataHora/formatDataHoraChat'

export default function TableComponent ({ requestSort, sortedData, sortConfig, showDetails }) {
  const [exibeTabela, setExibeTabela] = useState(false)

  useEffect(() => {
    if (showDetails !== '') {
      setExibeTabela(true)
    }
  }, [showDetails])

  return (
    <Box
      as={motion.div}
      initial={{ y: '-100vh' }}
      animate={{ y: exibeTabela ? 0 : '-100vh' }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      display={exibeTabela ? 'block' : 'none'}
      w={'100%'}
      h={'100vh'}
      pos={'absolute'}
      top={0}
    >
      <TableContainer
        mx={'auto'}
        w={'100%'}
        h={'100vh'}
        p={4}
        bg={'white'}
        rounded={'xl'}
        boxShadow={'lg'}
        overflowY={'scroll'}
      >
        <Table size='sm'>
          <Thead>
            <Tr>
              <Th display={showDetails === 'propostas_atendimentoEncerradoTTL' ? 'none' : 'flex'} alignItems={'center'} justifyContent={'space-between'} cursor={'pointer'} textAlign={'left'} onClick={() => requestSort('nome')}>
                <Text>Cliente</Text>
                {sortConfig.key !== 'nome' && sortConfig.direction !== 'descending'
                  ? (
                    <FaArrowDownShortWide />
                    )
                  : (
                    <FaArrowUpWideShort />
                    )}
              </Th>
              <Th cursor={'pointer'} textAlign={'center'} onClick={() => requestSort('proposta')}>
                <Text display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2}>
                  Proposta
                  {sortConfig.key !== 'proposta' && sortConfig.direction !== 'descending'
                    ? (
                      <FaArrowDownShortWide />
                      )
                    : (
                      <FaArrowUpWideShort />
                      )}
                </Text>
              </Th>
              <Th cursor={'pointer'} textAlign={'center'} onClick={() => requestSort('banco_origi')}>
                <Text display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2}>
                  Banco
                  {sortConfig.key !== 'banco_origi' && sortConfig.direction !== 'descending'
                    ? (
                      <FaArrowDownShortWide />
                      )
                    : (
                      <FaArrowUpWideShort />
                      )}
                </Text>
              </Th>
              <Th cursor={'pointer'} textAlign={'center'} onClick={() => requestSort('tipo')}>
                <Text display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2}>
                  Tipo
                  {sortConfig.key !== 'tipo' && sortConfig.direction !== 'descending'
                    ? (
                      <FaArrowDownShortWide />
                      )
                    : (
                      <FaArrowUpWideShort />
                      )}
                </Text>
              </Th>
              <Th cursor={'pointer'} textAlign={'center'} onClick={() => requestSort('respostaCapturadaBotWhatsApp')}>
                <Text display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2}>
                  Resposta
                  {sortConfig.key !== 'respostaCapturadaBotWhatsApp' && sortConfig.direction !== 'descending'
                    ? (
                      <FaArrowDownShortWide />
                      )
                    : (
                      <FaArrowUpWideShort />
                      )}
                </Text>
              </Th>
              <Th display={showDetails !== 'propostas_atendimentoEncerradoTTL' ? 'none' : 'initial'} cursor={'pointer'} textAlign={'center'} onClick={() => requestSort('status')}>
                <Text display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2}>
                  Status
                  {sortConfig.key !== 'status' && sortConfig.direction !== 'descending'
                    ? (
                      <FaArrowDownShortWide />
                      )
                    : (
                      <FaArrowUpWideShort />
                      )}
                </Text>
              </Th>
              <Th cursor={'pointer'} textAlign={'center'} onClick={() => requestSort('primeiraInteracaoBot')}>
                <Text display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2}>
                  Data Resposta
                  {sortConfig.key !== 'primeiraInteracaoBots' && sortConfig.direction !== 'descending'
                    ? (
                      <FaArrowDownShortWide />
                      )
                    : (
                      <FaArrowUpWideShort />
                      )}
                </Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedData.length > 0
              ? (
                  sortedData.map((item) => (
                  <Tr display={item.respostaCapturadaBotWhatsApp === 'Atendimento Encerrado Por Tempo' && item.status === 'CONFIRMACAO WHATSAPP NAO REALIZADA' && showDetails === 'propostas_atendimentoEncerradoTTL' ? 'none' : ''} key={item.telefone_confirmacao}>
                    <Td display={showDetails === 'propostas_atendimentoEncerradoTTL' ? 'none' : ''} fontWeight={'bold'} textAlign={'left'}>{item.nome}</Td>
                    <Td fontWeight={'bold'} textAlign={'center'}>{item.proposta}</Td>
                    <Td fontWeight={'bold'} textAlign={'center'}>{item.banco_origi}</Td>
                    <Td fontWeight={'bold'} textAlign={'center'}>{item.tipo}</Td>
                    <Td fontWeight={'bold'} textAlign={'center'}>
                      {
                        item.respostaCapturadaBotWhatsApp === 'Aprovado'
                          ? (
                            <Badge bg='green' color='white'>
                              {item.respostaCapturadaBotWhatsApp}
                            </Badge>
                            )
                          : item.respostaCapturadaBotWhatsApp === 'Atendimento Encerrado Por Tempo' && item.status === 'CONFIRMACAO WHATSAPP NAO REALIZADA'
                            ? (
                              <Badge bg='red' color='white'>
                                {item.respostaCapturadaBotWhatsApp}
                              </Badge>
                              )
                            : item.respostaCapturadaBotWhatsApp === 'Atendimento Encerrado Por Tempo'
                              ? (
                                <Badge bg='orange' color='white'>
                                  {item.respostaCapturadaBotWhatsApp}
                                </Badge>
                                )
                              : item.respostaCapturadaBotWhatsApp === 'Interagiu'
                                ? (
                                  <Badge bg='blue' color='white'>
                                    {item.respostaCapturadaBotWhatsApp}
                                  </Badge>
                                  )
                                : item.respostaCapturadaBotWhatsApp === 'Enviado'
                                  ? (
                                    <Badge bg='orange' color='white'>
                                      {item.respostaCapturadaBotWhatsApp}
                                    </Badge>
                                    )
                                  : item.respostaCapturadaBotWhatsApp === 'Proposta Priorizada'
                                    ? (
                                      <Badge bg='red' color='white'>
                                        {item.respostaCapturadaBotWhatsApp}
                                      </Badge>
                                      )
                                    : item.respostaCapturadaBotWhatsApp === 'Proposta não solicitada'
                                      ? (
                                        <Badge bg='purple' color='white'>
                                          {item.respostaCapturadaBotWhatsApp}
                                        </Badge>
                                        )
                                      : item.respostaCapturadaBotWhatsApp === 'Informações divergentes'
                                        ? (
                                          <Badge bg='yellow' color='black'>
                                            {item.respostaCapturadaBotWhatsApp}
                                          </Badge>
                                          )
                                        : item.respostaCapturadaBotWhatsApp === 'Telefone não me pertence'
                                          ? (
                                            <Badge bg='grey' color='white'>
                                              {item.respostaCapturadaBotWhatsApp}
                                            </Badge>
                                            )
                                          : null
                      }

                    </Td>
                    <Td display={showDetails !== 'propostas_atendimentoEncerradoTTL' ? 'none' : ''} fontWeight={'bold'} textAlign={'center'}>{item.status}</Td>
                    <Td fontWeight={'bold'} textAlign={'center'}>{formatDataHoraChat(item.primeiraInteracaoBot)}</Td>
                  </Tr>
                  ))
                )
              : (
                <Tr>
                  <Td colSpan={6} textAlign="left">Não tem nenhum cliente no status selecionado!</Td>
                </Tr>
                )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}
