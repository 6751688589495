import { useMutation } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useResponderMensagem = () => {
  const envioDeMensagem = async (payload) => {
    await apiAdmin.post('/v1/api/admin/atendimento/responder-mensagem', payload)
  }

  const mutation = useMutation(envioDeMensagem)

  const UseRequestResponderMensagem = (idProtocolo, mensagem, direcao, idMensagem) => {
    mutation.mutate({
      idProtocolo,
      mensagem,
      direcao,
      idMensagem
    })
  }
  return {
    UseRequestResponderMensagem,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export {
  useResponderMensagem
}
