import { useMutation, useQueryClient } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useConfirmacaoAtendimento = () => {
  const queryClient = useQueryClient()
  const confirmarAtendimento = async (payload) => {
    const response = await apiAdmin.put('/v1/api/admin/atendimento/aceitar', payload)

    queryClient.invalidateQueries('atendimento_useObtendoAtendimentos')

    if (response.status === 200) {
      mutation.isSuccess = true
      mutation.isError = false
    }

    if (response.status !== 200) {
      mutation.isSuccess = false
      mutation.isError = true
    }
  }

  const mutation = useMutation(confirmarAtendimento)

  const UseRequestConfirmacaoAtendimento = (idProtocolo, aceite) => {
    mutation.mutate({ idProtocolo, aceite })
  }
  return {
    UseRequestConfirmacaoAtendimento,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export {
  useConfirmacaoAtendimento
}
