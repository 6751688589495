import { useQuery } from 'react-query'

import api from '../../../services/api'

const useGetValidaToken = ({ token }) => {
  return useQuery(
    ['useGetValidaToken'],
    async () => {
      const response = await api.get(`/v1/api/public/validaToken?token=${token}`)
      const { data } = response
      return data
    },
    {
      staleTime: 5000 * 60 * 60 * 24,
      enabled: !!token
    }
  )
}

export { useGetValidaToken }
