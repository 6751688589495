import { useMutation, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { obtendoDadosAtendimento } from '../../../../../features/atendimento/atendimentoSlice'
import apiAdmin from '../../../../../services/apiAdmin'

const useTransferirAtendimento = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const alterarAtendimento = async (payload) => {
    const response = await apiAdmin.put('/v1/api/admin/atendimento/transferindoAtendimentoParaAgente', payload)

    queryClient.removeQueries('atendimento_useObtendoAtendimentos')
    queryClient.removeQueries('atendimento_useObtendoMensagensAtendimentos')

    if (response.status === 200) {
      dispatch(obtendoDadosAtendimento({
        dadosAtendimento: {}
      }))
      queryClient.invalidateQueries('atendimento_alterarAtendimentoChat')
    }
  }

  const mutation = useMutation(alterarAtendimento)

  const UseRequestAlterarAtendimento = (idProtocolo, idAcesso) => {
    mutation.mutate({
      idProtocolo,
      idAcesso
    })
  }
  return {
    UseRequestAlterarAtendimento,
    isLoading: mutation.isLoading
  }
}

export { useTransferirAtendimento }
