/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { FaPenAlt } from 'react-icons/fa'
import { FcDownLeft, FcFullTrash } from 'react-icons/fc'
import { IoIosArrowDown } from 'react-icons/io'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useDisclosure,
  Input,
  useColorMode,
  Divider
} from '@chakra-ui/react'

import { useApagarMensagem } from '../hooks/useApagarMensagem'
import { useEditarMensagem } from '../hooks/useEditarMensagem'
import { useResponderMensagem } from '../hooks/useResponderMensagem'
import { useManipulacaoDeMensagem } from '../../../../../layouts/provider/ManipulacaoDeMensagens'

export default function ManipulacaoDeMensagens ({ idProtocolo, idMensagem, direcao }) {
  const { UseRequestApagarMensagem } = useApagarMensagem()
  const { UseRequestEditarMensagem } = useEditarMensagem()
  const { UseRequestResponderMensagem } = useResponderMensagem()
  const { setHandleMenuItem } = useManipulacaoDeMensagem()
  const { isOpen: isApagarOpen, onOpen: onApagarOpen, onClose: onApagarClose } = useDisclosure()
  const { isOpen: isEditarOpen, onOpen: onEditarOpen, onClose: onEditarClose } = useDisclosure()
  const { isOpen: isResponderOpen, onOpen: onResponderOpen, onClose: onResponderClose } = useDisclosure()
  const cancelRef = React.useRef()
  const { colorMode } = useColorMode()
  const [newMessage, setNewMessage] = useState('')

  const handleApagarClick = () => {
    onApagarOpen()
  }

  const handleEditarClick = () => {
    onEditarOpen()
  }

  const handleResponderClick = () => {
    onResponderOpen()
  }

  const handleConfirmarApagar = () => {
    UseRequestApagarMensagem(idProtocolo, idMensagem)
    onApagarClose()
  }

  const handleConfirmarEditar = () => {
    UseRequestEditarMensagem(idProtocolo, idMensagem, newMessage)
    onEditarClose()
    setNewMessage('')
  }

  const handleConfirmarResponder = () => {
    UseRequestResponderMensagem(idProtocolo, newMessage, 'out', idMensagem)
    onResponderClose()
    setNewMessage('')
  }

  const handleInputChange = (event) => {
    setNewMessage(event.target.value)
  }

  return (
    <>
      <Menu
        onClose={() => setHandleMenuItem(false)}
        onOpen={() => setHandleMenuItem(true)}
        closeOnBlur={true}
        closeOnSelect={true}
      >
        <MenuButton
          mt={-3}
          mr={-3}
          as={Button}
          bg={'transparent'}
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
        >
          <IoIosArrowDown />
        </MenuButton>
        <MenuList
         bg={colorMode === 'light' ? 'white' : 'gray.400'} mt={-4} minWidth='240px' display={'flex'} flexDir={'column'}>
          <Button
            display={direcao === 'in' ? 'none' : ''}
            rounded={0}
            bg={colorMode === 'light' ? 'white' : 'gray.400'}
            color={colorMode === 'light' ? 'gray.600' : 'black'}
            _hover={{ bg: colorMode === 'light' ? 'gray.200' : 'gray.500', color: 'black' }}
            onClick={handleEditarClick}
          >
            <Text display={'flex'} align={'center'} justifyContent={'space-between'} textAlign={'start'} w={'100%'}>
              Editar <FaPenAlt />
            </Text>
          </Button>
          <Divider />
          <Button
            display={direcao === 'in' ? 'none' : ''}
            rounded={0}
            bg={colorMode === 'light' ? 'white' : 'gray.400'}
            color={colorMode === 'light' ? 'gray.600' : 'black'}
            _hover={{ bg: colorMode === 'light' ? 'gray.200' : 'gray.500', color: 'black' }}
            onClick={handleApagarClick}
          >
            <Text display={'flex'} align={'center'} justifyContent={'space-between'} textAlign={'start'} w={'100%'}>
              Apagar <FcFullTrash />
            </Text>
          </Button>
          <Divider />
          <Button
            rounded={0}
            bg={colorMode === 'light' ? 'white' : 'gray.400'}
            color={colorMode === 'light' ? 'gray.600' : 'black'}
            _hover={{ bg: colorMode === 'light' ? 'gray.200' : 'gray.500', color: 'black' }}
            onClick={handleResponderClick}
          >
            <Text display={'flex'} align={'center'} justifyContent={'space-between'} textAlign={'start'} w={'100%'}>
              Responder <FcDownLeft />
            </Text>
          </Button>
        </MenuList>
      </Menu>

      <AlertDialog
        isOpen={isResponderOpen}
        leastDestructiveRef={cancelRef}
        onClose={onResponderClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader display={'flex'} alignItems={'center'} justifyContent={'space-between'} fontSize='lg' fontWeight='bold'>
              Responder Sobre a Mensagem <FcDownLeft />
            </AlertDialogHeader>

            <AlertDialogBody>
              <Input onChange={handleInputChange} value={newMessage} />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onResponderClose}>
                Cancelar
              </Button>
              <Button bg='green' color='white' ml={3} onClick={handleConfirmarResponder}>
                Enviar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isEditarOpen}
        leastDestructiveRef={cancelRef}
        onClose={onEditarClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader display={'flex'} alignItems={'center'} justifyContent={'space-between'} fontSize='lg' fontWeight='bold'>
              Editar Mensagem <FaPenAlt />
            </AlertDialogHeader>

            <AlertDialogBody>
              <Input onChange={handleInputChange} value={newMessage} />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onEditarClose}>
                Cancelar
              </Button>
              <Button bg='green' color='white' ml={3} onClick={handleConfirmarEditar}>
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isApagarOpen}
        leastDestructiveRef={cancelRef}
        onClose={onApagarClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader display={'flex'} alignItems={'center'} justifyContent={'space-between'} fontSize='lg' fontWeight='bold'>
              Apagar mensagem
              <FcFullTrash />
            </AlertDialogHeader>

            <AlertDialogBody>
              Deseja mesmo apagar está mensagem?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onApagarClose} colorScheme='green'>
                Manter
              </Button>
              <Button colorScheme='red' color='white' ml={3} onClick={handleConfirmarApagar}>
                Apagar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
