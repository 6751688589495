import { Box } from '@chakra-ui/react'
import React from 'react'
import Calendar from './components/Calendario'

export default function AgendaCompromissoCalendario () {
  return (
    <Box>
      <div className="App">
        <main className="main">
          <Calendar />
        </main>
      </div>
    </Box>
  )
}
