import React, { useState, useEffect, useMemo } from 'react'
import { Button, Flex, Skeleton, Stack, Text } from '@chakra-ui/react'
import { useGetStatus } from './hooks/useGetStatus'
import TableComponent from './components/Table'
import InputComponent from './components/Input'
import CardsComponent from './components/Cards'
import HeaderComponent from './components/Header'
import { FcInfo } from 'react-icons/fc'

const cardColors = {
  propostas_Priorizadas: 'gray.400',
  propostas_enviadas: 'orange.300',
  propostas_interagiu: 'blue.400',
  propostas_Aprovado: 'green.700',
  propostas_atendimentoEncerradoTTL: 'red.400',
  nao_solucionada: 'red.400',
  propostas_NaoSolicitada: 'yellow.500',
  propostas_InformacoesDivergentes: 'yellow.500',
  propostas_TelefoneNaoMePertence: 'yellow.500',
  total: 'gray.400'
}
const cardLegend = {
  propostas_Priorizadas: 'Explicação: Esse card representa as propostas que tiveram seus status atualizados antes do fluxo natural da confirmação terminar.',
  propostas_enviadas: 'Explicação: Esse card representa as propostas que tiveram a confirmação disparada porém ainda não teve interação do cliente.',
  propostas_interagiu: 'Explicação: Esse card representa as propostas que tiveram a confirmação disparada e o cliente abriu a conversa com nosso bot.',
  propostas_Aprovado: 'Explicação: Esse card representa as propostas que tiveram a confirmação do cliente via bot, ele abriu o link, analisou e confirmou sua proposta.',
  propostas_atendimentoEncerradoTTL: 'Explicação: Esse card representa aqueles clientes que tiveram sua interação com o bot incerrada devido o tempo ter chego no limite (30 min após o disparo). Porém forma tratadas pro um analista.',
  nao_solucionada: 'Explicação: Esse card representa as propostas que entraram em "atendimento encerrado por tempo" e nenhum analista retornou para finalizar a proposta.',
  propostas_NaoSolicitada: 'Explicação: Esse card representa aquelas propostas que o cliente recusou por não ter solicitado nenhum tipo de contrato conosco.',
  propostas_InformacoesDivergentes: 'Explicação: Esse card representa aquelas propostas que o cliente recusou por ter informações divergentes com o que foi acordado com o cliente.',
  propostas_TelefoneNaoMePertence: 'Explicação: Esse card representa as propostas que foram disparadas para números dos quais o usuario não é se indentifica como o cliente.',
  total: 'Explicação: Esse card representa a soma de todos os card com exeção das propostas priorizadas pois não fazem parte do fluxo.'
}

export default function ConfirmacaoPropostasWppHistorico () {
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' })
  const [showDetails, setShowDetails] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [isAutomatic, setIsAutomatic] = useState(true)
  const [loadingTable, setLoadingTable] = useState(false)
  const { data, isLoading } = useGetStatus()
  const resultStatusAcompanhamentos = data || []
  const resultListaStatusAcompanhamentos = data || []

  const statusAcompanhamentos = resultStatusAcompanhamentos.statusAcompanhamentos || []
  const firstStatus = statusAcompanhamentos[0] || {}

  const total = statusAcompanhamentos.reduce((acc, curr) => acc + parseInt(curr.propostas_enviadas) + parseInt(curr.propostas_interagiu) + parseInt(curr.propostas_Aprovado) + parseInt(curr.propostas_atendimentoEncerradoTTL) + parseInt(curr.propostas_NaoSolicitada) + parseInt(curr.propostas_InformacoesDivergentes) + parseInt(curr.propostas_TelefoneNaoMePertence) + parseInt(curr.nao_solucionada), 0)

  const filteredStatusList = resultListaStatusAcompanhamentos.listaStatusAcompanhamentos?.filter((item) => {
    if (showDetails === 'propostas_Aprovado') { return item.respostaCapturadaBotWhatsApp === 'Aprovado' }
    if (showDetails === 'propostas_enviadas') { return item.respostaCapturadaBotWhatsApp === 'Enviado' }
    if (showDetails === 'propostas_interagiu') { return item.respostaCapturadaBotWhatsApp === 'Interagiu' }
    if (showDetails === 'propostas_atendimentoEncerradoTTL') { return item.respostaCapturadaBotWhatsApp === 'Atendimento Encerrado Por Tempo' }
    if (showDetails === 'nao_solucionada') { return item.status === 'CONFIRMACAO WHATSAPP NAO REALIZADA' }
    if (showDetails === 'propostas_NaoSolicitada') { return item.respostaCapturadaBotWhatsApp === 'Proposta não solicitada' }
    if (showDetails === 'propostas_InformacoesDivergentes') { return item.respostaCapturadaBotWhatsApp === 'Informações divergentes' }
    if (showDetails === 'propostas_TelefoneNaoMePertence') { return item.respostaCapturadaBotWhatsApp === 'Telefone não me pertence' }
    if (showDetails === 'propostas_Priorizadas') { return item.respostaCapturadaBotWhatsApp === 'Proposta Priorizada' }
    return true
  }).filter((item) => {
    if (searchTerm === '') {
      return true
    }
    const lowercasedSearchTerm = searchTerm.toLowerCase()
    return (
      item.nome.toLowerCase().includes(lowercasedSearchTerm) ||
      item.tipo.toLowerCase().includes(lowercasedSearchTerm) ||
      item.banco_origi.toLowerCase().includes(lowercasedSearchTerm) ||
      item.proposta.toLowerCase().includes(lowercasedSearchTerm)
    )
  }) || []

  const statusOrder = [
    'Enviado',
    'Interagiu',
    'Aprovado',
    'Proposta não solicitada',
    'Informações divergentes',
    'Telefone não me pertence',
    'Atendimento Encerrado Por Tempo'
  ]

  filteredStatusList.sort((a, b) => { return statusOrder.indexOf(a.respostaCapturadaBotWhatsApp) - statusOrder.indexOf(b.respostaCapturadaBotWhatsApp) })

  useEffect(() => {
    if (isAutomatic && !isLoading) {
      const statuses = [
        'propostas_Priorizadas',
        'propostas_enviadas',
        'propostas_interagiu',
        'propostas_Aprovado',
        'propostas_atendimentoEncerradoTTL',
        'nao_solucionada',
        'propostas_NaoSolicitada',
        'propostas_InformacoesDivergentes',
        'propostas_TelefoneNaoMePertence',
        'total'
      ]
      let index = 0

      const interval = setInterval(() => {
        setShowDetails(statuses[index])
        index = (index + 1) % statuses.length
      }, 15000)

      return () => clearInterval(interval)
    }
  }, [isAutomatic])

  const handleCardClick = (status) => {
    setLoadingTable(true)
    setShowDetails('')
    setIsAutomatic(false)
    setTimeout(() => {
      setShowDetails(status)
      setLoadingTable(false)
    }, 2500)
  }

  const handlePlayClick = () => { setIsAutomatic(true) }

  const sortedData = useMemo(() => {
    const sortableItems = [...filteredStatusList]
    if (sortConfig.key !== '') {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [filteredStatusList, sortConfig])

  const requestSort = (key) => {
    let direction = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  const calculatePercentage = (value) => {
    return total > 0 ? ((value / total) * 100).toFixed(2) : 0
  }

  return (
    <Flex flexDir={'column'} w={'100%'}>

      <Button colorScheme='red' display={showDetails !== '' ? 'block' : 'none'} onClick={() => { setShowDetails('') }} bg={'red'} pos={'fixed'} bottom={4} right={4} zIndex={9999} color={'white'} fontSize={22}>X</Button>

      <HeaderComponent
        handlePlayClick={handlePlayClick}
        isAutomatic={isAutomatic} />

      <CardsComponent
        calculatePercentage={calculatePercentage}
        cardColors={cardColors}
        firstStatus={firstStatus}
        handleCardClick={handleCardClick}
        isLoading={isLoading}
        showDetails={showDetails}
        total={total} />

      <InputComponent
        isAutomatic={isAutomatic}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm} />

      {showDetails &&
        <Flex w={'100%'} alignItems={'center'} pt={2} justifyContent={'flex-start'} pl={4} pr={4} >
          <Flex w={'100%'} alignItems={'center'} rounded={'xl'} bg={'rgba(0,0,0,0.7)'} color={'white'} justifyContent={'flex-start'} gap={4} p={4}>
            <FcInfo size={30} />
            <Text fontWeight={'bold'} fontSize={20}>{cardLegend[showDetails]}</Text>
          </Flex>
        </Flex>
      }

      {showDetails !== ''
        ? (
          <TableComponent
            showDetails={showDetails}
            sortConfig={sortConfig}
            requestSort={requestSort}
            sortedData={sortedData} />
          )
        : (
          <Stack p={5}>
            <Skeleton display={!loadingTable && 'none'} height='20px' />
            <Skeleton display={!loadingTable && 'none'} height='20px' />
            <Skeleton display={!loadingTable && 'none'} height='20px' />
            <Skeleton display={!loadingTable && 'none'} height='20px' />
            <Skeleton display={!loadingTable && 'none'} height='20px' />
            <Skeleton display={!loadingTable && 'none'} height='20px' />
            <Skeleton display={!loadingTable && 'none'} height='20px' />
            <Skeleton display={!loadingTable && 'none'} height='20px' />
            <Skeleton display={!loadingTable && 'none'} height='20px' />
          </Stack>
          )
      }
    </Flex>
  )
}
