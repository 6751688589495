/* eslint-disable react/prop-types */
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Tooltip,
  Image,
  Spinner,
  Flex,
  Text
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { IoQrCode } from 'react-icons/io5'
import { useScannerQrCode } from './hooks/useScannerQrCode'

export default function ScannerQRCode ({ instancia }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { UseRequestScannerQrCode, isLoading, qrCodeData, isSuccess } = useScannerQrCode()

  const buscarQrCode = () => {
    UseRequestScannerQrCode({ instance: instancia })
  }

  useEffect(() => {
    if (qrCodeData?.response?.length !== 0) return

    if (qrCodeData?.response?.length === 0) {
      setInterval(() => {
        UseRequestScannerQrCode({ instance: instancia })
      }, 30000)
    }

    if (isSuccess && !isLoading) {
      onOpen()
    }
  }, [isSuccess, onOpen])

  return (
    <>
      <Tooltip hasArrow label="Escanear QR-CODE da Instância" placement="top">
        <Button onClick={buscarQrCode}>
          <IoQrCode />
        </Button>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>SCANNER QR-CODE</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading && (
              <Flex alignItems={'center'} justifyContent={'center'} w={'100%'}>
                <Spinner size={'2xl'} />
              </Flex>
            )}

            {isSuccess && !isLoading && qrCodeData?.message?.text && <Text>{qrCodeData.message.text}</Text>}

            {isSuccess && !isLoading && qrCodeData?.response?.length > 0 && (
              <Flex
                boxShadow={'xl'}
                rounded={'xl'}
                h={'350px'}
                alignItems={'center'}
                justifyContent={'center'}
                w={'100%'}
              >
                <Image src={qrCodeData.response[0].qrcode} />
              </Flex>
            )}
          </ModalBody>

          <ModalFooter gap={2}>
            <Button colorScheme="red" onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme="green" onClick={buscarQrCode}>
              Tentar Novamente
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
