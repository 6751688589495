import { Box, Button, Divider, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Text, useDisclosure } from '@chakra-ui/react'
import React, { memo, useState } from 'react'
import { FcFilledFilter } from 'react-icons/fc'
import { useObtendoAtendimentos } from '../hooks/useObtendoAtendimentos'

function FiltrosDataAtendimentos () {
  const mes = new Date().getMonth() + 1
  const ano = new Date().getFullYear()
  const dataInicial = `${ano}-${mes}-01`
  const dataFinal = new Date().toISOString().split('T')[0]
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [placement] = useState('right')
  const [dataHoje, setDataHoje] = useState(dataFinal)
  const [dataComeco, setDataComeco] = useState(dataInicial)
  const obterAtendimentos = useObtendoAtendimentos(dataComeco, dataHoje)

  const dispararFiltro = () => {
    obterAtendimentos.refetch()
    localStorage.setItem('dataInicial', dataHoje)
    localStorage.setItem('dataFinal', dataComeco)
    onClose()
  }

  return (
        <>
            <Button pos={'fixed'} bottom={2} right={2} colorScheme='gray' onClick={onOpen}>
                <FcFilledFilter fontSize={24} />
            </Button>
            <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader borderBottomWidth='1px'>Filtros de atendimento
                        <Divider w={'210px'} />
                        <Text fontSize={12}>Selecione as datas para obter os atendimentos deste período</Text>
                    </DrawerHeader>
                    <DrawerBody display={'flex'} flexDir={'column'} gap={2} justifyContent={'space-between'}>
                        <Box display={'flex'} flexDir={'column'} gap={2}>
                            <input style={{ background: 'transparent', height: '20px', marginTop: '20px', outline: 'none', textTransform: 'uppercase' }}
                                type='date'
                                value={dataComeco}
                                onChange={(e) => setDataComeco(e.target.value)} ></input>
                            <input style={{ background: 'transparent', height: '20px', marginTop: '20px', outline: 'none', textTransform: 'uppercase' }}
                                type='date'
                                value={dataHoje}
                              onChange={(e) => setDataHoje(e.target.value)} ></input>
                        </Box>
                        <Box>
                            <Button ml={24} onClick={dispararFiltro} colorScheme='whatsapp' mt={'4'}>Filtrar Atendimentos</Button>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
  )
}

export default memo(FiltrosDataAtendimentos)
