/* eslint-disable react/prop-types */
import React from 'react'
import { GrCircleAlert } from 'react-icons/gr'

import {
  Card, Text, List,
  ListItem,
  ListIcon,
  Box
} from '@chakra-ui/react'

export default function PropostaFgts ({ proposta, isMobile }) {
  return (
    <>
      {proposta?.propostasVinculadas[0].tipo === 'FGTS' && (
        <Card shadow={'none'}>
          <Text mt={6} mb={4} textAlign={'center'} textTransform={'uppercase'} fontWeight={'semibold'} fontSize={isMobile ? '18px' : '24px'}>Dados da proposta</Text>
          <List spacing={3} mb={6}>
            <ListItem>
              <Box display={'flex'} flexDir={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                <ListIcon as={GrCircleAlert} color='green.500' />
                <Text fontWeight={'bold'}>Nome:</Text>
                <Text fontWeight={'semibold'} ml={2}>{proposta?.nome}</Text>
              </Box>
            </ListItem>
            <ListItem>
              <Box display={'flex'} flexDir={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                <ListIcon as={GrCircleAlert} color='green.500' />
                <Text fontWeight={'bold'}>Banco:</Text>
                <Text fontWeight={'semibold'} ml={2}>{proposta?.banco_origi}</Text>
              </Box>
            </ListItem>
            <ListItem>
              <Box display={'flex'} flexDir={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                <ListIcon as={GrCircleAlert} color='green.500' />
                <Text fontWeight={'bold'}>Tipo:</Text>
                <Text fontWeight={'semibold'} ml={2}>{proposta?.tipo}</Text>
              </Box>
            </ListItem>
            <ListItem>
              <Box display={'flex'} flexDir={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                <ListIcon as={GrCircleAlert} color='green.500' />
                <Text fontWeight={'bold'}>Nº Proposta:</Text>
                <Text fontWeight={'semibold'} ml={2}>{proposta?.proposta}</Text>
              </Box>
            </ListItem>
            <ListItem>
              <Box display={'flex'} flexDir={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                <ListIcon as={GrCircleAlert} color='green.500' />
                <Text fontWeight={'bold'}>Valor Total do Contrato:</Text>
                <Text fontWeight={'semibold'} ml={2}>R$ {proposta?.entregue}</Text>
              </Box>
            </ListItem>
            <ListItem>
              <Box display={'flex'} flexDir={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                <ListIcon as={GrCircleAlert} color='green.500' />
                <Text fontWeight={'bold'}>Valor da Parcela:</Text>
                <Text fontWeight={'semibold'} ml={2}>R$ {proposta?.valor_parcela}</Text>
              </Box>
            </ListItem>
            <ListItem>
              <Box display={'flex'} flexDir={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                <ListIcon as={GrCircleAlert} color='green.500' />
                <Text fontWeight={'bold'}>Nª Parcela:</Text>
                <Text fontWeight={'semibold'} ml={2}>{proposta?.parcela}x</Text>
              </Box>
            </ListItem>
          </List>
        </Card>
      )}
    </>
  )
}
