/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Button, Tooltip, Input, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Flex, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Text, useToast } from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa6'
import { useCreateInstancia } from './hooks/useCreateInstancia'

export default function CriarNovaInstancia () {
  const toast = useToast()
  const { UseRequestCreateInstancia, instanceCreate } = useCreateInstancia()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [prioridade, setPrioridade] = React.useState(99)
  const [name, setName] = React.useState('')
  const [hook, setHook] = React.useState('')
  const handleChange = (value) => setPrioridade(value)

  const criarNovaInstancia = () => {
    UseRequestCreateInstancia({
      name,
      hooks: hook,
      priority: prioridade
    })
    setPrioridade(99)
    setName('')
    setHook('')
  }

  useEffect(() => {
    if (!instanceCreate) return
    if (instanceCreate?.status) {
      toast({
        title: 'Instância',
        description: 'Criado com sucesso!',
        status: 'success',
        duration: 9000,
        isClosable: true
      })
    } else {
      toast({
        title: 'Instância',
        description: 'Falha na criação, tente novamente!',
        status: 'info',
        duration: 9000,
        isClosable: true
      })
    }
  }, [instanceCreate])

  return (
    <>
      <Tooltip hasArrow label="Criar Nova Instância" placement='left'>
        <Button onClick={onOpen} pos={'fixed'} bottom={4} right={6} colorScheme='green'>
          <FaPlus />
        </Button>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Criação de Instâncias</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input value={name} onChange={(e) => { setName(e.target.value) }} variant={'flushed'} placeholder='Escolha o nome da instância'/>
            <Input value={hook} onChange={(e) => { setHook(e.target.value) }} variant={'flushed'} my={4} placeholder='Cole o webhook'/>
            <Flex alignItems={'center'} justifyContent={'center'} gap={2}>
              <Text fontWeight={'semibold'} mt={-1}>Prioridade</Text>
              <NumberInput display={'none'} maxW='100px' mr='2rem' value={prioridade} onChange={handleChange}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Slider
                flex='1'
                focusThumbOnChange={false}
                value={prioridade}
                onChange={handleChange}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb fontSize='sm' boxSize='32px' children={prioridade} />
              </Slider>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button onClick={criarNovaInstancia} colorScheme='green'>Salvar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
