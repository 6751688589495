import { useMutation, useQueryClient } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useEnvioDeMensagem = () => {
  const queryClient = useQueryClient()
  const envioDeMensagem = async (payload) => {
    const response = await apiAdmin.post('/v1/api/admin/atendimento/inserir/mensagem', payload)

    if (response.status === 200) {
      queryClient.invalidateQueries('atendimento_useObtendoMensagensAtendimentos')
      mutation.isSuccess = true
      mutation.isError = false
    }

    if (response.status !== 200) {
      mutation.isSuccess = false
      mutation.isError = true
    }
  }

  const mutation = useMutation(envioDeMensagem)

  const UseRequestuseEnvioDeMensagem = (payload) => {
    mutation.mutate(payload)
  }
  return {
    UseRequestuseEnvioDeMensagem,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export {
  useEnvioDeMensagem
}
