import React from 'react'

import { useMediaQuery, Box, Text, Stack, Skeleton, SkeletonCircle } from '@chakra-ui/react'

function SkeletonFormulario () {
  const [isMobile] = useMediaQuery('(max-width: 900px)')
  return (
    <Box margin={'10px auto'} padding='6' boxShadow='lg' bg='white' width={isMobile ? '350px' : '600px'} h={isMobile ? '550px' : '487px'} mb={isMobile ? '150px' : '-4px'} rounded={'2xl'} mr={isMobile ? '0' : '70px'}>
      <Text textAlign={'center'} fontWeight={'semibold'}>Aguarde página em carregamento !</Text>
      <Box margin={'10% auto'}>
        <Stack>
          <Skeleton height='10px' />
          <Skeleton height='10px' />
          <Skeleton height='10px' />
          <Skeleton height='10px' />
          <Skeleton height='10px' />
        </Stack>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} margin={'25px auto'} width={'100%'} >
          <SkeletonCircle size='10' />
          <SkeletonCircle size='10' />
          <SkeletonCircle size='10' />
          <SkeletonCircle size='10' />
          <SkeletonCircle size='10' />
          <SkeletonCircle size='10' />
        </Box>
        <Stack>
          <Skeleton height='10px' />
          <Skeleton height='10px' />
          <Skeleton height='10px' />
          <Skeleton height='10px' />
          <Skeleton height='10px' />
        </Stack>
      </Box>
    </Box>
  )
}

export default SkeletonFormulario
