import React, { useState } from 'react'
import { useGetGerenciador } from '../hooks/useGetGerenciador'
import { Box, Flex, Grid, GridItem, Select, Text } from '@chakra-ui/react'
import { useGetDepartamentos } from '../hooks/useGetDepartamentos'
import DetailsGerenciador from './DetailsGerenciador'
import CreateMenuChatBot from './CreateMenuChatBot'

export default function GerenciadorChatbot () {
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const { data: departamentos } = useGetDepartamentos()
  const { data } = useGetGerenciador(selectedDepartment)
  const resultUseGetGerenciador = data || []
  const departamentosFiltrados = departamentos?.departamentos || []

  return (
    <Box p={4}>
      <Flex mb={4}>
        <Select
          borderBottom={'1px solid gray'}
          variant={'flushed'}
          placeholder='Selecione um Departamento'
          value={selectedDepartment}
          onChange={(e) => setSelectedDepartment(e.target.value)}
        >
          {departamentosFiltrados &&
            departamentosFiltrados.map((item, index) => (
              <option key={index} value={item.id}>
                {item.nome}
              </option>
            ))}
        </Select>

      </Flex>

      <Grid templateColumns='repeat(4, 1fr)' gap={6}>
        {resultUseGetGerenciador?.map((item, index) => (
          <GridItem cursor={'pointer'} boxShadow={'xl'} rounded={'lg'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} key={index} w='100%' h='20' bg='white' p={4} >
            <Text fontWeight={'semibold'} textAlign={'left'}>{item.menu}</Text>
            <DetailsGerenciador
              idMenu={item.idMenu}
              menu={item.menu}
              idDepartamento={selectedDepartment}
            />
          </GridItem>
        ))}
      </Grid>
      <CreateMenuChatBot />
    </Box>
  )
}
