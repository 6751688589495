import { useMutation } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useEditarMensagem = () => {
  const editarMensagem = async (payload) => {
    await apiAdmin.put('/v1/api/admin/atendimento/editar-mensagem', payload)
  }

  const mutation = useMutation(editarMensagem)

  const UseRequestEditarMensagem = (idProtocolo, idMensagem, mensagem) => {
    mutation.mutate({
      idProtocolo,
      idMensagem,
      mensagem
    })
  }
  return {
    UseRequestEditarMensagem,
    isLoading: mutation.isLoading
  }
}

export { useEditarMensagem }
