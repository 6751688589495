import { useQuery } from 'react-query'

import apiAdmin from '../../../../services/apiAdmin'

const useGetReportMenu = () => {
  return useQuery(
    'chat_useGetReportMenu',
    async () => {
      const response = await apiAdmin.get('/v1/api/admin/dashboard/reportPorMenu')
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000 * 1000 * 5,
      refetchInterval: 5000
    }
  )
}

export { useGetReportMenu }
