import { useQuery } from 'react-query'

import api from '../../../../services/api'

const useListagemAniversariantes = (payload) => {
  return useQuery(
    ['chat_useListagemAniversariantes', { payload }],
    async () => {
      const response = await api.get(`/v1/api/public/aniversariantes-dia?idParceiro=${payload}`)
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 60000,
      enabled: !!payload.h
    }
  )
}

export { useListagemAniversariantes }
