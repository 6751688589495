/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  Card, CardBody, PinInput,
  PinInputField,
  Box,
  HStack,
  Stack,
  Text,
  useMediaQuery,
  CircularProgress
} from '@chakra-ui/react'

import { executar } from '../../../features/chatbotWha/propostaSlice'
import useGetAutenticacaoProposta from '../hooks/useGetAutenticacaoProposta'

function Formulario ({ token }) {
  const [isMobile] = useMediaQuery('(max-width: 900px)')
  const [senha, setSenha] = useState('')
  const dispatch = useDispatch()

  const { UseRequestAutenticacaoProposta, isLoading, isError, isSuccess } = useGetAutenticacaoProposta()

  const handlerObtendoSenha = (value) => {
    setSenha(value)
    if (value.length === 6) {
      UseRequestAutenticacaoProposta(token, value)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(executar({ abrirDadosProposta: true }))
    }
  }, [dispatch, isSuccess])

  useEffect(() => {
    if (isError) {
      setSenha('')
    }
  }, [isError])

  return (
    <Box paddingBlock={isMobile ? '120px' : '0px'} flex='1' display='flex' justifyContent='flex-start' mt={isMobile ? 4 : '-5px'}>
      <Stack spacing={2} p={isMobile ? '2.5' : '5'}>
        <Card shadow={'md'} p={0}>
          <CardBody shadow={'md'} borderRadius='lg' p={0}>
            <Box m={isMobile ? 4 : 100} justifySelf='center' p={0}>
              <Text fontSize={isMobile ? '14px' : '2xl'} fontWeight='bold' textAlign={'center'} mt={10}>
                Digite o código de segurança 🔐
              </Text>
              <Text fontSize='14' fontWeight='400' color='CaptionText' textAlign={'center'} mb={10}>
                Seus dados estão seguros conosco, não compartilhamos com ninguém.
              </Text>
              <HStack flex={1} align={'center'} justify={'center'} mt={4} p={0}>
                <Card p={0}>
                  {!isLoading
                    ? (
                    <CardBody p={0} gap={5}>
                      <PinInput fontWeight='bold' onChange={handlerObtendoSenha} value={senha} isInvalid={isError} >
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    </CardBody>
                      )
                    : (
                    <Box shadow={'none'} display={'flex'} flexDir={'column'} alignItems={'center'} bg={'transparent'} p={4} gap={5}>
                      <CircularProgress isIndeterminate color='green.300' />
                      <Text bg={'transparent'}>Aguarde validando senha!</Text>
                    </Box>
                      )}
                </Card>
              </HStack>

              <Text fontSize={isMobile ? '14px' : '18'} fontWeight='400' color={'black.400'} mt={10} textAlign={'justify'} paddingInline={isMobile ? 4 : 0}>
                Sua senha foi enviada para seu WhatsApp. Ela contém 6 dígitos. Ex: 123456
              </Text>
              <Text textAlign={'center'} fontSize={isMobile ? '14px' : '20' }fontWeight='600' color={'blackAlpha'} mt={4} paddingInline={4}>
                Esse link contém as informações de sua Proposta de Empréstimo.
              </Text>
            </Box>
          </CardBody>
        </Card>
      </Stack>
    </Box>
  )
}

export default Formulario
