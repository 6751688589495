import { useMutation, useQueryClient } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'

const useAlterarStatus = () => {
  const queryClient = useQueryClient()
  const alterarStatus = async (payload) => {
    const response = await apiAdmin.put('/v1/api/admin/atendimento/alterarStatus', payload)

    if (response.status === 200) {
      queryClient.invalidateQueries('atendimento_alterarStatusChat')
    }
  }

  const mutation = useMutation(alterarStatus)

  const UseRequestAlterarStatus = (id) => {
    mutation.mutate({
      id,
      idDepartamento: 3
    })
  }
  return {
    UseRequestAlterarStatus,
    isLoading: mutation.isLoading
  }
}

export { useAlterarStatus }
