import React from 'react'

import { Box, useColorMode } from '@chakra-ui/react'

import CreateNovaTrilha from './components/CreateNovaTrilha'
import TableTrilhas from './components/TableTrilhas'

export default function Gerenciamento () {
  const { colorMode } = useColorMode()
  return (
    <Box overflow={'hidden'} h={'100vh'} bg={colorMode === 'light' ? 'white' : 'gray.600'} padding={4}>
      <TableTrilhas/>
      <CreateNovaTrilha/>
    </Box>
  )
}
