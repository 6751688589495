import { useQuery } from 'react-query'
import apiAdmin from '../../../../services/apiAdmin'

const useObtendoUsuariosAgendados = (idAgendaCompromisso) => {
  console.log(idAgendaCompromisso, 'useObtendoUsuariosAgendados')
  return useQuery(
    'atendimento_useObtendoUsuariosAgendados',
    async () => {
      const response = await apiAdmin.get(`/v1/api/admin/rh/calendario-compromissos/usuarios-agendados/${idAgendaCompromisso}`)
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000,
      refetchInterval: 5000
    }
  )
}

export { useObtendoUsuariosAgendados }
