import { useMutation } from 'react-query'
import { useState } from 'react'
import api from '../../api/api'

const useTesteInstancia = (instancia) => {
  const [instanceTeste, setIntanceTeste] = useState(null)

  const testeInstancia = async (payload) => {
    const response = await api.post(`/api/${instancia}/sendMessage`, payload)
    return response.data
  }

  const mutation = useMutation(testeInstancia, {
    onSuccess: (data) => {
      setIntanceTeste(data)
    },
    onError: (error) => {
      console.error('onError:', error)
    }
  })

  const UseRequestTesteInstancia = (payload) => {
    mutation.mutate(payload)
  }

  return {
    UseRequestTesteInstancia,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    instanceTeste
  }
}

export { useTesteInstancia }
