import React, { useState } from 'react'
import { FcCollaboration } from 'react-icons/fc'
import { useSelector } from 'react-redux'

import {
  Button, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay,
  Icon, Select, Tooltip, useDisclosure
} from '@chakra-ui/react'

import { useAlterarStatus } from '../hooks/useAlterarStatus'
import { useObtendoStatusAgente } from '../hooks/useObtendoStatusAgente'
import DialogAlterarStatus from './DialogAlterarStatus'

export default function AlterarStatusDrawer () {
  const { statusAtendimento } = useSelector((state) => state.atendimento)
  const { UseRequestAlterarStatus } = useAlterarStatus()
  const { data } = useObtendoStatusAgente()
  const listaStatus = data || []
  const [selectIdStatus, setSelectIdStatus] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const btnRef = React.useRef()

  const capturarStatusSelecionado = (event) => {
    setSelectIdStatus(event.target.value)
  }

  const alterarStatus = () => {
    UseRequestAlterarStatus(selectIdStatus)
  }
  return (
    <>
      <Tooltip hasArrow placement='top-end' label='Alterar Status'>
        <Button pos={'absolute'} left={-14} ref={btnRef} colorScheme='gray' onClick={onOpen}>
          <Icon as={FcCollaboration} />
        </Button>
      </Tooltip>
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader mt={4}>Alterar status de atendimento</DrawerHeader>
          <DrawerHeader fontSize={14}>Status atual do departamento: </DrawerHeader>
          <DrawerHeader mt={-8} fontSize={14}>{statusAtendimento.statusDepartamento}</DrawerHeader>

          <DrawerBody>
            <Select value={selectIdStatus} onChange={capturarStatusSelecionado} variant='outline'>
              <option disabled value={''}>{'Selecione um novo status'}</option>
              {listaStatus && listaStatus.map((listaStatus) =>
                <>
                  <option value={listaStatus.id}>{listaStatus.statusDepartamento}</option>
                </>
              )}
            </Select>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancelar
            </Button>

            {selectIdStatus &&
              <DialogAlterarStatus alterarStatus={alterarStatus} />
            }

          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
