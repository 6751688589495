import { Button, Flex, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Select, Stack, Text, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FaCheck, FaPlus } from 'react-icons/fa'
import { useGetDepartamentos } from '../hooks/useGetDepartamentos'
import { useGetMenu } from '../hooks/useGetMenu'
import { useGetProcessamentos } from '../hooks/useGetProcessamentos'
import CreateDepartamento from './CreateDepartamento'
import CreateMenu from './CreateMenu'
import { useCreateNovaConfiguracao } from '../hooks/useCreateNovaConfiguracao'

export default function CreateMenuChatBot () {
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [selectedProcess, setSelectedProcess] = useState('')
  const [selectedMode, setSelectedMode] = useState('')
  const [selectedMenu, setSelectedMenu] = useState('')
  const [perg, setPerg] = useState('')
  const [tempPerg, setTempPerg] = useState('')
  const [resp, setResp] = useState('')
  const [tempResp, setTempResp] = useState('')
  const [tag, setTag] = useState('')
  const [tempTag, setTempTag] = useState('')
  const [privado, setPrivado] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: departamentos } = useGetDepartamentos()
  const { data: processamentos } = useGetProcessamentos()
  const { data: menus } = useGetMenu(selectedDepartment)
  const departamentosFiltrados = departamentos?.departamentos || []
  const { UseRequestCreateNovaConfiguracao } = useCreateNovaConfiguracao()

  const handleCheckClickPerg = () => {
    setPerg(tempPerg)
  }

  const handleCheckClickResp = () => {
    setResp(tempResp)
  }

  const handleCheckClickTag = () => {
    setTag(tempTag)
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  const clearAllItems = () => {
    setSelectedDepartment('')
    setSelectedProcess('')
    setSelectedMode('')
    setSelectedMenu('')
    setPrivado('')
    setPerg('')
    setResp('')
    setTag('')
    setTempPerg('')
    setTempResp('')
    setTempTag('')
    onClose()
  }

  return (
    <>
      <Button pos={'fixed'} bottom={4} bg={'#229544'} colorScheme='green' right={6} onClick={onOpen}>
        <FaPlus />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Criar nova funcionalidade</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {!selectedDepartment &&
              <>
                <Text>1º Passo: Selecione um departamento</Text>
                <Select
                  value={selectedDepartment}
                  onChange={(e) => setSelectedDepartment(e.target.value)}>
                  <option value=''>-- Selecionar Departamento --</option>
                  {departamentosFiltrados.map((dep) => (
                    <option
                      key={dep.id}
                      value={dep.id}
                      style={{ display: dep.ativo ? 'initial' : 'none' }}>
                      {dep.nome}
                    </option>
                  ))}
                </Select>
              </>
            }

            {selectedDepartment && !selectedMenu &&
              <>
                <Text>2º Passo: Selecione um menu</Text>
                <Select
                  value={selectedMenu}
                  onChange={(e) => setSelectedMenu(e.target.value)}>
                  <option value='#'>-- Selecionar Menu --</option>
                  {menus?.menus.map((dep) => (
                    <option
                      key={dep.id}
                      value={dep.id}>
                      {dep.opcao}
                    </option>
                  ))}
                </Select>
              </>
            }

            {selectedMenu && !selectedProcess &&
              <>
                <Text>3º Passo: Selecione um tipo de processamento</Text>
                <Select
                  w={'100%'}
                  value={selectedProcess}
                  onChange={(e) => setSelectedProcess(e.target.value)}>
                  <option value='#'>-- Selecionar Tipo de Processamento --</option>
                  {processamentos?.processamentos.map((dep) => (
                    <option
                      key={dep.idProcessamento}
                      value={dep.idProcessamento}>
                      {truncateText(`${dep.tipoDeAcao} - ${dep.mensagem}`, 40)}
                    </option>
                  ))}
                </Select>
              </>
            }

            {selectedProcess && !selectedMode &&
              <>
                <Text>4º Passo: Selecione o modo de interação</Text>
                <Select
                  value={selectedMode}
                  onChange={(e) => setSelectedMode(e.target.value)}>
                  <option value='#'>-- Selecionar Modo de Interação --</option>
                  <option
                    value={2}>
                    Botão
                  </option>
                  <option
                    value={1}>
                    Caixa de Texto
                  </option>
                </Select>
              </>
            }

            {selectedMode && !perg && (
              <>
                <Text>5º Passo: Cria sua pergunta</Text>
                <Flex gap={2}>
                  <Input
                    value={tempPerg}
                    onChange={(e) => setTempPerg(e.target.value)} />
                  <Button colorScheme='green' bg={'#229544'} onClick={handleCheckClickPerg}>
                    <FaCheck />
                  </Button>
                </Flex>
              </>
            )}

            {perg && !resp && (
              <>
                <Text>6º Passo: Cria a resposta da sua pergunta</Text>
                <Flex gap={2}>
                  <Input
                    value={tempResp}
                    onChange={(e) => setTempResp(e.target.value)} />
                  <Button colorScheme='green' bg={'#229544'} onClick={handleCheckClickResp}>
                    <FaCheck />
                  </Button>
                </Flex>
              </>
            )}

            {resp && !tag && (
              <>
                <Text>7º Passo: Cria a tag de exibição</Text>
                <Flex gap={2}>
                  <Input
                    value={tempTag}
                    onChange={(e) => setTempTag(e.target.value)} />
                  <Button colorScheme='green' bg={'#229544'} onClick={handleCheckClickTag}>
                    <FaCheck />
                  </Button>
                </Flex>
              </>
            )}

            {tag && (
              <>
                <Text>8º Passo: Selecionar visibilidade</Text>
                <RadioGroup onChange={setPrivado} value={privado}>
                  <Stack direction='row'>
                    <Radio value='false'>Visivel</Radio>
                    <Radio value='true'>Invisivel</Radio>
                  </Stack>
                </RadioGroup>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            {!selectedDepartment && <CreateDepartamento />}
            {selectedDepartment && !selectedMenu && <CreateMenu idDepartamento={selectedDepartment} />}
            <Button colorScheme='red' mx={3} onClick={clearAllItems}>
              Cancelar
            </Button>
            <Button onClick={() => {
              UseRequestCreateNovaConfiguracao({
                idMenu: selectedMenu,
                idDepartamento: selectedDepartment,
                config_resposta: {
                  resposta: resp,
                  privado,
                  idProcessamento: selectedProcess
                },
                config_pergunta: {
                  pergunta: perg,
                  ferramenta: selectedMode,
                  tag
                }
              })
              clearAllItems()
            }} display={privado ? 'initial' : 'none'}>Finalizar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
