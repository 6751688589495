import React from 'react'

import { Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorMode } from '@chakra-ui/react'

import { useObtendoQuantidadeAtendimentoAgenteStatus } from '../hooks/useObtendoQuantidadeAtendimentoAgenteStatus'

export default function TableTrilhaAgenteStatus () {
  const { colorMode } = useColorMode()
  const { data } = useObtendoQuantidadeAtendimentoAgenteStatus()
  const dadosAtendimentoAgenteStatus = data
  return (
    <>
      <Heading mt={8} fontSize={18} ml={4}>Quantidade de Status de Atendimento </Heading>
      <Text fontSize={12} fontWeight={'semibold'} color={colorMode === 'light' ? 'black' : 'gray.200'} mb={2} ml={4} >Acompanhe a quantidade de status de  atendimentos de realizados por seus agentes</Text>
      <TableContainer mb={8} rounded={'2xl'} bg={colorMode === 'light' ? 'white' : 'gray.600'} padding={'10px 2px'} h={'150px'} overflowY={'scroll'} maxHeight={'400px'}>
        <Table variant='striped' colorScheme='green' size='sm'>
          <Thead>
            <Tr>
              <Th>Nome do Atendente</Th>
              <Th isNumeric>Atendimentos Fechados</Th>
              <Th isNumeric>Atendimentos Cancelados</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dadosAtendimentoAgenteStatus
              ? (
                  dadosAtendimentoAgenteStatus.map((dados) => (
                <Tr fontWeight={'bold'} key={dados.id_acesso}>
                  <Td>{dados.nome}</Td>
                  <Td paddingInlineEnd={'10'} isNumeric>{dados.qtde_atendimentos_fechados}</Td>
                  <Td paddingInlineEnd={'10'} isNumeric>{dados.qtde_atendimentos_cancelados}</Td>
                </Tr>

                  ))
                )
              : (
              <Tr fontWeight={'bold'} color={'black'} >
                <Td></Td>
                <Td>Nenhum Atendimento </Td>
                <Td paddingInlineEnd={'10'} isNumeric>0</Td>
              </Tr>
                )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}
