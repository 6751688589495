/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Box, Button, Flex, Heading, Image, Spinner, Text } from '@chakra-ui/react'
import { BsCake } from 'react-icons/bs'
import { useListagemAniversariantes } from './hooks/useListagemAniversariantes'
import img from './images/cake.png'
import Lista from './components/lista'
import { motion } from 'framer-motion'
import logo from './images/image.png'

export default function Aniversario () {
  const [showAniversariante, setShowAniversariante] = useState(false)
  const getIdAcessoFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('')
  }

  const idAcesso = getIdAcessoFromUrl()
  const { data, isLoading, isError, refetch } = useListagemAniversariantes(idAcesso)

  const HeadingWithAnimation = ({ children }) => {
    return (
      <motion.div
        initial={{ opacity: 0, x: '100%' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Heading w={'100%'} textAlign={'left'} my={4}>
          {children}
        </Heading>
      </motion.div>
    )
  }

  const TextWithAnimation = ({ children }) => {
    return (
      <motion.div
        initial={{ opacity: 0, x: '100%' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Text textAlign={'justify'} fontWeight={'semibold'}>
          {children}
        </Text>
      </motion.div>
    )
  }

  const ImageWithAnimation = ({ src }) => {
    return (
      <motion.div
        initial={{ opacity: 0, y: '100px' }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Image src={src} />
      </motion.div>
    )
  }

  const ButtonWithAnimation = ({ children, onClick }) => {
    return (
      <motion.button
        initial={{ opacity: 0, x: '100%' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.7 }}
      >
        <Button colorScheme='green'
          mt={4}
          bg={'#229544'}
          color={'white'}
          gap={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          onClick={onClick}>
          {children}
        </Button>
      </motion.button>
    )
  }

  if (isLoading) {
    return (
      <Box px={5} w={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDir={'column'}>
        <Spinner size={'xl'} />
      </Box>
    )
  }

  if (showAniversariante) {
    return (
      <Lista data={data} />
    )
  }

  if (isError || !data) {
    refetch()
    return (
      <Box px={5} w={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDir={'column'}>
        <Text>Não foi possível carregar a lista de aniversariantes, tente novamente mais tarde. 😅</Text>
      </Box>
    )
  }

  return (
    <Box overflow={'hidden'} px={5} w={'100%'} display={showAniversariante ? 'none' : 'flex'} alignItems={'center'} justifyContent={'center'} flexDir={'column'}>
      <Image src={logo} w={'100px'}/>
      <HeadingWithAnimation>Olá {data.usuarioSessao.nome}</HeadingWithAnimation>
      <Flex mt={-20} alignItems={'center'} justifyContent={'center'}>
        <ImageWithAnimation src={img}/>
        <Box w={'45%'}>
          <TextWithAnimation>
            Chegou a hora de celebrar aqueles que tornam nossos dias mais especiais! Utilize este espaço para enviar seus votos de felicidade e alegria aos seus amigos em seus aniversários. Nossa conexão é fortalecida por cada palavra compartilhada, e nada é mais gratificante do que celebrar juntos os momentos importantes da vida.
          </TextWithAnimation>
          <TextWithAnimation>
            Clique no botão abaixo para carregar a lista de aniversariantes do dia e espalhar seu carinho através de uma mensagem! 💖
          </TextWithAnimation>
          <ButtonWithAnimation onClick={() => { setShowAniversariante(true) }}>
            <Text>Carregar Aniversariantes</Text>
            <BsCake size={22} />
          </ButtonWithAnimation>
        </Box>
      </Flex>
    </Box>

  )
}
