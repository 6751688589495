import React from 'react'

import { Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorMode } from '@chakra-ui/react'

import { useObtendoQuantidadeAtendimentoAgenteMes } from '../hooks/useObtendoQuantidadeAtendimentoAgenteMes'

export default function TableTrilhaAgenteMes () {
  const { colorMode } = useColorMode()
  const { data } = useObtendoQuantidadeAtendimentoAgenteMes()
  const dadosAtendimentoAgenteMes = data
  return (
    <>
      <Heading mt={8} fontSize={18} ml={4}>Quantidade de Atendimento por Agente Mensalmente</Heading>
      <Text fontSize={12} fontWeight={'semibold'} color={colorMode === 'light' ? 'black' : 'gray.200'} mb={2} ml={4} >Acompanhe a quantidade de atendimentos de realizados por seus agentes</Text>
      <TableContainer rounded={'2xl'} bg={colorMode === 'light' ? 'white' : 'gray.600'} padding={'10px 2px'} h={'150px'} overflowY={'scroll'} maxHeight={'400px'}>
        <Table variant='striped' colorScheme='green' size='sm'>
          <Thead>
            <Tr>
              <Th>Nome do Atendente</Th>
              <Th isNumeric>Atendimentos Fechados</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dadosAtendimentoAgenteMes
              ? (
                  dadosAtendimentoAgenteMes.map((dados) => (
                <Tr fontWeight={'bold'} key={dados.id_acesso}>
                  <Td>{dados.nome}</Td>
                  <Td paddingInlineEnd={'10'} isNumeric>{dados.qtdeAtendimentosFechados}</Td>
                </Tr>

                  ))
                )
              : (
              <Tr fontWeight={'bold'}>
                <Td>Nenhuma Trilha Selecionada</Td>
                <Td paddingInlineEnd={'10'} isNumeric>0</Td>
              </Tr>
                )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}
