import { useMutation } from 'react-query'

import apiAdmin from '../../../../services/apiAdmin'

const useRequestAdicionarEventoAgenda = () => {
  const alterar = async (obj) => {
    await apiAdmin.put('/v1/api/public/adicionar-evento-agenda', obj)
  }

  const mutation = useMutation(alterar)

  const adicionarEVentoAgenda = async (formData) => {
    await mutation.mutateAsync(formData)
  }

  return {
    adicionarEVentoAgenda,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export default useRequestAdicionarEventoAgenda
