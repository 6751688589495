/* eslint-disable react/no-children-prop */
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { SearchIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputRightElement, Box, Text, VStack, Stack, Avatar, Flex, Card, Divider, useColorMode } from '@chakra-ui/react'

import { obtendoDadosAtendimento } from '../../../../features/atendimento/atendimentoSlice'
import { useObtendoAtendimentos } from '../hooks/useObtendoAtendimentos'
import SkeletonSidebarContatos from './SkeletonSidebarContatos'
import FiltrosDataAtendimentos from './FiltrosDataAtendimentos'

function SidebarListaContatos () {
  const { colorMode } = useColorMode()
  const { dadosAtendimento } = useSelector((state) => state.atendimento)
  const { features } = useSelector((state) => state.perfil)
  const { isLoading, data } = useObtendoAtendimentos()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const chaveAuth = features || []

  const atendimentos = data || []

  const dataInicialFiltro = localStorage.getItem('dataInicial')
  const dataFinalFiltro = localStorage.getItem('dataFinal')

  function formatarData (data) {
    const [ano, mes, dia] = data.split('-')
    return `${dia}/${mes}/${ano}`
  }

  const clickAtendimento = (element) => {
    queryClient.removeQueries('atendimento_useObtendoMensagensAtendimentos')
    queryClient.invalidateQueries('atendimento_useObtendoAtendimentos')
    dispatch(obtendoDadosAtendimento({ dadosAtendimento: element }))
  }

  const highlightText = (text, highlight) => {
    const regex = new RegExp(`(${highlight})`, 'gi')
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase()
        ? (
          <span key={index} style={{ fontWeight: 'bold', background: 'yellow' }}>
            {part}
          </span>
          )
        : (
          <span key={index}>{part}</span>
          )
    )
  }
  const [searchTerm, setSearchTerm] = useState('')

  const handleChange = (event) => { setSearchTerm(event.target.value) }

  const filteredElements = atendimentos.filter((element) => element.usuario.toLowerCase().includes(searchTerm.toLowerCase()))

  return (

    <Box mt='-7px' h={'88vh'} p={2} bg={colorMode === 'light' ? 'white' : 'gray.600'} color={colorMode === 'light' ? 'black' : 'white'} width='100%'>
      <>
        <Text color={colorMode === 'light' ? 'gray.700' : 'white'}>Filtro Aplicado:  {formatarData(dataFinalFiltro)} - {formatarData(dataInicialFiltro)} </Text>
        <InputGroup mt={4} gap={1} width={'100%'} display={'flex'} alignItems={'center'}>
          <FiltrosDataAtendimentos />
          <InputRightElement children={<SearchIcon />} />
          <Input
            value={searchTerm}
            onChange={handleChange}
            type='text'
            placeholder='Pesquisar'
          />
        </InputGroup>
      </>
      <VStack align='stretch'>
        <Box h='72vh' w='100%' overflow='auto' mt={1}>
          {isLoading
            ? (
              <SkeletonSidebarContatos />
              )
            : (
                filteredElements.length > 0
                  ? (
                      filteredElements.map((element) => (
                    <Card color={colorMode === 'light' ? 'gray.500' : 'white'} onClick={() => clickAtendimento(element)} key={element.idProtocolo} size={'sm'} bg={colorMode === 'light' ? dadosAtendimento?.idProtocolo === element.idProtocolo ? 'gray.300' : 'gray.100' : dadosAtendimento?.idProtocolo === element.idProtocolo ? 'gray.400' : 'gray.500'} boxShadow={'none'} _hover={{
                      bg: 'gray.200',
                      cursor: 'pointer'
                    }}>
                      <VStack alignItems={'flex-start'} textAlign={'left'} spacing='4' px={2} py={4}>
                        <Flex flex='1' gap='2' alignItems='flex-start' flexWrap='wrap'>
                          <Stack direction='row' spacing={2}>
                            <Avatar src={element.foto}>
                            </Avatar>
                          </Stack>
                          <Box display={'flex'} alignItems={'start'} justifyContent={'space-between'} key={element.usuario} w={'300px'}>
                            <Box>
                              <Text color={'black'} fontWeight={'bold'} fontSize={'12px'} >
                                {highlightText(element.usuario, searchTerm)}
                              </Text>
                              <Flex flexDir={chaveAuth.includes('filter_atendimento_todosAtendimentos') ? 'column' : 'row'} alignItems={chaveAuth.includes('filter_atendimento_todosAtendimentos') ? 'flex-start' : 'center'} justifyContent={'space-between'}>
                                <Text color={colorMode === 'light' ? 'black' : 'black'} fontSize={'10px'} >{chaveAuth.includes('filter_atendimento_todosAtendimentos') ? element.agente : element.tipoParceiro}
                                  {chaveAuth.includes('filter_atendimento_todosAtendimentos') && <Divider />}
                                </Text>

                                <Text color={'black'} textTransform={'capitalize'} fontSize={'10px'} >{element.trilha}</Text>
                              </Flex>
                            </Box>
                          </Box>
                        </Flex>
                      </VStack>
                    </Card>
                      ))
                    )
                  : (
                  <Text textAlign={'center'}>Nenhum resultado encontrado.</Text>
                    )
              )}
        </Box>
      </VStack>
    </Box>
  )
}

export default React.memo(SidebarListaContatos)
