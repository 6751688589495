import React from 'react'
import { useSelector } from 'react-redux'

import {
  Box,
  Image,
  useMediaQuery
} from '@chakra-ui/react'

function Logo () {
  const [isMobile] = useMediaQuery('(max-width: 900px)')
  const { propostas } = useSelector((state) => state.proposta)
  return (
    <Box display='flex' justifyContent='center' alignItems='center' mb={isMobile ? 4 : '5px'} gap={2}>
      <Image zIndex={99} src={propostas.urlLogo} alt='Logo' width={90} />
    </Box>
  )
}

export default Logo
