import React, { useState } from 'react'
import { Box, Button, Flex, Heading, Image, Text, Textarea } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useEnviarMensagem } from './hooks/useEnviarMensagem'
import img from './images/cake.png'
import logo from './images/image.png'
import data from './data.json'

export default function AniversarioCanal () {
  const { UseRequestEnviarMensagem } = useEnviarMensagem()
  const [mensagem, setMensagem] = useState('')
  const { search } = useLocation()
  const [mensagemComPalavrasOfensivas, setMensagemComPalavrasOfensivas] = useState(false)

  const extrairParametros = (search) => {
    const params = {}
    const queryString = search.slice(1)
    const pairs = queryString.split('&')
    pairs.forEach((pair) => {
      const [key, value] = pair.split('=')
      params[key] = decodeURIComponent(value)
    })
    return params
  }

  const queryParams = extrairParametros(search)
  const celular = queryParams.i
  const responsavel = queryParams.nomeAniversariante

  const handleTextareaChange = (event) => {
    const texto = event.target.value
    const palavroes = data.palavroes

    let contemPalavrasOfensivas = false

    palavroes.forEach((palavra) => {
      const regex = new RegExp(`\\b${palavra}\\b`, 'gi')
      if (texto.match(regex)) {
        contemPalavrasOfensivas = true
      }
    })

    setMensagem(texto)
    if (contemPalavrasOfensivas) {
      localStorage.setItem('mensagemComPalavrasOfensivas', 'true')
      setMensagemComPalavrasOfensivas(true)
    } else {
      localStorage.removeItem('mensagemComPalavrasOfensivas')
      setMensagemComPalavrasOfensivas(false)
    }
  }

  const verification = localStorage.getItem('send') === celular

  const sendMSG = () => {
    if (localStorage.getItem('mensagemComPalavrasOfensivas')) {
      return
    }

    localStorage.setItem('send', celular)
    const payload = {
      celular,
      mensagem,
      responsavel
    }
    UseRequestEnviarMensagem(payload)
    setMensagem('')
  }

  return (
    <Flex pos={'relative'} w={'100%'} h={'100vh'} rounded={'xl'} bg={'white'} p={4} gap={2} alignItems={'center'} justifyContent={'space-between'} flexDir={'column'}>
      <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'}>
        <Image src={logo} width={'100px'} mt={-5} />
        <Heading size={'md'}>{verification ? 'Agradecimentos enviados!' : 'Enviar agradecimentos'}</Heading>
      </Flex>
      <Image top={verification && 20} w={'300px'} pos={verification ? 'absolute' : 'relative'} src={img} />
      <Box p={5} w={'100%'} pos={'relative'}>
        <Text pos={'absolute'} top={-10} fontSize={14} display={mensagemComPalavrasOfensivas ? 'initial' : 'none'} color={'red'}>A mensagem contém palavras ofensivas. <br /> Por favor, remova ou altere-as antes de enviar.</Text>
        <Textarea bg={'gray.100'} placeholder='Digite seus agradecimentos...' value={mensagem} onChange={handleTextareaChange} style={{ color: localStorage.getItem('mensagemComPalavrasOfensivas') ? 'red' : 'inherit' }}></Textarea>
        <Button disabled={mensagemComPalavrasOfensivas} colorScheme={mensagemComPalavrasOfensivas ? 'red' : 'green'} bg={mensagemComPalavrasOfensivas ? 'red' : '#229544'} pointerEvents={mensagemComPalavrasOfensivas ? 'none' : 'all'} my={5} w={'100%'} onClick={sendMSG}>Enviar</Button>
      </Box>
    </Flex>
  )
}
