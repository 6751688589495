/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  VStack, Text,
  CircularProgress,
  HStack,
  Select,
  useDisclosure
} from '@chakra-ui/react'

import { useGetConfirmacaoProposta } from '../hooks/useGetConfirmacaoProposta'

function DialogoProposta ({
  setPropostaConfirmada,
  token
}) {
  const cancelRef = React.useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [statusConfirmacao, setStatusConfirmacao] = useState(null)
  const [motivoRecusa, setMotivoRecusa] = useState('')
  const { UseRequestConfirmacaoProposta, isSuccess, isError, isLoading } = useGetConfirmacaoProposta()

  const executandoConfirmacao = () => {
    if (statusConfirmacao || motivoRecusa) {
      onClose()
      UseRequestConfirmacaoProposta(token, statusConfirmacao, motivoRecusa)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      setPropostaConfirmada(true)
    }
    if (isError) {
      setPropostaConfirmada(false)
    }
  }, [isSuccess, isError, setPropostaConfirmada])

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {statusConfirmacao ? 'Confirmar Proposta' : 'Recusar Proposta'}
            </AlertDialogHeader>

            <AlertDialogBody fontWeight={'semibold'}>
              {statusConfirmacao
                ? 'Você realmente deseja CONFIRMAR a proposta?'
                : 'Você realmente deseja RECUSAR a proposta?'}
              {!statusConfirmacao && (
                <Select
                  placeholder='Selecione o motivo da recusa'
                  mt={4}
                  value={motivoRecusa}
                  onChange={(e) => setMotivoRecusa(e.target.value)}
                >
                  <option value='Proposta não solicitada'>Proposta não solicitada</option>
                  <option value='Informações divergentes'>Informações divergentes</option>
                  <option value='Telefone não me pertence'>Telefone não me pertence</option>
                </Select>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <HStack>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  colorScheme={statusConfirmacao ? 'green' : 'red'}
                  onClick={executandoConfirmacao}
                  ml={3}
                  isDisabled={!statusConfirmacao && !motivoRecusa}
                >
                  {statusConfirmacao ? 'Confirmar' : 'Recusar'}
                </Button>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {isLoading
        ? (
          <VStack>
            <CircularProgress size='80px' isIndeterminate color='green.300' mb={6} />
            <Text textAlign={'center'} fontWeight={'bold'} fontSize={'20px'}>Salvando dados da confirmação!</Text>
          </VStack>
          )
        : (

          <>
            <Button onClick={() => {
              onOpen()
              setStatusConfirmacao(true)
            }} colorScheme='green'>Confirmar</Button>
            <Button onClick={() => {
              onOpen()
              setStatusConfirmacao(false)
            }} colorScheme='red'>Recusar</Button>
          </>
          )}
    </>
  )
}

export default DialogoProposta
