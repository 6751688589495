import { useMutation } from 'react-query'
import api from '../../../../services/api'

const useEnviarMensagem = () => {
  const envioDeMensagem = async (payload) => {
    await api.post('/v1/api/public/enviar-mensagem-aniversario', payload)
  }

  const mutation = useMutation(envioDeMensagem)

  const UseRequestEnviarMensagem = (payload) => {
    mutation.mutate(payload)
  }

  return {
    UseRequestEnviarMensagem,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess
  }
}

export { useEnviarMensagem }
