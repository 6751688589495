import { useQuery } from 'react-query'

import apiAdmin from '../../../../../services/apiAdmin'
const useObetendoRoadMap = (payload) => {
  return useQuery(
    'atendimento_useObtendoRoadMap',
    async () => {
      const response = await apiAdmin.get(`/v1/api/admin/atendimento/obtendo/roadmap/?protocolo=${payload}`)
      return response.data
    },
    {
      enabled: !!payload
    }
  )
}

export { useObetendoRoadMap }
