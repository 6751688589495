import React from 'react'
import { Box, Card, CardBody, CardHeader, Grid, Text, useColorMode } from '@chakra-ui/react'
import { useGetCampanhas } from './hooks/useObtendoCampanhasPorIdAcesso'
import { ModalTrilha } from './components/ModalTrilha'

export default function GerenciamentoWhatsapp () {
  const { colorMode } = useColorMode()
  const { data: campanhaData, isLoading: campanhaLoading, isError: campanhaError } = useGetCampanhas()

  return (
    <Box w="100vw" h="100vh" bg={colorMode === 'light' ? 'white' : 'gray.600'} padding={3}>
      <Card bg="transparent" maxH="20rem" boxShadow={'none'} border={'none'}>
        <CardHeader fontWeight={800} fontSize="xl" display="flex" justifyContent="space-between">
          <div>
            Fluxo de trilhas
            <Text color={colorMode === 'light' ? 'gray.500' : 'gray.400'} fontWeight={500} fontSize="sm">
              Uma listagem de todas as trilhas ativas no sistema do ChatBot atualmente
            </Text>
          </div>
        </CardHeader>
        <CardBody>
          <Grid templateColumns="repeat(4, 1fr)" p={4} gap={4} w="100%" h="100%">
            {campanhaLoading && <p>Carregando...</p>}
            {campanhaError && <p>Erro ao carregar!</p>}
            {campanhaData && campanhaData.map((campanha) => (
              <Card key={campanha.id} bg={colorMode === 'light' ? 'gray.200' : 'gray.700'}>
                <CardHeader textTransform="uppercase" fontWeight={700}>{campanha.nome}</CardHeader>
                <CardBody display={'flex'} flexDir={'column'} justifyContent={'flex-end'}>
                  <ModalTrilha campanha={campanha} />
                </CardBody>
              </Card>
            ))}
          </Grid>
        </CardBody>
      </Card>
    </Box>
  )
}
