import { useMutation } from 'react-query'
import { useState } from 'react'
import api from '../../api/api'

const useResetInstancia = () => {
  const [instanceReset, setIntanceReset] = useState(null)

  const resetInstancia = async (payload) => {
    const response = await api.post('/api/instances/resetInstance', payload)
    return response.data
  }

  const mutation = useMutation(resetInstancia, {
    onSuccess: (data) => {
      setIntanceReset(data)
    },
    onError: (error) => {
      console.error('onError:', error)
    }
  })

  const UseRequestResetInstancia = (payload) => {
    mutation.mutate(payload)
  }

  return {
    UseRequestResetInstancia,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    instanceReset
  }
}

export { useResetInstancia }
