import { useQuery } from 'react-query'
import apiAdmin from '../../../../../services/apiAdmin'

const useGetMenu = (departamentoId) => {
  console.log(departamentoId)
  return useQuery(
    ['chat_useGetMenu', { departamentoId }],
    async () => {
      const response = await apiAdmin.get(`/v1/api/admin/menu/obtendo?idDepartamento=${departamentoId}`)
      return response.data
    },
    {
      enabled: !!departamentoId,
      refetchOnWindowFocus: true,
      staleTime: 500,
      refetchInterval: 500
    }
  )
}

export { useGetMenu }
